<script setup lang="ts">
import { toRefs, ref, watch } from "vue";
import { type AppddLookupType } from "@/types";

const props = defineProps({
	label: {
		type: String,
		default: "",
	},
	labelpos: {
		type: String,
		default: "top",
	},
	labelcolor: {
		type: String,
		default: "dark"
	},
	id: {
		type: String,
		required: true,
	},
	options: {
		type: Array<AppddLookupType>,
		required: true,
	},
	modelValue: {
		type: String,
		default: "",
	},
	validate: {
		type: Number,
		default: 0,
	},
	validationRegExp: {
		type: String,
		default: "",
	},
	errorMsg: {
		type: String,
		default: "",
	}
});

const { label, labelpos, labelcolor, id, options, modelValue, validate, validationRegExp, errorMsg } = toRefs(props);
const emit = defineEmits(["update:modelValue", "fieldError"]);

const error = ref(false);

const update = (e: Event) => {
	const val = (e.target as HTMLInputElement).value;
	if (validationRegExp.value) {
		if (new RegExp(validationRegExp.value).test(val)) {
			error.value = false;
		}
	}
	emit('update:modelValue', val);
	emit("fieldError", id.value, error.value);
}

watch(validate, (valValue) => {
	if (valValue && validationRegExp.value) {
		const rex = new RegExp(validationRegExp.value);
		if (!rex.test(modelValue.value)) {
			error.value = true;
		} else {
			error.value = false;
		}
		emit("fieldError", id.value, error.value);
	}
});
</script>

<template>
	<div class="input-outer" :class="`input-outer-label-${labelpos}`">
		<label v-if="label" :for="id" :class="{ light: labelcolor === 'light' }">{{ label }}</label>
		<p v-if="error && errorMsg" class="error-msg">{{ errorMsg }}</p>
		<div class="select-inner">
			<select :id="id" :value="modelValue" @change="update($event)" v-bind="$attrs" :class="{ 'field-error': error }">
				<option v-for="opt in options" :value="opt.key">{{ opt.value }}</option>
			</select>
		</div>
	</div>
</template>

<style scoped lang="scss">
.input-outer {

	.light {
		color: var(--color-white);
	}

	.error-msg {
		font-weight: 600;
		color: #c93b3b;
	}

	.field-error {
		background-color: #f8e1e1;
	}
}
</style>