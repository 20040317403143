import { defineStore } from 'pinia'
import type { ITree, ITreeNode, MainStateData, JsonArticle, JsonBookmarks, JsonEntryTab, KsgLanguages, JsonBlogEntry, UserDataStorageData, UpdateUserDataStorageFormData, UserDataStorageError, UserData, LanguageMatchingEntries, MatchingEntries } from "@/types"
import { filterUrl, articleUrl, tabsUrl, userDataStoreUrl, blogUrl, bookmarksUrl, downloadsUrl, deleteBookmarkListEntry, deleteBookmarkList, updateUserDataStorage, articleBranchedUrl } from '@/utils/entrypoints';
import { Tree, markdownRegExp } from '@/utils/utils';

export const useStore = defineStore('main', {
	state: (): MainStateData => {
		return {
			articleData: null,
			articleBranchedData: null,
			articleKeywords: new Set(),
			blogData: null,
			bookmarksData: null,
			downloadsData: null,
			brainCount: 1,
			overlayBrainCount: 1,
			bookmarkCurrArticle: "",
			closeAllPopups: 0,
			currTab: null,
			currentIntersectedArticleIds: [],
			currentPopupContent: [],
			currentSearchFilterInfoHeight: 75,
			currentUsedFilters: [],
			entryId: "",
			filteredArticleData: null,
			unfilteredArticleData: null, // Only if search query get a result in an article, but the article is not user filterd.
			filterData: null,
			flashMessage: null,
			initializing: true,
			lang: "de",
			searchDebounceTimeout: 0,
			searchDomTarget: null,
			searchFilterInfoExpanded: true,
			searchPhrase: "",
			searchExactMode: false,
			searching: false,
			sessionRestore: false,
			showPopup: false,
			ssoLoginUrl: "",
			ssoLoginUser: null,
			tabsData: null,
			testmode: false,
			userPrefs: null,
			singleSectionViewShiftOnClick: false,
			registerBaseFormControlTypes: {
				anrede2: {
					type: "lookup", subtype: "select", lookups: [
						{ key: "", value: "message.form-options-placeholder-salutation" },
						{ key: "frau", value: "message.form-options-salutation-mrs" },
						{ key: "herr", value: "message.form-options-salutation-mr" },
						{ key: "keine_angabe", value: "message.form-options-salutation-not-specified" },
					]
				},
				vorname: { type: "string", subtype: "text" },
				nachname: { type: "string", subtype: "text" },
				organisation: { type: "string", subtype: "text" },
				e_mail: { type: "string", subtype: "email" },
				account_aktiv2: { type: "bool", subtype: null },
				ksg_kunde_oder_interessent: {
					type: "lookup", subtype: "select", lookups: [
						{ key: "", value: "message.form-options-placeholder-role" },
						{ key: "ksg_kunde", value: "message.form-options-role-customer" },
						{ key: "interessent", value: "message.form-options-role-interested" },
						{ key: "sonstige", value: "message.form-options-role-others" }

					]
				},
				sprache: {
					type: "lookup", subtype: "select", lookups: [
						{ key: "deutsch", value: "message.form-options-language-german" },
						{ key: "englisch", value: "message.form-options-language-english" }
					]
				},
				registriert_stufe_1: { type: "date", subtype: "datetimesecond" }
			},
			registerExtendedFormControlTypes: {
				branche: {
					type: "lookup", subtype: "select", lookups: [
						{ key: "", value: "message.form-options-placeholder-industry" },
						{ key: "industrieelektronik", value: "message.form-options-industry-electronic" },
						{ key: "medizintechnik", value: "message.form-options-industry-medicine" },
						{ key: "mobility", value: "message.form-options-industry-mobility" },
						{ key: "kommunikation", value: "message.form-options-industry-communication" },
						{ key: "ems", value: "message.form-options-industry-ems" },
						{ key: "luft_und_raumfahrt", value: "message.form-options-industry-space" },
						{ key: "militaertechnik", value: "message.form-options-industry-military" },
						{ key: "konsumelektronik", value: "message.form-options-industry-consumerelectronics" },
						{ key: "sonstige", value: "message.form-options-industry-others" },

					]
				},
				abteilung: {
					type: "lookup", subtype: "select", lookups: [
						{ key: "", value: "message.form-options-placeholder-department" },
						{ key: "entwicklung", value: "message.form-options-department-development" },
						{ key: "layout", value: "message.form-options-department-layout" },
						{ key: "einkauf", value: "message.form-options-department-purchasing" },
						{ key: "qualitaet", value: "message.form-options-department-quality" },
						{ key: "vertrieb", value: "message.form-options-department-sales" },
						{ key: "geschaeftsleitung", value: "message.form-options-department-management" },
						{ key: "administration_verwaltung", value: "message.form-options-department-administration" },
					]
				},
				funktion: {
					type: "lookup", subtype: "select", lookups: [
						{ key: "", value: "message.form-options-placeholder-function" },
						{ key: "abteilungsleitung", value: "message.form-options-function-head-of-department" },
						{ key: "teamleitung", value: "message.form-options-function-teamleader" },
						{ key: "mitarbeiter", value: "message.form-options-function-employee" },
						{ key: "student_auszubildender", value: "message.form-options-function-student" },
					]
				},
				telefonnummer: { type: "string", subtype: "tel" },
				strasse2: { type: "string", subtype: "text" },
				postleitzahl: { type: "string", subtype: "text" },
				ort: { type: "string", subtype: "text" },
				land: { type: "string", subtype: "text" },
				registriert_stufe_2: { type: "date", subtype: "datetimesecond" }
			},
			languageMatchingEntries: {
				de: {
					en: {
						"source_6463b665b4398df7e0ab8ef0": "target_64b165cb90ad458f53d6f5a0", // Ohne Vorauswahl
						"source_636280757ac257024627c833": "target_64a2969687838939c14818ea", // HDI-/SBU-Leiterplatte
						"source_63274faae7e41c538abd7df8": "target_64a297369a27acf3417d58cb", // Multilayer- Leiterplatte
						"source_636280d5485bded5649d4b25": "target_64a2994948f996b1d84374f6", // Hochfrequenzleiterplatte
						"source_64a29a4de2a0ea5e6569dfec": "target_63274fd4a0a5f6bb7eede11a", // Dickkupferleiterplatte
						"source_6362810b661beea14b1dccb8": "target_64a29a9e4d0698d6344e486c", // Iceberg-Leiterplatte
						"source_6362814274634b299a9089f3": "target_64a29b6a4bb7f2724a658a35", // Semiflexible Leiterplatte
						"source_63274fc155e6ef40f13265ed": "target_64a295fbbca651c8edd72cc8", // Starrflexible Leiterplatte
						"source_6362812486498f36dfb751f2": "target_63eb729f6b4b1080e2e2d6bc", // HSMtec®-Leiterplatte
						"source_642d33af48c0429acbffc92f": "target_64a29bd632d25a7c33a0aed5", // Kupfer-IMS-Leiterplatte
						"source_636281594f606e30ce828a1c": "target_63d368a34e8b7013830aaaf2", // Embedded-Technologie
						"source_6362816b31832b6ca0c4a885": "target_64a29598a06f4e45cabaeb81", // Doppelseitige Leiterplatte
						"source_63a3171e21b81955cce70a73": "target_64a29252f067c96dee69af2a", // Hochfrequenz- und Radaranwendungen
						"source_63a3175293b65aa440d4757e": "target_64a292d8e30c57b40306f09b", // Hochstromlösungen
						"source_63a3231aee8368a44c9b5b36": "target_64a294ca9387401c5bc5916e", // LED-Anwendungen
						"source_63a31678f24139cb1fe7b779": "target_64a29168d9dd52d4ad27c820", // Mehrdimensional & Platzsparend
						"source_63a322e37b3065e8535cfb27": "target_64a293a082a93a345cd5cd0f" // Wärmemanagement
					}
				},
				en: {
					de: {
						"source_64b165cb90ad458f53d6f5a0": "target_6463b665b4398df7e0ab8ef0", // Without preselection
						"source_64a2969687838939c14818ea": "target_636280757ac257024627c833", // HDI/SBU PCB
						"source_64a297369a27acf3417d58cb": "target_63274faae7e41c538abd7df8", // Multilayer PCB
						"source_64a2994948f996b1d84374f6": "target_636280d5485bded5649d4b25", // High-Frequency PCB
						"source_63274fd4a0a5f6bb7eede11a": "target_64a29a4de2a0ea5e6569dfec", // Heavy Copper PCB
						"source_64a29a9e4d0698d6344e486c": "target_6362810b661beea14b1dccb8", // Iceberg
						"source_64a29b6a4bb7f2724a658a35": "target_6362814274634b299a9089f3", // Semi-Flex PCB
						"source_64a295fbbca651c8edd72cc8": "target_63274fc155e6ef40f13265ed", // Rigid-Flex PCB
						"source_63eb729f6b4b1080e2e2d6bc": "target_6362812486498f36dfb751f2", // HSMtec®
						"source_64a29bd632d25a7c33a0aed5": "target_642d33af48c0429acbffc92f", // Copper IMS
						"source_63d368a34e8b7013830aaaf2": "target_636281594f606e30ce828a1c", // Embedded Technology
						"source_64a29598a06f4e45cabaeb81": "target_6362816b31832b6ca0c4a885", // Double-Sided PCB
						"source_64a29252f067c96dee69af2a": "target_63a3171e21b81955cce70a73", // High-Frequency & Radar Applications
						"source_64a292d8e30c57b40306f09b": "target_63a3175293b65aa440d4757e", // High-Current Solutions
						"source_64a294ca9387401c5bc5916e": "target_63a3231aee8368a44c9b5b36", // LED Applications
						"source_64a29168d9dd52d4ad27c820": "target_63a31678f24139cb1fe7b779", // Multidimensional & Space Saving
						"source_64a293a082a93a345cd5cd0f": "target_63a322e37b3065e8535cfb27" // Thermal Management
					}
				}
			}
		};
	},
	getters: {
		getSsoLoginUser(state): UserDataStorageData | UserDataStorageError | null { return state.ssoLoginUser; },
		getArticleData(state): JsonArticle[] | null { return state.articleData; },
		getArticleDataByIdentifier(state) {
			return (articleIdentifier: string) => {
				const allArticles: JsonArticle[] | null = state.articleData;
				if (allArticles && articleIdentifier) {
					for (const article of allArticles) {
						if (article.identifizierer === articleIdentifier) {
							return article;
						}
					}
				}
				return null;
			};
		},
		getArticleDataById(state) {
			return (articleId: string) => {
				const allArticles: JsonArticle[] | null = state.articleData;
				if (allArticles && articleId) {
					for (const article of allArticles) {
						if (article.id === articleId) {
							return article;
						}
					}
				}
				return null;
			};
		},
		getArticleDataByFilterIdentifier(state) {
			return (filterId: string) => {
				const allArticles: JsonArticle[] | null = state.articleData;
				if (allArticles && filterId) {
					for (const article of allArticles) {
						if (article.zutreffender_filter?.id === filterId) {
							return article;
						}
					}
				}
				return null;
			};
		},
		getFilterData(state): ITree | null { return state.filterData; },
		getFilteredArticleData(state): JsonArticle[] | null {
			let cuf: string[] | null = null;
			let searchPhrases: string[] = [];
			const realArticles: JsonArticle[] = [];
			state.flashMessage = null;
			// if (state.searchExactMode && state.searchPhrase.length) {
			// 	searchPhrases = [this.getSearchPhrase.replace(/[.*+?^${}()|\[\]\\]/g, '\\$&')]
			// } else
			if (state.searchPhrase.length) {
				searchPhrases = this.getSearchPhrases.map((phrase: string) => phrase.replace(/[.*+?^${}()|\[\]\\]/g, '\\$&'))
			}
			const bookmarkCurrArticle = this.getBookmarkCurrArticle;
			for (const cf of state.currentUsedFilters) {
				if (!cuf) {
					cuf = [];
				}
				cuf.push(cf.id);
			}
			if (state.articleData) {
				if (searchPhrases.length) {
					for (const article of state.articleData) {
						if (state.filterData && article.zutreffender_filter) {
							const filterNode = state.filterData.findNodeById(state.filterData.nodes, article.zutreffender_filter.id);
							if (filterNode && !filterNode.children.length) {
								realArticles.push(article);
							}
						}
					}
				} else if (cuf) {
					for (const article of state.articleData) {
						if (bookmarkCurrArticle) {
							if (article.identifizierer === bookmarkCurrArticle) {
								realArticles.push(article);
								state.filteredArticleData = realArticles;
								break;
							}
						} else {
							if (article.zutreffender_filter && cuf) {
								if (cuf && cuf.includes(article.zutreffender_filter.id)) {
									realArticles.push(article);
								}
							}
						}
					}
				}
				if (searchPhrases.length && !bookmarkCurrArticle) {
					state.searching = true
					const searchPhraseResults = [];
					let result: RegExpMatchArray | null = null;
					const foundUnfilteredArticles: JsonArticle[] = [];
					for (const article of realArticles) {
						let foundAllSearchPhrases = false;
						const foundSearchPhrases = new Set();
						let articleScore = 0;
						const title = article.titel;
						for (const phrase of searchPhrases) {
							if (title) {
								result = title.match(new RegExp(phrase.toLowerCase(), "ig"));
								if (result) {
									articleScore += 50 * result.length;
									foundSearchPhrases.add(phrase);
								}
							}
						}
						if (article.parts) {
							for (const part of article.parts) {
								const jumpTargetHeader = part.bezeichner_sprungziel;
								const ueberschrift = part.ueberschrift?.replace(new RegExp(markdownRegExp, "ig"), "");
								const ueberschrift_zweite_spalte = part.ueberschrift_zweite_spalte?.replace(new RegExp(markdownRegExp, "ig"), "");
								const ueberschrift_dritte_spalte = part.ueberschrift_dritte_spalte?.replace(new RegExp(markdownRegExp, "ig"), "");
								const ueberschrift_vierte_spalte = part.ueberschrift_vierte_spalte?.replace(new RegExp(markdownRegExp, "ig"), "");
								const text = part.text?.replace(new RegExp(markdownRegExp, "ig"), "");
								const text_zweite_spalte = part.text_zweite_spalte?.replace(new RegExp(markdownRegExp, "ig"), "");
								const text_dritte_spalte = part.text_dritte_spalte?.replace(new RegExp(markdownRegExp, "ig"), "");
								const text_vierte_spalte = part.text_vierte_spalte?.replace(new RegExp(markdownRegExp, "ig"), "");
								const bilduntertext = part.bilduntertext?.replace(new RegExp(markdownRegExp, "ig"), "");
								const bilduntertext_zweite_spalte = part.bilduntertext_zweite_spalte?.replace(new RegExp(markdownRegExp, "ig"), "");
								const bilduntertext_dritte_spalte = part.bilduntertext_dritte_spalte?.replace(new RegExp(markdownRegExp, "ig"), "");
								const bilduntertext_vierte_spalte = part.bilduntertext_vierte_spalte?.replace(new RegExp(markdownRegExp, "ig"), "");
								const tabelle = part.tabelle;
								const tabelle_zweite_spalte = part.tabelle_zweite_spalte;
								const tabelle_dritte_spalte = part.tabelle_dritte_spalte;
								const tabelle_vierte_spalte = part.tabelle_vierte_spalte;
								const platinenschnittbild = part.platinenschnittbild;
								let tableTitle = "";
								let tableCsv = "";
								let tableTitle2 = "";
								let tableCsv2 = "";
								let tableTitle3 = "";
								let tableCsv3 = "";
								let tableTitle4 = "";
								let tableCsv4 = "";
								let sectionTableTitle = "";
								let sectionTableCsv = "";
								if (tabelle) {
									tableTitle = tabelle.titel?.replace(new RegExp(markdownRegExp, "ig"), "")
									tableCsv = (tabelle.tabelle_als_csv as string)?.replace(new RegExp(markdownRegExp, "ig"), "");
								}
								if (platinenschnittbild && platinenschnittbild.tabelle_zu_schnittbild) {
									sectionTableTitle = platinenschnittbild.tabelle_zu_schnittbild.titel?.replace(new RegExp(markdownRegExp, "ig"), "");
									sectionTableCsv = (platinenschnittbild.tabelle_zu_schnittbild.tabelle_als_csv as string)?.replace(new RegExp(markdownRegExp, "ig"), "");
								}
								for (const phrase of searchPhrases) {
									if (jumpTargetHeader) {
										result = jumpTargetHeader.match(new RegExp(phrase.toLowerCase(), "ig"));
										if (result) {
											articleScore += 50 * result.length;
											foundSearchPhrases.add(phrase);
										}
									}
									if (ueberschrift) {
										result = ueberschrift.match(new RegExp(phrase.toLowerCase(), "ig"));
										if (result) {
											articleScore += 50 * result.length;
											foundSearchPhrases.add(phrase);
										}
									}
									if (text) {
										result = text.match(new RegExp(phrase.toLowerCase(), "ig"));
										if (result) {
											articleScore += 40 * result.length;
											foundSearchPhrases.add(phrase);
										}
									}
									if (bilduntertext) {
										result = bilduntertext.match(new RegExp(phrase.toLowerCase(), "ig"));
										if (result) {
											articleScore += 40 * result.length;
											foundSearchPhrases.add(phrase);
										}
									}
									if (tableTitle) {
										result = tableTitle.match(new RegExp(phrase.toLowerCase(), "ig"));
										if (result) {
											articleScore += 20 * result.length;
											foundSearchPhrases.add(phrase);
										}
									}
									if (tableCsv) {
										result = tableCsv.match(new RegExp(phrase.toLowerCase(), "ig"));
										if (result) {
											articleScore += 20 * result.length;
											foundSearchPhrases.add(phrase);
										}
									}
									if (sectionTableTitle) {
										result = sectionTableTitle.match(new RegExp(phrase.toLowerCase(), "ig"));
										if (result) {
											articleScore += 20 * result.length;
											foundSearchPhrases.add(phrase);
										}
									}
									if (sectionTableCsv) {
										result = sectionTableCsv.match(new RegExp(phrase.toLowerCase(), "ig"));
										if (result) {
											articleScore += 20 * result.length;
											foundSearchPhrases.add(phrase);
										}
									}
									if (part.mehrspaltigkeit) {
										if (tabelle_zweite_spalte) {
											tableTitle2 = tabelle_zweite_spalte.titel?.replace(new RegExp(markdownRegExp, "ig"), "");
											tableCsv2 = (tabelle_zweite_spalte.tabelle_als_csv as string)?.replace(new RegExp(markdownRegExp, "ig"), "");
											if (tableTitle2) {
												result = tableTitle2.match(new RegExp(phrase.toLowerCase(), "ig"));
												if (result) {
													articleScore += 20 * result.length;
													foundSearchPhrases.add(phrase);
												}
											}
											if (tableCsv2) {
												result = tableCsv2.match(new RegExp(phrase.toLowerCase(), "ig"));
												if (result) {
													articleScore += 20 * result.length;
													foundSearchPhrases.add(phrase);
												}
											}
										}
										if (tabelle_dritte_spalte) {
											tableTitle3 = tabelle_dritte_spalte.titel?.replace(new RegExp(markdownRegExp, "ig"), "");
											tableCsv3 = (tabelle_dritte_spalte.tabelle_als_csv as string)?.replace(new RegExp(markdownRegExp, "ig"), "");
											if (tableTitle3) {
												result = tableTitle3.match(new RegExp(phrase.toLowerCase(), "ig"));
												if (result) {
													articleScore += 20 * result.length;
													foundSearchPhrases.add(phrase);
												}
											}
											if (tableCsv3) {
												result = tableCsv3.match(new RegExp(phrase.toLowerCase(), "ig"));
												if (result) {
													articleScore += 20 * result.length;
													foundSearchPhrases.add(phrase);
												}
											}
										}
										if (tabelle_vierte_spalte) {
											tableTitle4 = tabelle_vierte_spalte.titel?.replace(new RegExp(markdownRegExp, "ig"), "");
											tableCsv4 = (tabelle_vierte_spalte.tabelle_als_csv as string)?.replace(new RegExp(markdownRegExp, "ig"), "");
											if (tableTitle4) {
												result = tableTitle4.match(new RegExp(phrase.toLowerCase(), "ig"));
												if (result) {
													articleScore += 20 * result.length;
													foundSearchPhrases.add(phrase);
												}
											}
											if (tableCsv4) {
												result = tableCsv4.match(new RegExp(phrase.toLowerCase(), "ig"));
												if (result) {
													articleScore += 20 * result.length;
													foundSearchPhrases.add(phrase);
												}
											}
										}
										if (ueberschrift_zweite_spalte) {
											result = ueberschrift_zweite_spalte.match(new RegExp(phrase.toLowerCase(), "ig"));
											if (result) {
												articleScore += 50 * result.length;
												foundSearchPhrases.add(phrase);
											}
										}
										if (ueberschrift_dritte_spalte) {
											result = ueberschrift_dritte_spalte.match(new RegExp(phrase.toLowerCase(), "ig"));
											if (result) {
												articleScore += 50 * result.length;
												foundSearchPhrases.add(phrase);
											}
										}
										if (ueberschrift_vierte_spalte) {
											result = ueberschrift_vierte_spalte.match(new RegExp(phrase.toLowerCase(), "ig"));
											if (result) {
												articleScore += 50 * result.length;
												foundSearchPhrases.add(phrase);
											}
										}
										if (text_zweite_spalte) {
											result = text_zweite_spalte.match(new RegExp(phrase.toLowerCase(), "ig"));
											if (result) {
												articleScore += 50 * result.length;
												foundSearchPhrases.add(phrase);
											}
										}
										if (text_dritte_spalte) {
											result = text_dritte_spalte.match(new RegExp(phrase.toLowerCase(), "ig"));
											if (result) {
												articleScore += 40 * result.length;
												foundSearchPhrases.add(phrase);
											}
										}
										if (text_vierte_spalte) {
											result = text_vierte_spalte.match(new RegExp(phrase.toLowerCase(), "ig"));
											if (result) {
												articleScore += 40 * result.length;
												foundSearchPhrases.add(phrase);
											}
										}
										if (bilduntertext_zweite_spalte) {
											result = bilduntertext_zweite_spalte.match(new RegExp(phrase.toLowerCase(), "ig"));
											if (result) {
												articleScore += 40 * result.length;
												foundSearchPhrases.add(phrase);
											}
										}
										if (bilduntertext_dritte_spalte) {
											result = bilduntertext_dritte_spalte.match(new RegExp(phrase.toLowerCase(), "ig"));
											if (result) {
												articleScore += 40 * result.length;
												foundSearchPhrases.add(phrase);
											}
										}
										if (bilduntertext_vierte_spalte) {
											result = bilduntertext_vierte_spalte.match(new RegExp(phrase.toLowerCase(), "ig"));
											if (result) {
												articleScore += 40 * result.length;
												foundSearchPhrases.add(phrase);
											}
										}
									}
								}
							}
						}
						if (state.searchExactMode && articleScore && foundSearchPhrases.size === searchPhrases.length || !state.searchExactMode && articleScore && foundSearchPhrases.size >= 1) {
							searchPhraseResults.push([article, articleScore]);
						}
						if (searchPhraseResults.length === 30) {
							state.flashMessage = {
								type: "info",
								text: "search-to-much-results-flash-message-text"
							};
							break;
						}
					}
					searchPhraseResults.sort((a, b) => {
						const cntA = a[1];
						const cntB = b[1];
						return Number(cntA < cntB) - Number(cntA > cntB);
					});
					const realSearchArticles: JsonArticle[] = [];
					for (const item of searchPhraseResults) {
						const resultArticle = item[0] as JsonArticle;
						realSearchArticles.push(resultArticle);
						if (!cuf || cuf && resultArticle.zutreffender_filter && !cuf.includes(resultArticle.zutreffender_filter.id)) {
							foundUnfilteredArticles.push(resultArticle);
						}
					}
					state.filteredArticleData = realSearchArticles;
					state.unfilteredArticleData = foundUnfilteredArticles;
				} else {
					state.filteredArticleData = realArticles;
					state.unfilteredArticleData = [];
				}
			}
			return state.filteredArticleData;
		},
		getCurrentUsedFilters(state): ITreeNode[] { return state.currentUsedFilters; },
		getCurrentTab(state): JsonEntryTab | null { return state.currTab; },
		getSearchDebounceTimeout(state): number {
			return state.searchDebounceTimeout;
		},
		getSearchPhrase(state): string {
			return state.searchPhrase;
		},
		getSearchDomTarget(state): HTMLElement | null {
			return state.searchDomTarget;
		},
		getSearchPhrases(state): string[] {
			let searchPhrases
			searchPhrases = state.searchPhrase.split(" ")
			return searchPhrases.filter((item: string) => {
				item = item.trim()
				const minLength = isNaN(parseFloat(item)) ? 3 : 0
				return item && !item.match(/\s|(\bspan\b)/g) && item.length >= minLength
			})
		},
		getBlogEntries(state): JsonBlogEntry[] {
			return state.blogData || [];
		},
		getBookmarks(state): JsonBookmarks[] {
			return state.bookmarksData || [];
		},
		getBookmarkCurrArticle(state): string {
			return state.bookmarkCurrArticle;
		},
		getEntryTabs(state): JsonEntryTab[] {
			return state.tabsData || [];
		},
		getSelectedEntryTab(state): JsonEntryTab | null {
			if (state.tabsData) {
				for (const tab of state.tabsData) {
					if (tab.ausgewaehlt) {
						return tab;
					}
				}
			}
			return null;
		},
		getLang(state): string {
			const langs: KsgLanguages = {
				"de": "deutsch",
				"en": "englisch"
			};
			return langs[state.lang];
		},
		getLangMatchingEntriesForCurrLang(state): MatchingEntries {
			return state.languageMatchingEntries[state.lang]
		}
	},
	actions: {
		async updateDataByTab(entry: JsonEntryTab) {
			this.initializing = true;
			const tabs = this.getEntryTabs;
			for (const tab of tabs) {
				if (tab.id === entry.id) {
					tab.ausgewaehlt = true;
					this.currTab = tab;
					this.searchPhrase = "";
					const wipe = true;
					await this.updateFilterData(wipe);
					await this.updateArticleBranchedData(wipe);
					await this.updateArticleData(wipe);
					await this.updateDownloadsData();
					this.initializing = false;
				} else {
					tab.ausgewaehlt = false;
				}
			}
		},
		async updateUserDataStorage(writeToApp = false) {
			if (writeToApp && this.filterData && this.ssoLoginUser) {
				this.filterData.treeToJson(this.filterData.nodes);
				if (this.filterData.flatJsonTree) {
					this.filterData.flatJsonTree["entryId"] = this.entryId;
					const storageData: UpdateUserDataStorageFormData = {
						id: this.ssoLoginUser.id,
						sprache: this.getLang,
						json_daten: JSON.stringify({
							filterdata: this.filterData.flatJsonTree,
							pills_toggle_state: this.searchFilterInfoExpanded ? "1" : "0"
						})
					};
					await updateUserDataStorage(storageData);
				}
			} else {
				const resp = await fetch(userDataStoreUrl(this.getLang)).catch((status) => {
					const message = `Not able to request user storage data: ${status}`;
					throw new Error(message);
				});
				if (resp && resp.ok) {
					const data = await resp.json();
					if (data && data.length) {
						const userData = (data as UserDataStorageData[])[0];
						this.ssoLoginUser = userData;
						await this.updateBookmarksData();
					} else {
						this.ssoLoginUser = null;
						console.warn("There is no user logged in! Normally we would redirect to the login page, but in develop mode we dont.");
					}
				}
			}
		},
		async updateTabsData() {
			if (!this.filterData) {
				const resp = await fetch(tabsUrl(this.getLang)).catch((status) => {
					const message = `Not able to request tabs data: ${status}`;
					throw new Error(message);
				});
				if (resp && resp.ok) {
					const data: JsonEntryTab[] | null = await resp.json();
					if (data) {
						this.tabsData = data;
						if (this.entryId) {
							for (const tab of this.getEntryTabs) {
								if (tab.id === this.entryId || tab.ausgewaehlt) {
									tab.ausgewaehlt = true;
								} else {
									tab.ausgewaehlt = false;
								}
							}
						}
					}
				} else {
					this.tabsData = null;
				}
			}
		},
		async updateFilterData(wipe?: boolean) {
			this.currentUsedFilters = []
			if (!this.filterData || wipe) {
				const resp = await fetch(filterUrl(this.getCurrentTab, this.getLang, this.testmode)).catch((status) => {
					const message = `Not able to request filter data: ${status}`;
					throw new Error(message);
				});
				if (resp && resp.ok) {
					const data = await resp.json();
					const tree = new Tree();
					tree.jsonToTree(data, null);
					this.filterData = tree;
					const selectedTab = this.getSelectedEntryTab;
					if (!this.sessionRestore && selectedTab && selectedTab.zu_setzende_filter) {
						for (const filter of selectedTab.zu_setzende_filter) {
							const filterNode = this.filterData.findNodeById(this.filterData.nodes, filter.id)
							if (filterNode) {
								this.updateUsedFilters(filterNode);
							}
						}
					} else if (this.sessionRestore && this.ssoLoginUser) {
						const sessionJsonStr = this.ssoLoginUser.json_daten;
						if (sessionJsonStr) {
							const sessionData = JSON.parse(sessionJsonStr);
							const filterData = sessionData.filterdata ? sessionData.filterdata : sessionData;
							for (const nodeId in filterData) {
								if (nodeId !== "entryId") {
									const sdNode = filterData[nodeId] as any;
									const nid = nodeId.replace("_", "");
									const filterNode = this.filterData.findNodeById(this.filterData.nodes, nid);
									if (filterNode && sdNode) {
										if (filterNode.children.length) {
											filterNode.toggleState = sdNode.toggleState;
										} else {
											if (sdNode.checkerstate) {
												this.updateUsedFilters(filterNode);
											}
										}
									}
								}
							}
						}
					}
				} else {
					this.filterData = null;
				}
			}
		},
		async updateArticleData(wipe?: boolean, testmode?: boolean) {
			if (!this.articleData || wipe) {
				const resp = await fetch(articleUrl(this.getCurrentTab, this.getLang)).catch((status) => {
					const message = `Not able to request article data: ${status}`;
					throw new Error(message);
				});
				if (resp && resp.ok) {
					const data = await resp.json();
					this.articleData = data;
					this.filteredArticleData = [...data];
					this.sortCurrentUsedFilters();
					this.extractArticleKeywords();
				} else {
					this.articleData = null;
					this.filteredArticleData = null;
				}
			} else {
				if (testmode && this.articleBranchedData && this.articleBranchedData.length) {
					const realArticles = []
					for (const article of this.articleData) {
						let found = false
						for (const bArticle of this.articleBranchedData) {
							if (article.branched_artikel_id === bArticle.id) {
								realArticles.push(bArticle)
								found = true
							}
						}
						if (!found) {
							realArticles.push(article)
						}
					}
					this.articleData = realArticles
				}
				this.filteredArticleData = this.getFilteredArticleData;
			}
		},
		async updateArticleBranchedData(wipe?: boolean) {
			if (!this.articleBranchedData || wipe) {
				const resp = await fetch(articleBranchedUrl(this.getCurrentTab, this.getLang)).catch((status) => {
					const message = `Not able to request article data: ${status}`;
					throw new Error(message);
				});
				if (resp && resp.ok) {
					const data = await resp.json();
					this.articleBranchedData = data;
				} else {
					this.articleBranchedData = null;
				}
			}
		},
		async updateBlogData() {
			const resp = await fetch(blogUrl(this.getLang)).catch((status) => {
				const message = `Not able to request blog data: ${status}`;
				throw new Error(message);
			});
			if (resp && resp.ok) {
				const data = await resp.json();
				this.blogData = data;
			} else {
				this.blogData = [];
			}
		},
		async updateBookmarksData() {
			let userEmail = "";
			const userData = this.ssoLoginUser ? this.ssoLoginUser.benutzer as UserData : null;
			if (userData) {
				userEmail = userData.email;
			}
			const resp = await fetch(bookmarksUrl(userEmail)).catch((status) => {
				const message = `Not able to request bookmarks data: ${status}`;
				throw new Error(message);
			});
			if (resp && resp.ok) {
				const data = await resp.json();
				this.bookmarksData = data;
			} else {
				this.bookmarksData = [];
			}
		},
		async deleteBookmarkList(id: string) {
			await deleteBookmarkList(id);
			await this.updateBookmarksData();
			this.brainCount++;
		},
		async deleteBookmarkEntry(id: string) {
			await deleteBookmarkListEntry(id);
			await this.updateBookmarksData();
			this.brainCount++;
		},
		async updateDownloadsData() {
			const resp = await fetch(downloadsUrl(this.getLang)).catch((status) => {
				const message = `Not able to request downloads data: ${status}`;
				throw new Error(message);
			});
			if (resp && resp.ok) {
				const data = await resp.json();
				this.downloadsData = data;
			} else {
				this.downloadsData = null;
			}
		},
		updateUsedFilters(node: ITreeNode | string) {
			if (typeof node === "object") {
				const checkerState = node.checkerstate === -1 || node.checkerstate === 0 ? 1 : 0;
				this.updateFiltersDownwardsFromNode(node, checkerState);
				this.updateParentsFromNode(node);
				this.sortCurrentUsedFilters();
			} else if (typeof node === "string" && node === "*") {
				for (const node of this.currentUsedFilters) {
					node.checkerstate = 0
				}
				this.currentUsedFilters = [];
				if (this.filterData) {
					this.filterData.uncheckAll()
				}
				this.brainCount++;
			}
			this.filteredArticleData = this.getFilteredArticleData;
			const writeToApp = true;
			if (!this.initializing) {
				this.updateUserDataStorage(writeToApp);
			}
		},
		updateFiltersDownwardsFromNode(node: ITreeNode, checkerState: number) {
			const currFilters = this.getCurrentUsedFilters;
			node.checkerstate = checkerState;
			if (node.children.length) {
				for (const child of node.children) {
					const currFilters = this.getCurrentUsedFilters;
					child.checkerstate = checkerState;
					if (child.children.length) {
						this.updateFiltersDownwardsFromNode(child, checkerState);
					} else {
						if (child.checkerstate === 1) {
							if (!currFilters.includes(child)) {
								currFilters.push(child);
							}
						} else {
							this.currentUsedFilters = currFilters.filter((cfn: ITreeNode) => child !== cfn);
						}
					}
				}
			} else {
				if (checkerState === 1) {
					if (!currFilters.includes(node)) {
						currFilters.push(node);
					}
				} else {
					this.currentUsedFilters = currFilters.filter((cfn: ITreeNode) => node !== cfn);
				}
			}
		},
		updateParentsFromNode(node: ITreeNode) {
			if (node.parent) {
				let checkedNodes = 0;
				let partial = false;
				for (const child of node.parent.children) {
					partial = false;
					if (child.checkerstate !== 0) {
						checkedNodes++;
						if (child.checkerstate === -1) {
							partial = true;
							break;
						}
					}
				}
				if (!partial && checkedNodes === node.parent.children.length) {
					node.parent.checkerstate = 1;
				} else if (checkedNodes !== 0 && checkedNodes < node.parent.children.length || partial) {
					node.parent.checkerstate = -1;
				} else {
					node.parent.checkerstate = 0;
				}
				this.updateParentsFromNode(node.parent);
			}
		},
		updateBookmarkCurrArticle(identifier: string) {
			this.closeAllPopups++;
			this.bookmarkCurrArticle = identifier;
			this.currentIntersectedArticleIds = [];
			this.filteredArticleData = this.getFilteredArticleData;
		},
		sortCurrentUsedFilters() {
			const sortedFilters: ITreeNode[] = [];
			const currFilters = this.getCurrentUsedFilters as ITreeNode[];
			const articles = this.getArticleData as JsonArticle[];
			if (articles) {
				for (const article of articles) {
					const articleFilter = article.zutreffender_filter;
					if (articleFilter) {
						for (const currFilter of currFilters) {
							if (articleFilter.id === currFilter.id && !sortedFilters.includes(currFilter)) {
								sortedFilters.push(currFilter);
							}
						}
					}
				}
				this.currentUsedFilters = sortedFilters;
			}
		},
		extractArticleKeywords() {
			const articles = this.getArticleData as JsonArticle[]
			const kws = []
			if (articles) {
				for (const article of articles) {
					if (article.schlagwoerter) {
						const words = article.schlagwoerter.split(",")
						for (const word of words) {
							kws.push(word.trim())
						}
					}
				}
				// If you need some more keywords for testing.
				// kws.push("Basistest1", "Basistest2", "Basistest3", "Basistest4", "Basistest5", "Basistest6", "Basistest7", "Basistest8", "Basistest9", "Basistest10",)
				kws.sort()
				for (const kw of kws) {
					this.articleKeywords.add(kw)
				}
			}
		},
	}
})
