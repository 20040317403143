<script setup lang="ts">
import { useStore } from "@/stores/main";
import { useI18n } from 'vue-i18n';
import type { JsonArticle } from "@/types";
import { computed, toRefs, ref, type ComputedRef, type PropType, type Ref, watch, onMounted, onBeforeUnmount } from "vue";
import ArticleBottomBar from "@/components/ArticleBottomBar.vue";
import ArticlePart from "@/components/ArticlePart.vue";
import SelectInput from "@/components/form/SelectInput.vue";
import FormWrapper from "@/components/form/FormWrapper.vue";
import { getHighlightedText, jumpToArticleAnchor } from "@/utils/utils";
import { useIntersectionObserverTarget } from "@/composables/intersectionObserver";

const props = defineProps({
	data: {
		type: Object as PropType<JsonArticle>,
		required: true,
	},
	inOverlay: {
		type: Boolean,
		default: false,
	}
});

const store = useStore();
const { t } = useI18n({ useScope: "global" });
const { data, inOverlay } = toRefs(props);
const articleAnchors: Ref = ref(null);
const selectedAnchor = ref("");
const articleDomElm: Ref<HTMLElement | null> = ref(null);

const searchPhrases: ComputedRef<string[]> = computed(() => {
	return store.getSearchPhrases;
});

const collectAnchors = (title: string, identifier: string) => {
	if (!articleAnchors.value) {
		articleAnchors.value = {};
	}
	articleAnchors.value[identifier] = title;
};

const getAnchorOptions = () => {
	const kvOpts = [
		{ key: "-1", value: t("message.form-select-first-entry-label-anchor-navigation") }
	];
	for (const key of Object.keys(articleAnchors.value)) {
		kvOpts.push({ key, value: articleAnchors.value[key] });
	}
	if (kvOpts.length) {
		selectedAnchor.value = kvOpts[0].key;
	}
	return kvOpts;
};

watch(selectedAnchor, (target) => {
	if (target) {
		jumpToArticleAnchor(target, "", inOverlay.value);
	}
});

watch(data, (currData) => {
	if (currData) {
		articleAnchors.value = null;
	}
});


onMounted(() => {
	useIntersectionObserverTarget(articleDomElm, "contentIntersecObs");
});

onBeforeUnmount(() => {
	const unobserve = true;
	useIntersectionObserverTarget(articleDomElm, "contentIntersecObs", unobserve);
});
</script>

<template>
	<section class="article-outer" :id="`__${data.id}`" ref="articleDomElm">
		<article class="article">
			<div v-if="articleAnchors" class="anchors-extended-header" :key="`__${data.id}`">
				<h1 :id="`in_overlay_${data.id}`" v-if="data.titel && props.inOverlay" v-html="getHighlightedText(data.titel, searchPhrases)"></h1>
				<h1 :id="`_${data.id}`" v-else-if="data.titel" v-html="getHighlightedText(data.titel, searchPhrases)"></h1>
				<FormWrapper>
					<SelectInput :id="data.id" v-model="selectedAnchor" :options="getAnchorOptions()" />
				</FormWrapper>
			</div>
			<h1 v-else-if="data.titel" v-html="getHighlightedText(data.titel, searchPhrases)"></h1>
			<ArticlePart v-for="part in data.parts" :part="part" @found-anchor="collectAnchors" :in-overlay="inOverlay" />
		</article>
		<ArticleBottomBar :data="props.data" :key="`__${data.id}`" />
	</section>
</template>

<style scoped lang="scss">
.article-outer {
	padding: 35px 20px 20px;
	background-color: var(--color-white);
	margin: 0 0 50px;
	box-shadow: var(--main-shadow);
	border-bottom-right-radius: var(--big-border-bottom-right-radius);
}
</style>

<style lang="scss">
.article {
	.anchors-extended-header {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;

		select {
			padding-top: 10px;
			padding-bottom: 10px;
			background-position: calc(100% - 15px) 1.1em, calc(100% - 22px) 1.1em, 100% 0;
		}
	}

	.accent {
		display: inline-block;
		padding: 0 5px;
		margin: 0;
		background-color: var(--color-dolly);
	}
}

.popup .article-outer {
	padding: 20px;
	background-color: var(--color-white);
	margin: 0 0 10px;
	box-shadow: none;
}
</style>