<script setup lang="ts">
import { ref, watch, toRefs, type PropType } from "vue";
import type { PopupContentData } from "@/types";
import { useStore } from "@/stores/main";

const props = defineProps({
	data: {
		type: Object as PropType<PopupContentData[]>,
		required: true,
	},
});

const store = useStore();
const { data } = toRefs(props);
const showNavi = ref(false);

const getCrumbLabel = (popContItem: PopupContentData) => {
	if (popContItem.__image__) {
		return "Grafik"
	} else {
		const article = store.getArticleDataByIdentifier(popContItem.__article__);
		return article?.titel;
	}
};

const handleItemClick = (e: Event, idx: number) => {
	const popCnt = store.currentPopupContent.length - idx - 1;
	for (let i = 0; i < popCnt; i++) {
		store.currentPopupContent.pop();
	}
	store.overlayBrainCount++
};

watch(data.value, (currData) => {
	if (currData && currData.length > 1) {
		showNavi.value = true;
	} else {
		showNavi.value = false;
	}
});

</script>

<template>
	<div v-if="showNavi" class="breadcrumb-navi">
		<div v-for="[idx, item] in data.entries()" class="crumb-item" :key="`__crump_item_${idx}`">
			<i v-if="idx < data.length" class="icon icon-right_arrow_big"></i>
			<a v-if="idx + 1 < data.length" href="#" @click.stop.prevent="handleItemClick($event, idx)">{{ getCrumbLabel(item) }}</a>
			<span v-else class="crump-item-here">{{ getCrumbLabel(item) }}</span>
		</div>
	</div>
</template>

<style scoped lang="scss">
.breadcrumb-navi {
	display: flex;
	flex-wrap: wrap;
	gap: 12px;
	margin: 10px 20px 10px 15px;

	.crumb-item {
		display: flex;
		gap: 5px;
		align-items: center;

		&-here {
			font-weight: 600;
		}
	}
}
</style>