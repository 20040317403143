<script setup lang="ts">
import { type PropType, type ComputedRef, toRefs, computed, ref, type Ref, nextTick } from "vue";
import { useStore } from "@/stores/main";
import type { JsonBookmarks } from "@/types";
import { VueCollapsiblePanelGroup, VueCollapsiblePanel } from "@swoga/vue-collapsible-panel";
import BookmarkEntry from "@/components/BookmarkEntry.vue";
import { useI18n } from 'vue-i18n';
import FormWrapper from "./form/FormWrapper.vue";
import TextInput from "./form/TextInput.vue";
import SharePopper from "./SharePopper.vue";

const props = defineProps({
	bookmarks: {
		type: Object as PropType<JsonBookmarks[]>,
		required: true,
	},
});

const { bookmarks } = toRefs(props);
const store = useStore();
const { t, n } = useI18n({ useScope: "global" });

const shareButton = ref()
const shareIdentifier = ref("")
const sharedTargetUser = ref("")

const bookmarkView: ComputedRef<string> = computed(() => {
	return store.getBookmarkCurrArticle;
})

const holdSharedTargetUser = (user: string) => {
	sharedTargetUser.value = user
}

const handleBookmarkListDelete = async (id: string, e: Event) => {
	await store.deleteBookmarkList(id);
}
const handleShareDialog = (bm: JsonBookmarks, e: Event) => {
	shareIdentifier.value = ""
	nextTick(() => {
		shareIdentifier.value = bm.id
	})
}
</script>

<template>
	<div class="bookmark-list-outer">
		<VueCollapsiblePanelGroup>
			<VueCollapsiblePanel @click="shareIdentifier = ''" v-for="[idx, bm] in bookmarks.entries()" :key="bm.id" class="bookmark-list">
				<template #title>
					<div class="bm-title-outer">
						<div class="title">{{ bm.titel }}</div>
						<SharePopper :lang="store.getLang" :entry-id="store.entryId" @sharedWithUser="holdSharedTargetUser" :share-identifier="shareIdentifier" :identifier="bm.id" placenment="bottom" :sourceUser="bm.user" :target-user="sharedTargetUser">
							<button @click.stop="handleShareDialog(bm, $event)" v-if="!bookmarkView" ref="shareButton" class="share-icon button green"><i class="icon icon-share"></i>{{ t("message.bookmarks-share-button-label") }}</button>
						</SharePopper>
						<div v-if="!bookmarkView" @click.stop="handleBookmarkListDelete(bm.id, $event)" class="delete-icon icon icon-cross"></div>
					</div>
				</template>
				<template #content>
					<div class="flex-outer">
						<p v-if="!bm.eintraege.length">{{ t("message.hint-no-bookmarks") }}.</p>
						<BookmarkEntry v-for="item in bm.eintraege" :entry="item" :key="item.identifizierer + store.brainCount" />
					</div>
				</template>
			</VueCollapsiblePanel>
		</VueCollapsiblePanelGroup>
	</div>
</template>


<style scoped lang="scss">
.bookmark-list-outer {
	margin: 24px 0 20px;
	min-height: 380px;

	&[data-as-navigation="true"] {
		margin: 24px 0 20px 15px;
	}


	.bookmark-list {
		position: relative;

		.bm-title-outer {
			display: flex;
			align-items: center;
			width: 100%;

			.title {
				flex: 1;
			}

			.share-icon {
				display: flex;
				align-items: center;
				gap: 5px;
				margin: 0 20px;

				.icon {
					font-size: 1.5rem;
					color: var(--color-white);
				}
			}

			.delete-icon {
				font-size: .8rem;
				color: var(--color-nevada);

				&:hover {
					color: var(--color-anthrazit);
				}
			}
		}

		.flex-outer {
			display: flex;
			flex-direction: column;
			gap: .4rem;
		}
	}
}

.popup-outer .bookmark-list-outer {
	margin: 0 20px 60px;
}
</style>