<script setup lang="ts">
import { toRefs, type ComputedRef, computed, watch, ref, type Ref, onMounted, onUnmounted, nextTick } from "vue"
import type { SuggestionListItem } from "@/types"
import { useStore } from "@/stores/main"
const props = defineProps({
	phrase: {
		type: String,
		required: true,
		default: ""
	},
})

const { phrase } = toRefs(props)
const store = useStore()

const emit = defineEmits<{
	(e: "userSuggestionSelect", phrase: string): void,
	(e: "isOpen", state: boolean): void,
}>()

const cachedPrevPhrase = ref("")
const currentSuggestionListIndex = ref(-1)
const suggestionListDomel: Ref<HTMLUListElement | null> = ref(null)

const suggestionList: Ref<SuggestionListItem[]> = ref([])

const articleKeywords: ComputedRef<Set<string>> = computed(() => {
	return store.articleKeywords
})

// const preparedSuggestionPrePhrases: ComputedRef<string> = computed(() => {
// 	const prevPhrases = phrase.value.split(" ")
// 	if (prevPhrases.length) {
// 		prevPhrases.pop()
// 		return prevPhrases.join(" ").trim()
// 	}
// 	return ""
// })

const setSuggestionList = (): SuggestionListItem[] => {
	let items: SuggestionListItem[] = []
	const akws = articleKeywords.value
	const phraseParts = phrase.value.split(" ")
	if (phraseParts.length && phraseParts[0]) {
		let phraseStr = ""
		for (let pp of phraseParts) {
			pp = pp.replace(/[\s\|\/]/g, "")
			phraseStr += pp + "[\\s\\|\\/]*"
		}
		const rex = new RegExp(`^${phraseStr}`, "i")
		akws.forEach((kw) => {
			if (rex.test(kw)) {
				items.push({ phrase: kw, active: false })
			}
		})
	}
	return items
}

const selectSuggestionItem = (idx?: number) => {
	if (typeof idx !== "undefined") {
		if (suggestionList.value[idx]) {
			emit("userSuggestionSelect", suggestionList.value[idx].phrase)
		}
	} else {
		if (currentSuggestionListIndex.value !== -1) {
			if (suggestionList.value[currentSuggestionListIndex.value]) {
				emit("userSuggestionSelect", suggestionList.value[currentSuggestionListIndex.value].phrase)
			}
		}
	}
	nextTick(() => {
		suggestionList.value = []
	})
}

const handleKeyAction = (e: KeyboardEvent) => {
	if (e.key === "ArrowUp" || e.key === "ArrowDown") {
		e.stopPropagation()
		e.preventDefault()
		const sugList = suggestionList.value
		const sugListLen = sugList.length
		currentSuggestionListIndex.value = -1
		if (sugList && sugListLen) {
			for (const [idx, item] of sugList.entries()) {
				if (item.active) {
					currentSuggestionListIndex.value = idx
				}
				item.active = false
			}
			if (e.key === "ArrowUp") {
				if (currentSuggestionListIndex.value) {
					if (currentSuggestionListIndex.value < 0) {
						currentSuggestionListIndex.value = sugList.length - 1
					} else {
						currentSuggestionListIndex.value--
					}
				} else {
					currentSuggestionListIndex.value = -1
				}
			} else if (e.key === "ArrowDown") {
				if (currentSuggestionListIndex.value < 0) {
					currentSuggestionListIndex.value = 0
				} else if (currentSuggestionListIndex.value < sugList.length - 1) {
					currentSuggestionListIndex.value++
				} else {
					currentSuggestionListIndex.value = -1
				}
			}
			if (sugList[currentSuggestionListIndex.value]) {
				sugList[currentSuggestionListIndex.value].active = true
			}
			nextTick(() => {
				if (suggestionListDomel.value) {
					const activeItem = suggestionListDomel.value.querySelector("li.active")
					if (activeItem) {
						activeItem.scrollIntoView({ behavior: "smooth", block: "nearest", inline: "center" })
					}
				}
			})
		}
	} else if (e.key === "Enter") {
		selectSuggestionItem()
	}
}

watch(phrase, (curr) => {
	suggestionList.value = setSuggestionList()
})

watch(suggestionList, (sugList) => {
	if (sugList.length) {
		emit("isOpen", true)
		// cachedPrevPhrase.value = phrase.value
	} else {
		emit("isOpen", false)
	}
})

defineExpose(
	{ handleKeyAction }
)
</script>

<template>
	<div v-if="suggestionList.length" class="search-autocompletion-outer">
		<div class="inner">
			<div class="scrollpane">
				<ul @keydown="handleKeyAction" class="result-list" ref="suggestionListDomel">
					<li v-for="[idx, item] in suggestionList.entries()" @click="selectSuggestionItem(idx)" :class="['item', { active: item.active, invisible: item.invisible }]" :key="idx">{{ item.phrase }}</li>
				</ul>
			</div>
		</div>
	</div>
</template>

<style scoped lang="scss">
@import "@/sass/icon_font_style";

.search-autocompletion-outer {
	position: relative;
	z-index: 10000;

	.inner {
		position: absolute;
		z-index: 1000;
		background-color: var(--color-white);
		padding: 15px 0;
		border: 1px solid var(--color-silver-sand);

		.scrollpane {
			max-width: 80vw;
			min-width: 200px;
			min-height: 100px;
			max-height: 50vh;
			overflow: auto;

			.result-list {
				list-style: none;
				margin: 0;
				padding: 0;

				.item {
					padding: 4px 25px;
					cursor: pointer;
					background-color: var(--color-white);

					&:hover {
						transition: background-color .2s ease-in-out;
						background-color: var(--color-iron);
					}

					&.active {
						background-color: var(--color-iron);
					}

					&.invisible {
						height: 0;
						overflow: hidden;
						padding: 0;
						background-color: transparent;
					}
				}
			}
		}
	}
}
</style>