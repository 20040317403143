{
	"message": {
		"button-label-back": "Back",
		"button-label-print": "Print",
		"button-label-manage-bookmarklist": "My Bookmarks",
		"hint-no-bookmarks": "No entries available",
		"remove-entry": "Remove entry",
		"search-field-placeholder": "Search Term",
		"search-result-title": "Your search terms",
		"search-to-much-results-flash-message-text": "Too much of a good thing!<br/>We have found more than 30 results for your current search query.<br/>Please narrow down your enquiry further, or switch to »Exact search«.",
		"search-field-exact-label": "Exact search",
		"search-field-exact-label-tooltip": "The search becomes more targeted because the search algorithm for multiple words in the search field is changed from »or« to »and«!",
		"button-label-delete-all": "Delete all",
		"filter-info-title": "Set Filters",
		"found-filter-info-title": "found filters",
		"found-unfiltered-info-title": "unset filters",
		"hint-no-articles": "Unfortunately, no articles could be found",
		"hint-no-overlay-article": "Unfortunately, no article could be found! Please check the identifier",
		"form-select-first-entry-label-anchor-navigation": "Select a Jump Target",
		"button-label-faqs": "FAQs",
		"button-label-set-bookmark": "Bookmark",
		"hint-set-bookmark-no-bookmark-list": "You don't have a bookmark list yet, please create one. To do this, assign a descriptive identifier",
		"hint-new-bookmarklist": "Enter a descriptive identifier for your new bookmark list",
		"form-input-placeholder-bookmark-title": "Title",
		"button-label-cancel": "Cancel",
		"button-label-create-bookmarklist": "Create bookmarklist",
		"form-textarea-title-bookmark-notice": "Notes",
		"button-label-add-bookmark": "Add Bookmark",
		"button-label-article-show-complete-table": "Show table completely",
		"button-back-to-top-of-page": "To The Top",
		"overlay-header-title-overlay-article": "Additional info",
		"design-to-cost-regex": ".*standard.*design\\sto\\scost.*",
		"cash-info-regex": ".*advanced.*",
		"performance-message-header": "New Game, New Luck!",
		"performance-message-text": "No filters are currently set. Select the appropriate content from our  informative chapters on the left for the PCB issue that is currently on your mind, or use our search function.",
		"performance-message-text2": "Alternatively, you can also access the Design Compass via <a href=\"/PCB-Technologies.htm\">PCB Technologies <i class=\"icon icon-right_arrow\"></i></a> or <a href=\"/Applications--amp--Solutions.htm\">Applications & Solutions <i class=\"icon icon-right_arrow\"></i></a> with a filter set provided by us.",
		"sso-login-shell-no-frame-support-text": "Unfortunately, the browser used does not support iFrames.",
		"ddc-footer-imprint-text": "Imprint",
		"ddc-footer-imprint-link": "https://www.ksg-pcb.com/en/imprint",
		"ddc-footer-privacy_policy-text": "Privacy Policy",
		"ddc-footer-privacy_policy-link": "https://www.ksg-pcb.com/en/privacy",
		"flash-msg-to-many-search-results": "Too much of a good thing!<br/>We found more than 30 results for your current search query.<br/>Please narrow down your query further.",
		"portalpage-salutation-headline-snippet": "Hello",
		"portalpage-welcome-headline": "Welcome to the KSG Group's Digital Design Compass!",
		"portalpage-welcome-paragraph1": "Our smart platform supports you in fast and secure PCB development.",
		"portalpage-welcome-paragraph2": "In order to tailor the content of our platform optimally to your requirements and current projects,<br />we offer you various entry options.",
		"portalpage-entry-pcb-technology-label": "PCB Technologies",
		"portalpage-entry-pcb-technology-link": "/PCB-Technologies.htm",
		"portalpage-entry-solutions-label": "Applications & Solutions",
		"portalpage-entry-solutions-link": "/Applications--amp--Solutions.htm",
		"portalpage-entry-individual-label": "Individually and Without Filters",
		"portalpage-entry-individual-link": "/Design-Compass-en.htm?entry=64b165cb90ad458f53d6f5a0",
		"portalpage-entry-last-session-label": "Restore Last Session",
		"portalpage-entry-last-session-link": "/Design-Compass-en.htm?entry={session_entry_id}&session_restore=1",
		"portalpage-news-panel-header": "News and Updates",
		"portalpage-hackl-hacks-header": "The \"Hans Hackl Hacks\"",
		"portalpage-hackl-para-first": "In this series, Johann Hackl—also known as Hans Hackl—shares a variety of tips and tricks about the Digital Design Compass.",
		"portalpage-hackl-para-second": "In this video, Hans will introduce you to all the features and possibilities of this innovative tool. From basic applications to advanced techniques, he will show you how to get the most out of the Digital Design Compass!",
		"portalpage-hackl-hacks-video-src": "https://www.youtube.com/embed/Omg5sddHlSc?si=Ui676VRSiqme9KiG",
		"portalpage-newletter-header": "Hungry for News?",
		"portalpage-newletter-paragraph": "Get the latest information from the world of PCBs.",
		"portalpage-newletter-button-label": "Subscribe to Newsletter",
		"portalpage-newletter-link": "https://www.ksg-pcb.com/en/newsletter",
		"loginpage-tab-login-label": "Login",
		"loginpage-tab-register-label": "Register",
		"loginpage-register-hint-paragraph-text-1": "You don't have an account for the Digital Design Compass yet?",
		"loginpage-register-hint-paragraph-text-2": "Register here",
		"login-user-menu-logout-label": "Logout",
		"login-user-menu-label": "User",
		"login-user-menu-user-data-link-label": "My Data",
		"login-user-menu-testmode-off-link-label": "Testmode switch on",
		"login-user-menu-testmode-on-link-label": "Testmode switch off",
		"login-user-menu-user-account-link-label": "Change account data",
		"register-base-form-header": "Create Account",
		"register-base-form-error": "Please fill out all fields and check the email.",
		"register-base-form-disclaimer": "We also use your e-mail address to send you information about new offers and technical information. You can do so at any time at <a href=\"mailto:datenschutz{'@'}ksg-pcb.com\" target=\"_blank\">datenschutz{'@'}ksg-pcb.com</a> free of charge contradict. Our <a href=\"https://www.ksg-pcb.com/datenschutz\" target=\"_blank\">Privacy Policy</a> applies.",
		"register-base-form-data-success-msg-header": "Thanks very much!",
		"register-base-form-data-success-msg-paragraph-1": "Your registration for the Digital Design Compass is almost<br /> complete. After your details have been checked, you will receive an e-mail with your access data.",
		"register-base-form-data-success-msg-paragraph-2": "Please also check your spam folder.",
		"register-base-form-user-exists-msg-header": "User already registered!",
		"register-base-form-user-exists-msg-paragraph-1": "The username <span class=\"email\">{user_already_exists}</span> is already registered to use the Digital Design Compass.",
		"register-base-form-user-exists-msg-paragraph-2": "If you have forgotten your password, please go to the login and click on <i>Forgot your password</i> to set a new password.",
		"register-base-form-submit-button-label": "Register",
		"register-extended-form-header": "Registration",
		"register-extended-form-paragraph": "Give us additional information about your registration!",
		"register-extended-form-error": "Please fill out the required fields.",
		"register-extended-form-server-error": "An error occurred while transmitting the data. Please contact support!",
		"register-all-personal-data-form-header": "Personal Data",
		"register-all-extended-data-form-header": "Company Data",
		"account-data-form-header": "Change Account Data",
		"account-form-error": "Please check the data you have entered.",
		"account-change-process-error": "The data could not be changed because the following errors occurred:",
		"account-change-no-changes-msg": "No changes were made to username or password!",
		"account-change-success-header": "Your login details have been successfully updated!",
		"account-change-success-msg": "You will be automatically logged out shortly for security reasons! Please log in again with the new access data.",
		"form-prompt-salutation": "Salutation",
		"form-prompt-organisation": "Company",
		"form-prompt-firstname": "First Name",
		"form-prompt-surname": "Last Name",
		"form-prompt-role": "Role",
		"form-prompt-language": "Language",
		"form-prompt-email": "E-Mail",
		"form-prompt-industry": "Industry Sector",
		"form-prompt-department": "Department",
		"form-prompt-position": "Position",
		"form-prompt-phone": "Phone",
		"form-prompt-street": "Street and No.",
		"form-prompt-zip-code": "Postcode",
		"form-prompt-location": "City",
		"form-prompt-country": "Country",
		"form-prompt-user": "Username",
		"form-prompt-user-new": "New Username",
		"form-prompt-user-new-placeholder": "Only fill out if you also want to change the username",
		"form-prompt-old-password": "Currently used password",
		"form-prompt-new-password": "New Password",
		"form-prompt-new-password-repeat": "Repeat new Password",
		"form-prompt-no-matching-password-repeat": "Passwords do not match.",
		"form-prompt-account-password-error": "Enter the password for this account.",
		"form-prompt-username-email-error": "Please provide a valid email address.",
		"form-options-placeholder-salutation": "Select salutation",
		"form-options-salutation-mrs": "Ms.",
		"form-options-salutation-mr": "Mr.",
		"form-options-salutation-not-specified": "Not specified",
		"form-options-placeholder-role": "Select role",
		"form-options-role-customer": "KSG customer",
		"form-options-role-interested": "Prospect",
		"form-options-role-others": "Others",
		"form-options-language-german": "German",
		"form-options-language-english": "English",
		"form-options-placeholder-industry": "Select industry sector",
		"form-options-industry-electronic": "Industrial electronics",
		"form-options-industry-medicine": "Medical technology",
		"form-options-industry-mobility": "Mobility",
		"form-options-industry-communication": "Communication",
		"form-options-industry-ems": "EMS",
		"form-options-industry-space": "Aviation and aerospace",
		"form-options-industry-military": "Military technology",
		"form-options-industry-consumerelectronics": "Consumer electronics",
		"form-options-industry-others": "Others",
		"form-options-placeholder-department": "Select department",
		"form-options-department-development": "Engineering",
		"form-options-department-layout": "Layout",
		"form-options-department-purchasing": "Purchasing",
		"form-options-department-quality": "Quality",
		"form-options-department-sales": "Sales",
		"form-options-department-management": "Management",
		"form-options-department-administration": "Administration",
		"form-options-placeholder-function": "Select function",
		"form-options-function-head-of-department": "Head of department",
		"form-options-function-teamleader": "Team leadership",
		"form-options-function-employee": "Employee",
		"form-options-function-student": "Student/trainee",
		"register-extended-form-submit-button-label": "Save",
		"account-form-submit-button-label": "Update data",
		"bookmarks-share-button-label": "Share",
		"bookmarks-share-popup-title": "Who Do You Like to Share this Data With?",
		"bookmarks-share-popup-input-label": "E-mail Address",
		"bookmarks-share-popup-input-email-error-msg": "Invalid e-mail address!",
		"button-form-sending-label": "Send",
		"filter-hint-on-active-search": "Articles not matching the active search are added to your individual selection, but are not displayed immediately. They appear after changing or deleting the search query."
	}
}