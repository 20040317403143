<script setup lang="ts">
import { useStore } from "@/stores/main";
import { ref, toRefs, watch, onMounted, onUpdated, type ComputedRef, type PropType, type Ref } from "vue"
import { useI18n } from 'vue-i18n'
import { parse } from 'papaparse'
import type { JsonArticlePartTable, ElementTypeDef, JsonArticlePartSection, ParseResult, PopupContentData, JsonDownloads } from "@/types"
import { computed } from "vue"
import { getHighlightedText, getMarkdownParsedTextData } from "@/utils/utils"
import { HOSTNAME, fetchDownloadFile } from "@/utils/entrypoints";

const props = defineProps({
	data: {
		type: Object as PropType<any>,
		required: true,
	},
	articleId: {
		type: String,
		required: true,
	},
	template: {
		type: String,
		default: "Standard"
	},
	typeDefs: {
		type: Array<ElementTypeDef>,
		required: false,
	},
	sectionData: {
		type: Object as PropType<JsonArticlePartSection | null>,
		default: null,
		required: false,
	},
})

const store = useStore()
const { t, n } = useI18n({ useScope: "global" })
const { data, articleId, template, sectionData } = toRefs(props)
const unwrappedData = data.value.tabelle as JsonArticlePartTable
const unwrappedData2 = data.value.tabelle_2 as JsonArticlePartTable
const unwrappedData3 = data.value.tabelle_3 as JsonArticlePartTable
const unwrappedData4 = data.value.tabelle_4 as JsonArticlePartTable
const showPopup = ref(false)
const popupArticleId = ref("")

const closePopup = (e?: Event) => {
	popupArticleId.value = ""
	store.currentPopupContent = []
	showPopup.value = false
}

const closeAllPopups: ComputedRef<number> = computed(() => {
	return store.closeAllPopups
})

const searchPhrases: ComputedRef<string[]> = computed(() => {
	return store.getSearchPhrases
})

const downloads: ComputedRef<JsonDownloads[] | null> = computed(() => {
	return store.downloadsData
})

const getDataValue = (uwdata: JsonArticlePartTable, key: string) => {
	if (uwdata) {
		const keyVal = uwdata[`${key}`]
		return keyVal
	}
	return null
}


const getParsedCSVTableData = (uwdata: JsonArticlePartTable) => {
	if (uwdata) {
		return parse(getDataValue(uwdata, "tabelle_als_csv"), {
			delimiter: ";",
			error: (err: any) => {
				throw new Error(
					`CSV parsing error type ${err.type}, of code ${err.code}, in row ${err.row}: ${err.message}`
				)
			}
		})
	}
	return null
}

const getTableHeaderIdxByRex = (parsedData: Ref<ParseResult<string[]> | null>, regex: RegExp): number => {
	const pd = parsedData.value
	if (pd) {
		for (const [idx, str] of pd.data[0].entries()) {
			if (regex.test(str)) {
				return idx
			}
		}
	}
	return -1
}

const parsedData = ref<ParseResult<string[]> | null>(getParsedCSVTableData(unwrappedData))
const parsedData2 = ref<ParseResult<string[]> | null>(getParsedCSVTableData(unwrappedData2))
const parsedData3 = ref<ParseResult<string[]> | null>(getParsedCSVTableData(unwrappedData3))
const parsedData4 = ref<ParseResult<string[]> | null>(getParsedCSVTableData(unwrappedData4))

const designToCostRex = new RegExp(t("message.design-to-cost-regex"), "i")
const designToCostTableHeaderIdx: number = getTableHeaderIdxByRex(parsedData, designToCostRex)
const designToCostTableHeaderIdx2: number = getTableHeaderIdxByRex(parsedData2, designToCostRex)
const designToCostTableHeaderIdx3: number = getTableHeaderIdxByRex(parsedData3, designToCostRex)
const designToCostTableHeaderIdx4: number = getTableHeaderIdxByRex(parsedData4, designToCostRex)

const cashInfoRex = new RegExp(t("message.cash-info-regex"), "i")
const cashInfoTableHeaderIdx: number = getTableHeaderIdxByRex(parsedData, cashInfoRex)
const cashInfoTableHeaderIdx2: number = getTableHeaderIdxByRex(parsedData2, cashInfoRex)
const cashInfoTableHeaderIdx3: number = getTableHeaderIdxByRex(parsedData3, cashInfoRex)
const cashInfoTableHeaderIdx4: number = getTableHeaderIdxByRex(parsedData4, cashInfoRex)

const getTableIdentifier = (uwdata: JsonArticlePartTable): string => {
	if (sectionData.value) {
		return `${sectionData?.value?.identifizierer.toLowerCase()}_${articleId.value}_table`
	}
	return `${uwdata.identifizierer}_${articleId.value}_table`
}

const getColumnIdentifier = (id: string, currIdx: number): string => {
	if (sectionData.value && currIdx === 0) {
		return `${sectionData?.value?.identifizierer.toLowerCase()}_${id}`
	}
	return ""
}

const handleMarkdownLinkClick = function (e: Event) {
	e.stopPropagation()
	e.preventDefault()
	const eTarget = e.target as HTMLLinkElement
	if (eTarget.dataset.download && downloads.value) {
		for (const dl of downloads.value) {
			if (dl.identifizierer === eTarget.dataset.downloadTarget) {
				fetchDownloadFile(dl.id)
				break;
			}
		}
	} else {
		store.currentPopupContent.push({ __article__: eTarget.dataset.overlayTarget } as PopupContentData)
		store.overlayBrainCount++
	}
}

const bindTableLinksForOverlay = (uwdata: JsonArticlePartTable) => {
	if (uwdata) {
		const tableId = getTableIdentifier(uwdata)
		const prevNodes: HTMLLinkElement[] = []
		try {
			const linkElms = document.querySelectorAll(`#${tableId} a[data-overlay-target], #${tableId} a[data-download-target]`)
			linkElms.forEach(node => {
				const n = node as HTMLLinkElement
				if (!prevNodes.includes(n) && !n.dataset.hasTableLinkListener) {
					n.addEventListener("click", handleMarkdownLinkClick)
					if (!n.dataset.hasTableLinkListener) {
						n.dataset.hasTableLinkListener = "true"
					}
				}
				prevNodes.push(n)
			})
		} catch (error) {
			console.error(error)
		}
	}
}

const addIconForColumnIndex = (content: string, loopIdx: number, cashInfoIdx: number, designToCostIdx: number) => {
	if (cashInfoIdx > -1 && loopIdx === cashInfoIdx) {
		return `<div class="flex-th"><div class="th-content">${content}</div><div class="design-to-cost-column-icon"><i class="icon icon-euro"/></div></div>`
	} else if (designToCostIdx > -1 && loopIdx === designToCostIdx) {
		return `<div class="flex-th"><div class="th-content">${content}</div><div class="cash-info-column-icon"><i class="icon icon-piggy_bank"/></div></div>`
	}
	return content
}

watch(closeAllPopups, (clap: number) => {
	if (clap) {
		closePopup()
	}
})


onMounted(() => {
	bindTableLinksForOverlay(unwrappedData)
	bindTableLinksForOverlay(unwrappedData2)
	bindTableLinksForOverlay(unwrappedData3)
	bindTableLinksForOverlay(unwrappedData4)
})

onUpdated(() => {
	bindTableLinksForOverlay(unwrappedData)
	bindTableLinksForOverlay(unwrappedData2)
	bindTableLinksForOverlay(unwrappedData3)
	bindTableLinksForOverlay(unwrappedData4)
})
</script>

<template>
	<div>
		<section v-if="unwrappedData" class="table-outer">
			<h2 v-if="getDataValue(unwrappedData, 'titel')" v-html="getHighlightedText((getDataValue(unwrappedData, 'titel') as string), searchPhrases)"></h2>
			<div v-if="getDataValue(unwrappedData, 'tabelle_als_csv')" class="table-inner">
				<table v-if="parsedData" :id="getTableIdentifier(unwrappedData)" border="0" colspacing="0" rowspacing="0" :class="`ksg-data-table ksg-data-table-${template.toLowerCase()}`">
					<thead>
						<tr class="ksg-row ksg-row-0">
							<th v-for="(thdata, cidx) in parsedData.data[0]" :key="`${getTableIdentifier(unwrappedData)}_th_0_${cidx}`" v-html="addIconForColumnIndex(getHighlightedText(thdata, searchPhrases), cidx, cashInfoTableHeaderIdx, designToCostTableHeaderIdx)" :class="[`ksg-col ksg-col-${cidx}`, { 'design-to-cost-col': cidx === designToCostTableHeaderIdx, 'cash-info-col': cidx === cashInfoTableHeaderIdx }]"></th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="(rdata, ridx) in parsedData.data.slice(1)" :key="`${getTableIdentifier(unwrappedData)}_tr_0_${ridx}`" :class="`ksg-row ksg-row-${ridx}`">
							<td v-for="(cdata, cidx) in rdata" :key="`${getTableIdentifier(unwrappedData)}_td_0_${cidx}`" :id="getColumnIdentifier(cdata, cidx)" :class="[`ksg-col ksg-col-${cidx}`, { 'design-to-cost-col': cidx === designToCostTableHeaderIdx, 'cash-info-col': cidx === cashInfoTableHeaderIdx }]" v-html="getMarkdownParsedTextData(getHighlightedText(cdata, searchPhrases), 'table')"></td>
						</tr>
					</tbody>
				</table>
			</div>
		</section>
		<section v-if="unwrappedData2" class="table-outer">
			<h2 v-if="getDataValue(unwrappedData2, 'titel')" v-html="getHighlightedText((getDataValue(unwrappedData2, 'titel') as string), searchPhrases)"></h2>
			<div v-if="getDataValue(unwrappedData2, 'tabelle_als_csv')" class="table-inner">
				<table v-if="parsedData2" :id="getTableIdentifier(unwrappedData2)" border="0" colspacing="0" rowspacing="0" :class="`ksg-data-table ksg-data-table-${template.toLowerCase()}`">
					<thead>
						<tr class="ksg-row ksg-row-0">
							<th v-for="(thdata, cidx) in parsedData2.data[0]" :key="`${getTableIdentifier(unwrappedData2)}_th_1_${cidx}`" v-html="addIconForColumnIndex(getHighlightedText(thdata, searchPhrases), cidx, cashInfoTableHeaderIdx2, designToCostTableHeaderIdx2)" :class="[`ksg-col ksg-col-${cidx}`, { 'design-to-cost-col': cidx === designToCostTableHeaderIdx2, 'cash-info-col': cidx === cashInfoTableHeaderIdx2 }]"></th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="(rdata, ridx) in parsedData2.data.slice(1)" :key="`${getTableIdentifier(unwrappedData2)}_tr_1_${ridx}`" :class="`ksg-row ksg-row-${ridx}`">
							<td v-for="(cdata, cidx) in rdata" :key="`${getTableIdentifier(unwrappedData2)}_td_1_${cidx}`" :id="getColumnIdentifier(cdata, cidx)" :class="[`ksg-col ksg-col-${cidx}`, { 'design-to-cost-col': cidx === designToCostTableHeaderIdx2, 'cash-info-col': cidx === cashInfoTableHeaderIdx2 }]" v-html="getMarkdownParsedTextData(getHighlightedText(cdata, searchPhrases), 'table')"></td>
						</tr>
					</tbody>
				</table>
			</div>
		</section>
		<section v-if="unwrappedData3" class="table-outer">
			<h2 v-if="getDataValue(unwrappedData3, 'titel')" v-html="getHighlightedText((getDataValue(unwrappedData3, 'titel') as string), searchPhrases)"></h2>
			<div v-if="getDataValue(unwrappedData3, 'tabelle_als_csv')" class="table-inner">
				<table v-if="parsedData3" :id="getTableIdentifier(unwrappedData3)" border="0" colspacing="0" rowspacing="0" :class="`ksg-data-table ksg-data-table-${template.toLowerCase()}`">
					<thead>
						<tr class="ksg-row ksg-row-0">
							<th v-for="(thdata, cidx) in parsedData3.data[0]" :key="`${getTableIdentifier(unwrappedData3)}_th_2_${cidx}`" v-html="addIconForColumnIndex(getHighlightedText(thdata, searchPhrases), cidx, cashInfoTableHeaderIdx3, designToCostTableHeaderIdx3)" :class="[`ksg-col ksg-col-${cidx}`, { 'design-to-cost-col': cidx === designToCostTableHeaderIdx3, 'cash-info-col': cidx === cashInfoTableHeaderIdx3 }]"></th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="(rdata, ridx) in parsedData3.data.slice(1)" :key="`${getTableIdentifier(unwrappedData3)}_tr_2_${ridx}`" :class="`ksg-row ksg-row-${ridx}`">
							<td v-for="(cdata, cidx) in rdata" :key="`${getTableIdentifier(unwrappedData3)}_td_2_${cidx}`" :id="getColumnIdentifier(cdata, cidx)" :class="[`ksg-col ksg-col-${cidx}`, { 'design-to-cost-col': cidx === designToCostTableHeaderIdx3, 'cash-info-col': cidx === cashInfoTableHeaderIdx3 }]" v-html="getMarkdownParsedTextData(getHighlightedText(cdata, searchPhrases), 'table')"></td>
						</tr>
					</tbody>
				</table>
			</div>
		</section>
		<section v-if="unwrappedData4" class="table-outer">
			<h2 v-if="getDataValue(unwrappedData4, 'titel')" v-html="getHighlightedText((getDataValue(unwrappedData4, 'titel') as string), searchPhrases)"></h2>
			<div v-if="getDataValue(unwrappedData4, 'tabelle_als_csv')" class="table-inner">
				<table v-if="parsedData4" :id="getTableIdentifier(unwrappedData4)" border="0" colspacing="0" rowspacing="0" :class="`ksg-data-table ksg-data-table-${template.toLowerCase()}`">
					<thead>
						<tr class="ksg-row ksg-row-0">
							<th v-for="(thdata, cidx) in parsedData4.data[0]" :key="`${getTableIdentifier(unwrappedData4)}_th_3_${cidx}`" v-html="addIconForColumnIndex(getHighlightedText(thdata, searchPhrases), cidx, cashInfoTableHeaderIdx4, designToCostTableHeaderIdx4)" :class="[`ksg-col ksg-col-${cidx}`, { 'design-to-cost-col': cidx === designToCostTableHeaderIdx4, 'cash-info-col': cidx === cashInfoTableHeaderIdx4 }]"></th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="(rdata, ridx) in parsedData4.data.slice(1)" :key="`${getTableIdentifier(unwrappedData4)}_tr_3_${ridx}`" :class="`ksg-row ksg-row-${ridx}`">
							<td v-for="(cdata, cidx) in rdata" :key="`${getTableIdentifier(unwrappedData4)}_td_3_${cidx}`" :id="getColumnIdentifier(cdata, cidx)" :class="[`ksg-col ksg-col-${cidx}`, { 'design-to-cost-col': cidx === designToCostTableHeaderIdx4, 'cash-info-col': cidx === cashInfoTableHeaderIdx4 }]" v-html="getMarkdownParsedTextData(getHighlightedText(cdata, searchPhrases), 'table')"></td>
						</tr>
					</tbody>
				</table>
			</div>
		</section>
	</div>
</template>

<style lang="scss">
.table-outer {
	.ksg-data-table {
		width: 100%;
		// border: 1px solid var(--color-anthrazit);
		// border-collapse: collapse;
		margin: var(--table-margin);
		border-spacing: 0;

		.ksg-col {
			font-size: var(--table-head-font-size);
			font-weight: 600;
			padding: var(--table-body-col-padding);
			border-collapse: collapse;
		}

		td.ksg-col {
			font-size: var(--table-body-font-size);
			font-weight: 400;
			border: none;
			border-bottom: 2px solid var(--color-iron);
		}

		tbody tr {
			background-color: var(--color-white);
			transition: background-color .2s ease-in-out;
		}

		tbody tr:hover {
			background-color: var(--table-row-hover-color);

			.cash-info-col {
				background-color: var(--color-tulip-tree-light-hover);
			}

			.design-to-cost-col {
				background-color: var(--color-iron-light-hover);
			}
		}

		&-standard {
			thead {
				background-color: var(--color-nevada);
				white-space: nowrap;
				text-align: left;
				vertical-align: top;
				color: var(--color-white);

				.cash-info-col,
				.design-to-cost-col {
					.flex-th {
						display: flex;
						gap: 5px;

						.design-to-cost-column-icon,
						.cash-info-column-icon {
							width: 30px;
							height: 30px;
							background-color: var(--color-blaugruen);
							border-radius: 50%;
							padding: 3px;
							transform: translate(10px, -13px);

							.icon {
								display: inline-block;
								color: var(--color-white);
								font-size: 1.4rem;
								transform: translateX(-1px);
								cursor: default;
							}
						}

						.cash-info-column-icon {
							transform: translate(0, -13px);

							.icon {
								font-size: 1.6rem;
								transform: translateX(1px);
							}
						}
					}
				}

				.ksg-col:has(.text-90-left, .text-90-right) {
					vertical-align: bottom;
				}
			}

			tbody {

				.cash-info-col,
				.design-to-cost-col {
					transition: background-color .2s ease-in-out;
				}

				.cash-info-col {
					background-color: var(--color-tulip-tree-light);

					font[color="#EBB432"] {
						color: var(--color-anthrazit);

						b {
							font-weight: 200;
						}
					}
				}

				.design-to-cost-col {
					background-color: var(--color-iron-light);
				}
			}
		}
	}
}
</style>