import type { BookmarkData, JsonEntryTab, BaseRegisterUserData, ExtendedRegisterUserData, UpdateLastUserDataRequestFormData, UpdateUserDataStorageFormData, SharedData, AccountData } from "@/types";
import { getISOWithTimezoneString } from "@/utils/utils";

const HOSTNAME = "https://design-compass.ksg-pcb.com";

const userDataStoreUrl = (lang: string) => {
	if (import.meta.env.PROD) {
		return `${HOSTNAME}/gateway/apps/645de36a0541e8f8e187f942?template=userdata_json&lang=${lang}`;
	}
	return "http://localhost:3000/userdata";
};

const tabsUrl = (lang: string) => {
	if (import.meta.env.PROD) {
		return `${HOSTNAME}/gateway/apps/63273bb87d0a82e21dd9c212?template=tabs_tpl&lang=${lang}`;
	}
	return `${HOSTNAME}/gateway/apps/63273bb87d0a82e21dd9c212?template=tabs_tpl&lang=${lang}`;
};

const filterUrl = (entryTab: JsonEntryTab | null, lang: string, testmode: boolean) => {
	const entryId = entryTab ? entryTab.id : "";
	if (import.meta.env.PROD) {
		return `${HOSTNAME}/gateway/apps/6327505bad126a6bf16f5512?template=filter_tpl&entry_id=${entryId}&lang=${lang}&testmode=${testmode ? "1" : "0"}`;
	}
	return `${HOSTNAME}/gateway/apps/6327505bad126a6bf16f5512?template=filter_tpl&entry_id=${entryId}&lang=${lang}&testmode=${testmode ? "1" : "0"}`;
};

const articleUrl = (entryTab: JsonEntryTab | null, lang: string) => {
	const entryId = entryTab ? entryTab.id : "";
	if (import.meta.env.PROD) {
		return `${HOSTNAME}/gateway/apps/63275e6c72149bf61a09d852?template=article_json&entry_id=${entryId}&lang=${lang}`;
	}
	return `${HOSTNAME}/gateway/apps/63275e6c72149bf61a09d852?template=article_json&entry_id=${entryId}&lang=${lang}`;
};

const articleBranchedUrl = (entryTab: JsonEntryTab | null, lang: string) => {
	const entryId = entryTab ? entryTab.id : "";
	if (import.meta.env.PROD) {
		return `${HOSTNAME}/gateway/apps/656da6e7a08f3797893c7c51?template=article_json&entry_id=${entryId}&lang=${lang}`;
	}
	return `${HOSTNAME}/gateway/apps/656da6e7a08f3797893c7c51?template=article_json&entry_id=${entryId}&lang=${lang}`;
};

const blogUrl = (lang: string) => {
	if (import.meta.env.PROD) {
		return `${HOSTNAME}/gateway/apps/645372b8680df02e19b26740?template=blog_tpl&lang=${lang}`;
	}
	return `${HOSTNAME}/gateway/apps/645372b8680df02e19b26740?template=blog_tpl&lang=${lang}`;
};

const bookmarksUrl = (user: string) => {
	if (import.meta.env.PROD) {
		return `${HOSTNAME}/gateway/apps/632a0e797bf3006ae1e9c9cd?template=merkliste&user=${encodeURIComponent(user)}`;
	}
	return "http://localhost:3000/bookmarks";
};

const downloadsUrl = (lang: string) => {
	if (import.meta.env.PROD) {
		return `${HOSTNAME}/gateway/apps/653104ace84fe2da7f0a80ae?template=downloads&lang=${lang}`;
	}
	return `${HOSTNAME}/gateway/apps/653104ace84fe2da7f0a80ae?template=downloads&lang=${lang}`;
};


const getExistingUserData = (email: string) => {
	if (import.meta.env.PROD) {
		return `${HOSTNAME}/gateway/apps/644659ece04c70bb398a32c0?template=user_registration&email=${encodeURIComponent(email)}`;
	}
	return `${HOSTNAME}/gateway/apps/644659ece04c70bb398a32c0?template=user_registration&email=${encodeURIComponent(email)}`;
};

const newBookmarkList = async (data: BookmarkData): Promise<Response> => {
	const formData: FormData = new FormData();
	formData.append("user", data.user);
	formData.append("identifizierer", new Date().getTime().toString());
	formData.append("titel", data.newBmListTitle);
	const url = `${HOSTNAME}/gateway/apps/632a0e797bf3006ae1e9c9cd?template=new_bookmark_list`;
	try {
		return await fetch(new Request(url), {
			method: "POST",
			body: formData,
		});
	} catch (status) {
		const message = `Unable to fulfill new bookmark list fetch request: ${status}, data: ${JSON.stringify(data)}`;
		throw new Error(message);
	}
};

const newBookmarkListEntry = async (data: BookmarkData): Promise<Response> => {
	const formData: FormData = new FormData();
	formData.append("merkliste", data.bmid);
	formData.append("identifizierer", new Date().getTime().toString());
	formData.append("artikel_id", data.articleid);
	formData.append("titel", data.titel);
	formData.append("notizen", data.notizen);
	const url = `${HOSTNAME}/gateway/apps/632a115402486dc22bdba8e0?template=new_bookmark_list_entry`;
	try {
		return await fetch(new Request(url), {
			method: "POST",
			body: formData,
		});
	} catch (status) {
		const message = `Unable to fulfill new bookmark list entry fetch request: ${status}, data: ${JSON.stringify(data)}`;
		throw new Error(message);
	}
};

const deleteBookmarkList = async (id: string): Promise<Response> => {
	const formData: FormData = new FormData();
	formData.append("id", id);
	const url = `${HOSTNAME}/gateway/apps/632a0e797bf3006ae1e9c9cd?template=delete_bookmark_list`;
	try {
		return await fetch(new Request(url), {
			method: "POST",
			body: formData,
		});
	} catch (status) {
		const message = `Unable to fulfill delete bookmark list fetch request: ${status}, id: ${id}`;
		throw new Error(message);
	}
};

const deleteBookmarkListEntry = async (id: string): Promise<Response> => {
	const formData: FormData = new FormData();
	formData.append("id", id);
	const url = `${HOSTNAME}/gateway/apps/632a115402486dc22bdba8e0?template=delete_bookmark_entry`;
	try {
		return await fetch(new Request(url), {
			method: "POST",
			body: formData,
		});
	} catch (status) {
		const message = `Unable to fulfill delete bookmark entry fetch request: ${status}, id: ${id}`;
		throw new Error(message);
	}
};

const registerUser = async (data: BaseRegisterUserData): Promise<Response> => {
	const formData: FormData = new FormData();
	formData.append("anrede2", data.anrede2);
	formData.append("vorname", data.vorname);
	formData.append("nachname", data.nachname);
	formData.append("e_mail", data.e_mail.toLowerCase());
	formData.append("organisation", data.organisation);
	formData.append("sprache", data.sprache);
	formData.append("ksg_kunde_oder_interessent", data.ksg_kunde_oder_interessent);
	formData.append("registriert_stufe_1", getISOWithTimezoneString());
	const urlIdStr = data.id ? `/${data.id}` : "";
	const url = `${HOSTNAME}/gateway/apps/644659ece04c70bb398a32c0${urlIdStr}?template=user_registration`;
	try {
		return await fetch(new Request(url), {
			method: "POST",
			body: formData,
		});
	} catch (status) {
		const message = `Unable to fulfill new user registration fetch request: ${status}, data: ${JSON.stringify(data)}`;
		throw new Error(message);
	}
};

const registerUserExtended = async (data: ExtendedRegisterUserData): Promise<Response> => {
	const formData: FormData = new FormData();
	formData.append("branche", data.branche);
	formData.append("abteilung", data.abteilung);
	formData.append("funktion", data.funktion);
	formData.append("telefonnummer", data.telefonnummer);
	formData.append("strasse2", data.strasse2);
	formData.append("postleitzahl", data.postleitzahl);
	formData.append("ort", data.ort);
	formData.append("land", data.land);
	formData.append("registriert_stufe_2", getISOWithTimezoneString());
	const url = `${HOSTNAME}/gateway/apps/644659ece04c70bb398a32c0/${data.id}?template=user_registration`;
	try {
		return await fetch(new Request(url), {
			method: "POST",
			body: formData,
		});
	} catch (status) {
		const message = `Unable to fulfill new user registration fetch request: ${status}, data: ${JSON.stringify(data)}`;
		throw new Error(message);
	}
};

const registeredUserAll = async (baseData: BaseRegisterUserData, extData: ExtendedRegisterUserData): Promise<Response> => {
	const formData: FormData = new FormData();
	formData.append("edit", "true");
	formData.append("anrede2", baseData.anrede2);
	formData.append("vorname", baseData.vorname);
	formData.append("nachname", baseData.nachname);
	formData.append("organisation", baseData.organisation);
	formData.append("sprache", baseData.sprache);
	formData.append("ksg_kunde_oder_interessent", baseData.ksg_kunde_oder_interessent);
	formData.append("branche", extData.branche);
	formData.append("abteilung", extData.abteilung);
	formData.append("funktion", extData.funktion);
	formData.append("telefonnummer", extData.telefonnummer);
	formData.append("strasse2", extData.strasse2);
	formData.append("postleitzahl", extData.postleitzahl);
	formData.append("ort", extData.ort);
	formData.append("land", extData.land);
	const url = `${HOSTNAME}/gateway/apps/644659ece04c70bb398a32c0/${extData.id}?template=user_registration`;
	try {
		return await fetch(new Request(url), {
			method: "POST",
			body: formData,
		});
	} catch (status) {
		const message = `Unable to fulfill new user registration fetch request: ${status}, data: ${JSON.stringify(extData)}`;
		throw new Error(message);
	}
};

const updateUserDataStorage = async (data: UpdateUserDataStorageFormData): Promise<Response | void> => {
	if (import.meta.env.PROD) {
		const formData: FormData = new FormData();
		formData.append("sprache", data.sprache);
		formData.append("json_daten", data.json_daten as string);
		const url = `${HOSTNAME}/gateway/apps/645de36a0541e8f8e187f942/${data.id}?template=save_userdata_json`;
		try {
			return await fetch(new Request(url), {
				method: "POST",
				body: formData,
			});
		} catch (status) {
			const message = `Unable to fulfill save session user data fetch request: ${status}, data: ${JSON.stringify(data)}`;
			throw new Error(message);
		}
	}
};

const updateLastUserDataRequest = async (data: UpdateLastUserDataRequestFormData): Promise<Response> => {
	const formData: FormData = new FormData();
	formData.append("sprache", data.sprache);
	formData.append("letzte_benutzerabfrage", data.letzte_benutzerabfrage);
	const url = `${HOSTNAME}/gateway/apps/645de36a0541e8f8e187f942/${data.id}?template=save_userdata_json`;
	try {
		return await fetch(new Request(url), {
			method: "POST",
			body: formData,
		});
	} catch (status) {
		const message = `Unable to fulfill save session user data fetch request: ${status}, data: ${JSON.stringify(data)}`;
		throw new Error(message);
	}
};

const newShareProtocol = async (data: SharedData): Promise<Response> => {
	const formData: FormData = new FormData()
	formData.append("typ", data.type || "merkliste")
	formData.append("sprache", data.lang)
	formData.append("entryid", data.entryId)
	formData.append("benutzer_quelle", data.sourceUser.toLowerCase())
	formData.append("benutzer_ziel", data.targetUser.toLowerCase())
	formData.append("identifizierer_quelle	", data.identifier)
	const url = `${HOSTNAME}/gateway/apps/65217816815247b9878559a3?template=new_share_entry`
	try {
		return await fetch(new Request(url), {
			method: "POST",
			body: formData,
		})
	} catch (status) {
		const message = `Unable to fulfill new bookmark list fetch request: ${status}, data: ${JSON.stringify(data)}`
		throw new Error(message)
	}
}

const fetchDownloadFile = async (recordId: string) => {
	const url = `${HOSTNAME}/gateway/apps/653104ace84fe2da7f0a80ae/${recordId}?template=download_file`
	window.location.href = url
}

const changeAccount = async (data?: AccountData): Promise<Response> => {
	const formData: FormData = new FormData()
	if (data) {
		formData.append("old_password", data.altes_passwort)
		formData.append("new_password", data.passwort)
		formData.append("user_email", data.benutzer_email_neu)
		formData.append("sprache", data.sprache)
	}
	const url = `${HOSTNAME}/gateway/apps/65e9ca21370be7a0f1ea8f42?template=change_account`
	try {
		return await fetch(new Request(url), {
			method: "POST",
			body: formData,
		})
	} catch (status) {
		const message = `Unable to fulfill change account fetch request: ${status}`
		throw new Error(message)
	}
}



export {
	HOSTNAME,
	userDataStoreUrl,
	tabsUrl,
	filterUrl,
	articleUrl,
	articleBranchedUrl,
	blogUrl,
	bookmarksUrl,
	downloadsUrl,
	getExistingUserData,
	newBookmarkList,
	newBookmarkListEntry,
	deleteBookmarkList,
	deleteBookmarkListEntry,
	registerUser,
	registerUserExtended,
	registeredUserAll,
	updateUserDataStorage,
	updateLastUserDataRequest,
	newShareProtocol,
	fetchDownloadFile,
	changeAccount,
};
