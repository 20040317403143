<script setup lang="ts">
import { computed, onMounted, ref, toRefs, watch, type ComputedRef, type PropType } from "vue";
import type { JsonBookmarkEntries } from "@/types";
import { useStore } from "@/stores/main";
import { useI18n } from 'vue-i18n';

const props = defineProps({
	entry: {
		type: Object as PropType<JsonBookmarkEntries>,
		required: true,
	},
});

const store = useStore();
const { t, n } = useI18n({ useScope: "global" });
const { artikelid, id, notizen } = toRefs(props.entry);

const startBookmarkView = (e: Event) => {
	store.updateBookmarkCurrArticle(artikelid.value);
};

const bookmarkView: ComputedRef<string> = computed(() => {
	return store.getBookmarkCurrArticle
});

const getTitle = (identifier: string) => {
	const node = store.getArticleDataByIdentifier(identifier);
	if (node) {
		return node.titel;
	}
	return "Title not found!";
};

const checkItemHere = () => {
	return bookmarkView.value === artikelid.value;
};

const removeEntry = async (e: Event) => {
	await store.deleteBookmarkEntry(id.value);
};

onMounted(() => {
	const accRootElm = document.querySelectorAll(".vcpg") as NodeListOf<HTMLDivElement>;
	if (accRootElm) {
		accRootElm.forEach(elm => {
			elm.removeAttribute("style");
		});
	}
});
</script>


<template>
	<div class="bm-list-item" :class="{ 'bm-list-item-here': checkItemHere() }">
		<h3><a href="#" @click.stop.prevent="startBookmarkView($event)">{{ getTitle(artikelid) }}</a></h3>
		<p v-if="notizen" class="note-text">{{ notizen }}</p>
		<div v-if="!bookmarkView" class="flex-outer menue">
			<a @click.prevent="removeEntry($event)" href="#" class="flex-item menue-item">
				{{ t("message.remove-entry") }}
			</a>
		</div>
	</div>
</template>

<style scoped lang="scss">
.bm-list-item {
	padding: 0 0.5rem;

	h3 {
		font-size: inherit !important;
	}

	p.note-text {
		font-size: var(--medium-font-size);
		margin: 0 0 12px !important;
	}

	a,
	a:link,
	a:visited,
	a:hover {
		text-decoration: none;
		font-weight: bold;

		&.menue-item {
			font-weight: normal;
		}
	}

	text-decoration: none;
	// background-color: var(--color-white);
	// box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, .3);
	// border-bottom-right-radius: var(--small-border-bottom-right-radius);

	&:hover {
		background-color: var(--color-yellow-green);
	}

	&-here {
		color: var(--color-anthrazit);
		background-color: var(--color-yellow-green);
	}

	.flex-outer {
		display: flex;
		flex-direction: row;
		justify-content: flex-end;
		align-items: center;
		gap: 1rem;
		margin-top: 0.3rem;

		.flex-item {
			user-select: none;
			font-size: .8rem;
		}
	}
}
</style>