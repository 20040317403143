<script setup lang="ts">
import { useStore } from "@/stores/main"
import { type ComputedRef, computed, ref, toRefs, watch, nextTick } from "vue"
import { useI18n } from 'vue-i18n'
import SearchAutocompletion from "@/components/SearchAutocompletion.vue"

const props = defineProps({
	hideOnBookmark: {
		type: String,
		default: "",
	},
})

const { hideOnBookmark } = toRefs(props)
const store = useStore()
const { t, n } = useI18n({ useScope: "global" })

const currentPhrase = ref("")
const searchAutocompletion = ref()
const searchAutocomplOpen = ref(false)

const teleportDomTarget: ComputedRef<HTMLElement | null> = computed(() => {
	return store.searchDomTarget
})

const searchPhrase: ComputedRef<string> = computed(() => {
	return store.searchPhrase
})

const searchPhrases: ComputedRef<string[]> = computed(() => {
	return [...store.getSearchPhrases]
})

const hasCurrFilters = ref(true)

const clearSearch = () => {
	store.searchPhrase = ""
	currentPhrase.value = ""
	store.updateArticleData()
}

const handleAutocompleteState = (isOpen: boolean) => {
	searchAutocomplOpen.value = isOpen
}

const setCurrentPhrase = (phrase: string) => {
	currentPhrase.value = phrase.trim() + " "
	nextTick(() => {
		processSearch()
	})
}

const processSearch = () => {
	const val = currentPhrase.value
	store.searchPhrase = val.trim()
	const sp = searchPhrases.value
	if (sp.length) {
		const lastPart = sp.pop() as string
		const minLength = isNaN(parseFloat(lastPart)) ? 3 : 0
		if (!lastPart.length || lastPart.length >= minLength) {
			store.updateArticleData()
			if ((window as any)._paq) {
				(window as any)._paq.push(["trackEvent", "Search", "Process Search", val]);
			}
		}
	}
}

const handleKeyAction = (e: KeyboardEvent) => {
	if (e.key === "ArrowUp" || e.key === "ArrowDown") {
		e.stopPropagation()
		e.preventDefault()
		searchAutocompletion.value.handleKeyAction(e)
	} else if (e.key === "Enter") {
		if (searchAutocomplOpen.value) {
			searchAutocompletion.value.handleKeyAction(e)
		} else {
			processSearch()
		}
	}
}

watch(currentPhrase, (cp) => {
	if (cp.trim() !== searchPhrase.value) {
		store.flashMessage = null
	}
})

watch(searchPhrase, (phrase) => {
	if (!phrase) {
		clearSearch()
	}
})

watch(() => store.currentUsedFilters, (filterList) => {
	if (filterList) {
		hasCurrFilters.value = filterList.length ? true : false
	}
})
</script>

<template>
	<div v-if="!hideOnBookmark">
		<Teleport v-if="teleportDomTarget" :to="teleportDomTarget">
			<div class="search-outer">
				<div class="field-outer">
					<div class="phrase">
						<input id="searchWebApps" @keydown="handleKeyAction($event)" type="text" autocomplete="off" :placeholder="t('message.search-field-placeholder')" v-model="currentPhrase" class="field">
						<div v-if="searchPhrase && searchPhrase === currentPhrase.trim()" class="clear-search-button icon icon-cross" @click="clearSearch"></div>
						<div v-else-if="hasCurrFilters" class="search-button icon icon-magnifier" @click="processSearch"></div>
					</div>
					<div class="checkbox-outer">
						<input type="checkbox" id="searchWebAppsCheckbox" v-model="store.searchExactMode" class="checkbox">
						<i v-if="store.searchExactMode" class="checkmark icon icon-checkbox_hook"></i>
						<label for="searchWebAppsCheckbox" class="checkbox-label">
							{{ t('message.search-field-exact-label') }}
							<i @click="(e: Event) => { e.stopPropagation(); e.preventDefault() }" class="info icon icon-info" :title="t('message.search-field-exact-label-tooltip')"></i>
						</label>
					</div>
				</div>
				<SearchAutocompletion @isOpen="handleAutocompleteState" @userSuggestionSelect="setCurrentPhrase" :phrase="currentPhrase" ref="searchAutocompletion"></SearchAutocompletion>
			</div>
		</Teleport>
		<div v-else class="search-outer">
			<div class="phrase">
				<input id="searchWebApps" @keyDown="handleKeyAction($event)" type="text" autocomplete="off" :placeholder="t('message.search-field-placeholder')" v-model="currentPhrase" class="field">
				<div v-if="searchPhrase && searchPhrase === currentPhrase.trim()" class="clear-search-button icon icon-cross" @click="clearSearch"></div>
				<div v-else-if="hasCurrFilters" class="search-button icon icon-magnifier" @click="processSearch"></div>
			</div>
			<SearchAutocompletion @isOpen="handleAutocompleteState" @userSuggestionSelect="setCurrentPhrase" :phrase="currentPhrase" ref="searchAutocompletion"></SearchAutocompletion>
		</div>
	</div>
</template>

<style scoped lang="scss">
@import "@/sass/icon_font_style";

.search-outer {

	&.has-no-filters {
		opacity: .6;
	}

	.field-outer {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: flex-start;
		gap: 16px;

		.phrase {
			position: relative;
			flex-grow: 1;

			input.field {
				box-sizing: border-box;
				appearance: none;
				color: var(--color-nevada);
				transition: all .3s ease-out;
				font-size: 1rem;
				width: 100%;
				outline: 0;
				line-height: normal;
				padding: 12px 50px 12px 27px;
				background-color: #fff;
				border: 1px solid var(--color-blaugruen);
				user-select: text;
				margin: 0;

				&:hover {
					border-color: var(--color-persian-green);
				}
			}

			.field {
				position: relative;

				&::placeholder {
					color: var(--color-nevada);
				}
			}

			.search-button,
			.clear-search-button {
				position: absolute;
				right: 18px;
				top: 6px;
				color: var(--color-blaugruen);
				background-color: #fff;
				padding: 3px;
				line-height: normal;
				font-size: 1.5rem;
			}

			.clear-search-button {
				top: 8px;
				cursor: pointer;
				transition: color 0.2s ease-in-out;
				padding: 5px;
				font-size: 1.2rem;
				right: 18px;

				&:hover {
					color: var(--color-blaugruen);
				}
			}
		}
	}

	.checkbox-outer {
		position: relative;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		gap: 8px;

		.checkbox {
			position: relative;
			z-index: 10;
			appearance: none;
			-webkit-appearance: none;
			-moz-appearance: none;
			width: 24px;
			height: 24px;
			border: 2px solid var(--color-white);
			cursor: pointer;
			transition: all .3s ease-out;

			&:checked+.checkbox-label {
				color: var(--color-white);
			}
		}

		.checkmark {
			position: absolute;
			z-index: 0;
			left: 4px;
			color: var(--color-white);
			font-size: .9rem;
		}

		.checkbox-label {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: flex-start;
			gap: 12px;
			color: var(--color-white);
			font-size: 1rem;
			cursor: pointer;
			transition: color .3s ease-out;
			user-select: none;

			.info {
				color: var(--color-gelbgruen);
				font-size: 1.2rem;
				font-weight: 600;
				cursor: help;
			}
		}
	}


}
</style>
