<script setup lang="ts">
import { toRefs } from 'vue';


const props = defineProps({
	msg: {
		type: String,
		required: true,
	},
	xhtml: {
		type: Boolean
	}
});

const { msg, xhtml } = toRefs(props);
</script>

<template>
	<div v-html="msg" class="standard-message"></div>
</template>

<style scoped lang="scss">
.standard-message {
	font-size: var(--header-1);
	text-align: center;
	margin: 40px 0 0;
	color: var(--color-anthrazit);
}
</style>