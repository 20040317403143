<script setup lang="ts">
import { useStore } from "@/stores/main";
import type { ITreeNode, JsonArticle } from "@/types";
import { toRefs, computed, type ComputedRef, type PropType } from "vue";
import { jumpToArticleAnchor } from "@/utils/utils";
import Checker from "@/components/Checker.vue";

const props = defineProps({
	title: {
		type: String,
		default: "",
	},
	node: {
		type: Object as PropType<ITreeNode>,
		default: null,
	},
	woicon: {
		type: Boolean,
	},
	wochecker: {
		type: Boolean,
		default: true,
	},
	highlight: {
		type: Boolean,
	},
	hasevent: {
		type: Boolean,
		default: false,
	},
	searchphrase: {
		type: Boolean,
		default: false,
	},
	checkerTooltip: {
		type: String,
		default: "",
	}
});

const store = useStore();
const { title, node, woicon, wochecker, highlight, hasevent, searchphrase, checkerTooltip } = toRefs(props);

const article: ComputedRef<JsonArticle | null> = computed(() => {
	const filterId = node.value ? node.value.id : "";
	return store.getArticleDataByFilterIdentifier(filterId);
});

const pillArticleOnStage: ComputedRef<boolean> = computed(() => {
	if (node.value) {
		const id = node.value.id;
		const article = store.getArticleDataByFilterIdentifier(id);
		if (article && store.currentIntersectedArticleIds.includes(article.id)) {
			return true;
		}
	}
	return false;
});

const handleDeleteIconClick = (e: Event) => {
	if (node.value) {
		store.updateUsedFilters(node.value);
	} else {
		const searchPhrases = [...store.getSearchPhrases];
		if (searchPhrases.length) {
			store.searchPhrase = searchPhrases.filter((phrase) => {
				if (title.value && phrase.toLowerCase().trim() === title.value?.toLowerCase().trim()) {
					return false;
				}
				return true;
			}).join(" ");
		}
	}
};
</script>

<template>
	<div class="pill-outer" :class="['hasevent', { highlight: highlight, highlight2: pillArticleOnStage }]">
		<div v-if="searchphrase" class="pill pill-searchphrase">
			<div v-if="!woicon" @click.stop="handleDeleteIconClick" class="icon icon-cross"></div>
			<div class="title">{{ title }}</div>
		</div>
		<div v-else class="pill">
			<div v-if="!woicon" @click.stop="handleDeleteIconClick" class="icon icon-cross"></div>
			<Checker v-if="node && !wochecker" :node="node" :checked-status="node.checkerstate" :tooltip="checkerTooltip" small />
			<div v-if="title && !hasevent" @click.stop.prevent="jumpToArticleAnchor(`__${article?.id}`, store.searchPhrase)" class="title">{{ title }}</div>
			<div v-else class="title">{{ title }}</div>
		</div>
	</div>
</template>

<style scoped lang="scss">
@import "@/sass/icon_font_style";

.pill-outer {
	display: inline-block;

	&.hasevent {
		&.highlight {
			.pill {
				background: var(--color-iron);
			}
		}

		&.highlight2 {
			.pill {
				background-color: var(--color-yellow-green);
			}
		}

		.pill {
			cursor: pointer;
			display: flex;
			flex-direction: row;
			flex-shrink: 0;
			align-items: center;
			padding: 5px 10px;
			gap: 10px;
			background: var(--color-white);
			border-radius: 20px;
			font-size: .8rem;
			line-height: 1;

			&:hover {
				color: var(--color-persian-green);
				text-decoration: underline;

				.icon {
					text-decoration: none;

					&::before {
						text-decoration: none;
					}
				}
			}

			.title {
				color: var(--color-hover-dark-green);
			}

			&-searchphrase {
				.title {
					color: var(--color-anthrazit);
					cursor: default;
				}

				&:hover {
					text-decoration: none;
				}
			}

			.icon {
				font-size: .8rem;
			}

		}
	}
}
</style>
