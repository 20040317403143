<script setup lang="ts">
import { useStore } from "@/stores/main";
import { useI18n } from 'vue-i18n';
import { HOSTNAME } from "@/utils/entrypoints";
import { debounceFunction, bindCMSLangSwitcherForDDC } from "@/utils/utils";
import FilterTree from "@/components/FilterTree.vue";
import Search from "@/components/Search.vue";
import SearchFilterInfo from "./components/SearchFilterInfo.vue";
import { watch, computed, onMounted, ref, provide, type ComputedRef, type Ref } from "vue";
import ArticlesOuter from "./components/ArticlesOuter.vue";
import PopupModal from "./components/PopupModal.vue";
import type { JsonBookmarks, PopupContentData } from "@/types";
import Bookmarks from "./components/Bookmarks.vue";
import ArticleScrollNavigator from "@/components/ArticleScrollNavigator.vue";
import BreadcrumbNavigation from "@/components/BreadcrumbNavigation.vue";
import PrintButton from "@/components/PrintButton.vue";
import FlashMessage from "@/components/FlashMessage.vue";
import { useIntersectionObserver } from "@/composables/intersectionObserver";

const store = useStore();
const { t } = useI18n({ useScope: "global" });

const showFilter = ref(true);
const filterSearchResult: Ref<HTMLDivElement | null> = ref(null);
const gridOuter: Ref<HTMLDivElement | null> = ref(null);
const popupDomel: Ref<{ popupDomel: HTMLDivElement | null } | null> = ref(null);
const contentScrollPane: Ref<HTMLElement | null> = ref(null);
provide("contentScrollPane", contentScrollPane);

const filterCol: Ref<HTMLDivElement | null> = ref(null);

const closeAllPopups: ComputedRef<number> = computed(() => {
  return store.closeAllPopups;
});

const searching: ComputedRef<boolean> = computed(() => {
  return store.searching;
});

const currPopupContent: ComputedRef<PopupContentData | null> = computed(() => {
  if (store.currentPopupContent.length) {
    return store.currentPopupContent.at(-1) as PopupContentData;
  }
  return null;
});

const bookmarks: ComputedRef<JsonBookmarks[]> = computed(() => {
  return store.getBookmarks;
});

const bookmarkCurrArticle: ComputedRef<string> = computed(() => {
  return store.getBookmarkCurrArticle;
});

const closePopup = () => {
  store.currentPopupContent = [];
};

const closeBookmarkView = () => {
  store.currentIntersectedArticleIds = [];
  store.bookmarkCurrArticle = "";
};


const calcGridHeight = () => {
  let fsrHeight = 0;
  if (filterSearchResult.value) {
    fsrHeight = filterSearchResult.value.clientHeight;
  }
  const h = window.innerHeight - 115 - fsrHeight;
  if (gridOuter.value) {
    (gridOuter.value as HTMLDivElement).style.cssText = `height: ${h}px;`;
  }
};

const handleUrlParamOverlayTarget = () => {
  const paramStr = window.location.search
  const pObj = new URLSearchParams(paramStr)
  if (pObj.has("sharedArticle")) {
    store.currentPopupContent.push({ __article__: pObj.get("sharedArticle") } as PopupContentData)
  }
}

const handleSearchScrollToHighlight = () => {
  const mutationObs = new MutationObserver((mutations) => {
    if (searching.value) {
      for (const mutation of mutations) {
        if (mutation.type === "childList") {
          const searchResult = document.querySelector(".accent")
          if (searchResult) {
            searchResult.scrollIntoView({ behavior: "smooth" })
          }
        }
      }
      store.searching = false
    }
  })
  const scrollpane = document.querySelector(".content-column") as HTMLElement
  mutationObs.observe(scrollpane, { childList: true, subtree: true })
}

watch(closeAllPopups, (clap: number) => {
  if (clap) {
    closePopup();
  }
});

watch(currPopupContent, () => {
  if (popupDomel.value && popupDomel.value.popupDomel) {
    const scrollPane = popupDomel.value.popupDomel.querySelector(".flex-inner-content");
    if (scrollPane) {
      scrollPane.scrollTo(0, 0);
    }
  }
});

onMounted(async () => {
  useIntersectionObserver("contentIntersecObs", (entries) => {
    for (const entry of entries) {
      const entryId = entry.target.id.replace(/__/, "");
      const realIntersections = [];
      if (entry.isIntersecting) {
        store.currentIntersectedArticleIds.push(entryId);
      } else {
        for (const id of store.currentIntersectedArticleIds) {
          if (id !== entryId) {
            realIntersections.push(id);
          }
        }
        store.currentIntersectedArticleIds = realIntersections;
      }
      if (store.currentIntersectedArticleIds.length && (window as any)._paq && !store.currentPopupContent.length) {
        debounceFunction(() => {
          for (const articleId of store.currentIntersectedArticleIds) {
            const article = store.getArticleDataById(articleId);
            if (article) {
              (window as any)._paq.push(["setDocumentTitle", article.titel]);
              (window as any)._paq.push(["trackPageView"]);
            }
          }
        }, 5000);
      }
    }
  }, { root: contentScrollPane.value as HTMLElement, rootMargin: "-150px 0px 0px 0px" });

  const resizeObserver: ResizeObserver = new window.ResizeObserver(() => {
    calcGridHeight();
  });
  resizeObserver.observe(document.body);
  resizeObserver.observe(document.querySelector("#filter-search-results") as HTMLDivElement);
  if (filterCol.value) {
    resizeObserver.observe(filterCol.value);
  }

  calcGridHeight();

  if (filterCol.value) {
    filterCol.value.addEventListener("scroll", (e: Event) => {
      const elm = e.target as HTMLDivElement;
      if (!elm.classList.contains("on-scroll")) {
        elm.classList.add("on-scroll");
      }
    });
    filterCol.value.addEventListener("mouseleave", (e: Event) => {
      const elm = e.target as HTMLDivElement;
      elm.classList.remove("on-scroll");
    });
  }

  handleUrlParamOverlayTarget()
  handleSearchScrollToHighlight()
});

bindCMSLangSwitcherForDDC(store.getLangMatchingEntriesForCurrLang)

</script>

<template>
  <div>
    <div id="filter-search-results" class="filter-search-results" ref="filterSearchResult">
      <Search :hide-on-bookmark="bookmarkCurrArticle" />
      <SearchFilterInfo v-show="store.currentUsedFilters.length || store.searchPhrase" :hide-on-bookmark="bookmarkCurrArticle" />
    </div>
    <div class="app-outer" :class="{ collapsed: !showFilter }" ref="gridOuter">
      <div class="filter-footer-wrapper">
        <div class="base-column filter-column" ref="filterCol">
          <div v-if="bookmarkCurrArticle" class="fixed-container">
            <div class="flex-row filter-top-row bookmark-view-top-row">
              <div @click.stop="closeBookmarkView()" class="button margin green expand">
                {{ t("message.button-label-back") }}</div>
            </div>
            <Bookmarks :bookmarks="bookmarks" data-as-navigation="true" />
          </div>
          <div v-else class="fixed-container">
            <div class="flex-row filter-top-row">
              <div @click.stop="store.currentPopupContent.push({ __bookmarks__: true } as PopupContentData)" class="button margin green expand">
                {{ t("message.button-label-manage-bookmarklist") }}
              </div>
            </div>
            <FilterTree :key="`filter-tree-reset-${store.brainCount}`" />
          </div>
        </div>
        <div class="footer">
          <a :href="t('message.ddc-footer-imprint-link')" target="_blank" class="footer-link">{{ t("message.ddc-footer-imprint-text") }}</a><span class="sep"></span><a :href="t('message.ddc-footer-privacy_policy-link')" target="_blank" class="footer-link">{{ t("message.ddc-footer-privacy_policy-text") }}</a>
        </div>
      </div>
      <div class="base-column content-column" ref="contentScrollPane">
        <div class="filter-collapse-outer">
          <button @click.stop="showFilter ? showFilter = false : showFilter = true" :class="['filter-collapse icon-only green', { collapsed: !showFilter }]">
            <i class="icon icon-left_arrow_short"></i>
          </button>
        </div>
        <Teleport to="body">
          <Transition name="fade" mode="in-out">
            <FlashMessage v-if="store.flashMessage" v-show="store.flashMessage" :type="store.flashMessage.type">
              <div v-html="t('message.' + store.flashMessage.text)"></div>
            </FlashMessage>
          </Transition>
        </Teleport>
        <div class="print-logo"><i class="icon icon-logo"></i></div>
        <ArticlesOuter />
        <PopupModal v-if="currPopupContent" @close-popup="closePopup" :options="{ closebutton: true, modalclose: true, escclose: true }" ref="popupDomel">
          <template #header>
            <h1 v-if="currPopupContent.__bookmarks__" class="popover">{{ t("message.button-label-manage-bookmarklist") }}</h1>
            <BreadcrumbNavigation :data="store.currentPopupContent" />
          </template>
          <template #content>
            <div v-if="currPopupContent.__image__" class="image-wrapper">
              <img :src="`${HOSTNAME}${currPopupContent.__image__}` || ''" class="zoomed-image" />
            </div>
            <ArticlesOuter v-else-if="currPopupContent.__article__" :article-id="currPopupContent.__article__" :key="store.overlayBrainCount" />
            <Bookmarks v-else-if="currPopupContent.__bookmarks__" :bookmarks="bookmarks" />
          </template>
        </PopupModal>
        <ArticleScrollNavigator />
        <Teleport to="#ddc-print-icon-target">
          <PrintButton />
        </Teleport>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import "@/sass/icon_font_style";

.image-wrapper {
  text-align: center;

  .zoomed-image {
    max-height: calc(var(--popover-max-height) - 20px);
  }
}

.filter-search-results {
  background-color: var(--color-blaugruen);
}

.app-outer {
  display: grid;
  grid-template-columns: .3fr 1fr;
  grid-template-areas:
    "wrapper content";
  padding: 0;
  transition: margin-left .5s ease-in-out;


  .filter-footer-wrapper {
    display: grid;
    overflow: hidden;
    grid-area: wrapper;
    grid-template-columns: 1fr;
    grid-template-rows: auto 50px;

    .filter-column {
      padding: 20px 15px 20px 0;
      overflow-y: auto;
      overflow-x: hidden;
      scrollbar-width: thin;
      background-color: var(--color-white);
      box-shadow: var(--main-shadow);

      &.on-scroll::-webkit-scrollbar,
      &:hover::-webkit-scrollbar {
        width: 6px;
      }

      &::-webkit-scrollbar {
        width: 0;
        height: 6px;
      }

      &::-webkit-scrollbar-track {
        background-color: #ccc;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #999;
        border-radius: 4px;
      }

      .fixed-container {
        width: 22vw;

        .filter-top-row {
          gap: 1.25rem;
          align-items: flex-start;

          .button {
            margin-left: 15px;
            margin-right: 0
          }
        }
      }
    }

    .footer {
      display: flex;
      flex-direction: row;
      gap: 10px;
      justify-items: center;
      justify-content: center;
      align-items: center;
      background-color: var(--color-anthrazit);

      .footer-link,
      .sep {
        display: inline-block;
        color: var(--color-silver-sand);
      }

      .sep {
        height: 24px;
        border-right: 2px solid var(--color-blaugruen);
      }

      .footer-link {
        text-decoration: none;

        &:hover {
          color: var(--color-white);
        }
      }
    }
  }

  .content-column {
    position: relative;
    grid-area: content;
    // padding: 20px 20px 20px 35px;
    overflow-y: auto;
    scrollbar-width: thin;

    .filter-collapse-outer {
      position: fixed;
      z-index: 1000;

      .filter-collapse {
        position: fixed;
        font-size: 1.5rem;
        margin-left: 0;
        color: var(--color-white);
        transform: translateY(20px);

        .icon {
          color: var(--color-white);
          transition: transform .3s ease-in-out;
        }

        &.collapsed {
          .icon {
            transform: rotate(180deg);
          }
        }
      }

      &.collapsed {
        gap: 0;

        .filter-collapse {
          @extend .icon-right_arrow_short;
        }
      }
    }

    .print-logo {
      display: none;
      text-align: right;
      font-size: 70pt;
      margin: 0 0 -20pt;
    }

    &::-webkit-scrollbar {
      width: 8px;
      height: 8px;
    }

    &::-webkit-scrollbar-track {
      background-color: #ccc;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #999;
      border-radius: 4px;
    }
  }

  &.collapsed {
    margin-left: -30vw;

    .button,
    .filters-outer,
    .bookmark-list-outer {
      display: none;
    }
  }
}
</style>
