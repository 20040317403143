<script setup lang="ts">
import type { JsonArticleFaq } from '@/types';
import { onMounted, type PropType } from 'vue';
import { useStore } from "@/stores/main";
import { VueCollapsiblePanelGroup, VueCollapsiblePanel } from "@swoga/vue-collapsible-panel";

const props = defineProps({
	data: {
		type: Object as PropType<JsonArticleFaq[]>,
		required: true,
	}
});
const data = props.data;
const store = useStore();

// const lang: ComputedRef<string> = computed(() => {
// 	return store.lang !== "de" ? `_${store.lang}` : "";
// });

const getDataValue = (key: string, faq: JsonArticleFaq) => {
	const keyVal = faq[`${key}`];
	return keyVal;
};

onMounted(() => {
	const accRootElm = document.querySelectorAll(".vcpg") as NodeListOf<HTMLDivElement>;
	if (accRootElm) {
		accRootElm.forEach(elm => {
			elm.removeAttribute("style");
		});
	}
});
</script>

<template>
	<VueCollapsiblePanelGroup accordion>
		<VueCollapsiblePanel v-for="[idx, faq] in data.entries()" :key="faq.id" :expanded="!idx">
			<template #title>{{ getDataValue("frage", faq) }}</template>
			<template #content>{{ getDataValue("antwort", faq) }}</template>
		</VueCollapsiblePanel>
	</VueCollapsiblePanelGroup>
</template>

<style lang="scss">

</style>