<script setup lang="ts">
import { ref, watch, toRefs, type Ref, nextTick, type PropType } from 'vue';


const props = defineProps({
	placeholder: {
		type: String,
		default: "",
	},
	label: {
		type: String,
		default: "",
	},
	labelpos: {
		type: String,
		default: "top",
	},
	labelcolor: {
		type: String,
		default: "dark"
	},
	id: {
		type: String,
		required: true,
	},
	focus: {
		type: Boolean,
		default: false,
	},
	modelValue: {
		type: String,
		default: "",
	},
	validate: {
		type: Number,
		default: 0,
	},
	validationRegExp: {
		type: String,
		default: "",
	},
	errorMsg: {
		type: String,
		default: "",
	},
	externalError: {
		type: Object as PropType<any | null>,
		default: null
	}
});

const { placeholder, label, labelpos, labelcolor, id, focus, modelValue, validate, validationRegExp, errorMsg, externalError } = toRefs(props);
const emit = defineEmits(["update:modelValue", "fieldError"]);

const error = ref(false);
const errorMsgText = ref(errorMsg.value)

const element: Ref<HTMLInputElement | null> = ref(null);

const update = (e: Event) => {
	const val = (e.target as HTMLInputElement).value;
	if (validationRegExp.value) {
		const rex = new RegExp(validationRegExp.value);
		if (rex.test(val)) {
			error.value = false;
		}
	} else if (element.value && element.value.value === "") {
		error.value = false;
	}
	emit('update:modelValue', val);
	emit("fieldError", id.value, error.value);
}

const clearError = () => {
	error.value = false
}

const setFocus = () => {
	nextTick(() => {
		if (element.value) {
			element.value.focus()
		}
	})
}

watch(element, (elm) => {
	nextTick(() => {
		if (elm && focus.value) {
			elm.focus()
		}
	})
});

watch(validate, (valValue) => {
	if (valValue && validationRegExp.value) {
		const rex = new RegExp(validationRegExp.value);
		if (!rex.test(modelValue.value)) {
			error.value = true;
		} else {
			error.value = false;
		}
		emit("fieldError", id.value, error.value);
	}
});

watch(externalError, (eeObj) => {
	if (eeObj) {
		console.log("externalError");

		if (eeObj.id === id.value) {
			errorMsgText.value = eeObj.errorMsg
			error.value = true
		}
	} else {
		errorMsgText.value = ""
		error.value = false

	}
	emit("fieldError", id.value, error.value);
})

defineExpose({
	clearError,
	setFocus
})
</script>

<template>
	<div class="input-outer" :class="`input-outer-label-${labelpos}`">
		<label v-if="label" :for="id" :class="{ light: labelcolor === 'light' }">{{ label }}</label>
		<p v-if="error && errorMsgText" class="error-msg">{{ errorMsgText }}</p>
		<textarea v-if="$attrs.type && $attrs.type === 'textarea'" :placeholder="placeholder" :id="id" @input="update($event)" v-bind="$attrs" ref="element" :class="{ 'field-error': error }">{{ modelValue }}</textarea>
		<input v-else :placeholder="placeholder" :id="id" :type="$attrs.type as string || 'text'" :value="modelValue" @input="update($event)" v-bind="$attrs" ref="element" :class="{ 'field-error': error }">
	</div>
</template>

<style scoped lang="scss">
.input-outer {
	width: 100%;

	.light {
		color: var(--color-white);
	}

	.error-msg {
		font-weight: 600;
		color: #c93b3b;
		margin: 0;
	}

	.field-error {
		background-color: #f8e1e1;
	}
}
</style>