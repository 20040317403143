<script setup lang="ts">
import { useStore } from "@/stores/main";
import { HOSTNAME, fetchDownloadFile } from "@/utils/entrypoints";
import { toRefs } from "vue";
import { type ComputedRef, type PropType, type Ref, ref, watch, onMounted, onUpdated } from "vue";
import type { ElementTypeDef, JsonArticlePartParagraph, JsonDownloads, PopupContentData } from "@/types";
import { computed } from "vue";
import { getHighlightedText, getMarkdownParsedTextData } from "@/utils/utils";

const props = defineProps({
	data: {
		type: Object as PropType<JsonArticlePartParagraph>,
		required: true,
	},
	articleId: {
		type: String,
		required: true,
	},
	typeDefs: {
		type: Array<ElementTypeDef>,
		required: true,
	}
});

const store = useStore();
const { data, articleId } = toRefs(props);
const paragraph: Ref<HTMLElement | null> = ref(null);

const closePopup = (e?: Event) => {
	store.currentPopupContent = [];
};

const zoomImage = () => {
	store.currentPopupContent.push({ __image__: data.value.bild_fuer_text } as PopupContentData);
};

const closeAllPopups: ComputedRef<number> = computed(() => {
	return store.closeAllPopups;
});

const downloads: ComputedRef<JsonDownloads[] | null> = computed(() => {
	return store.downloadsData
})

// const lang: ComputedRef<string> = computed(() => {
// 	return store.lang !== "de" ? `_${store.lang}` : "";
// });

const searchPhrases: ComputedRef<string[]> = computed(() => {
	return store.getSearchPhrases;
});

const getDataValue = (key: string): string => {
	const text = data.value[`${key}`] as string || "";
	if (searchPhrases.value) {
		return getMarkdownParsedTextData(getHighlightedText(text, searchPhrases.value));
	}
	return text;
};

const handleMarkdownLinkClick = function (e: Event) {
	e.stopPropagation();
	e.preventDefault();
	const eTarget = e.target as HTMLLinkElement
	if (eTarget.dataset.download && downloads.value) {
		for (const dl of downloads.value) {
			if (dl.identifizierer === eTarget.dataset.downloadTarget) {
				fetchDownloadFile(dl.id)
				break;
			}
		}
	} else {
		store.currentPopupContent.push({ __article__: eTarget.dataset.overlayTarget } as PopupContentData);
		store.overlayBrainCount++
	}
};

const bindMarkdownLinksForAction = () => {
	if (paragraph.value) {
		const para = paragraph.value;
		const prevNodes: HTMLLinkElement[] = [];
		try {
			const linkElms = para.querySelectorAll(`a[data-overlay-target], a[data-download-target]`);
			linkElms.forEach(node => {
				const n = node as HTMLLinkElement;
				if (!prevNodes.includes(n) && !n.dataset.hasParaLinkListener) {
					n.addEventListener("click", handleMarkdownLinkClick);
					if (!n.dataset.hasParaLinkListener) {
						n.dataset.hasParaLinkListener = "true";
					}
				}
				prevNodes.push(n);
			});
		} catch (error) {
			console.error(error);
		}
	}
};

const hasImage = (): boolean => {
	if (data.value.bild_fuer_text) {
		return true;
	}
	return false;
}

const isSVG = (): boolean => {
	if (data.value.bild_fuer_text_type && data.value.bild_fuer_text_type === "image/svg+xml") {
		return true;
	}
	return false;
};

const getImageAlign = (): string => {
	return data.value.bildausrichtung || "left_umfliessend";
}

watch(closeAllPopups, (clap: number) => {
	if (clap) {
		closePopup();
	}
});

onMounted(() => {
	bindMarkdownLinksForAction();
});

onUpdated(() => {
	bindMarkdownLinksForAction();
});

</script>

<template>
	<div class="paragraph-outer" ref="paragraph">
		<h2 v-if="getDataValue('ueberschrift')" v-html="getDataValue('ueberschrift')"></h2>
		<div v-if="hasImage()" class="param-image-outer" :class="`param-image-outer_${getImageAlign()}`">
			<div v-if="getDataValue('bilduntertext')" class="ksg-image" :class="`ksg-image-${getImageAlign()}`">
				<div :class="['ksg-image-outer', { 'svg-image': isSVG() }]">
					<span class="ksg-image">
						<img v-if="isSVG()" :src="`${HOSTNAME}${data.bild_fuer_text}` || ''" @click.stop.prevent="zoomImage()" class="image-zoom" />
						<img v-else :src="`${HOSTNAME}${data.bild_fuer_text}` || ''" />
					</span>
					<div class="clear-float"></div>
				</div>
				<p class="subtext" v-html="getDataValue('bilduntertext')"></p>
			</div>
			<div v-else :class="['ksg-image-outer', { 'svg-image': isSVG() }]">
				<span class="ksg-image" :class="`ksg-image-${getImageAlign()}`">
					<img v-if="isSVG()" :src="`${HOSTNAME}${data.bild_fuer_text}` || ''" @click.stop.prevent="zoomImage()" class="image-zoom" />
					<img v-else :src="`${HOSTNAME}${data.bild_fuer_text}` || ''" />
				</span>
				<div class="clear-float"></div>
			</div>
			<p v-if="getDataValue('text')" v-html="getDataValue('text')"></p>
		</div>
		<p v-if="!hasImage() && getDataValue('text')" v-html="getDataValue('text')"></p>
	</div>
</template>

<style scoped lang="scss">
.paragraph-outer {
	.clear-float {
		clear: both;
	}

	.param-image-outer {

		&_mitte_unterhalb,
		&_mitte_oberhalb {
			display: flex;
			flex-flow: column;
			flex-direction: column-reverse;
		}

		&_links_freibleibend,
		&_rechts_freibleibend {
			display: flex;
			flex-flow: row;
			flex-direction: row;
			justify-content: flex-start;
			align-items: flex-start;
			gap: 15px;

			.ksg-image-outer {
				flex-shrink: 2;
			}

			.ksg-image {
				img {
					width: 100%;
					vertical-align: inherit;
				}
			}

			p {
				width: 100%;
			}
		}

		&_rechts_freibleibend {
			flex-direction: row-reverse;
			justify-content: flex-end;
		}

		.ksg-image-outer {
			.image-zoom {
				cursor: pointer;
			}

			div.ksg-image {
				display: flex;
				flex-flow: column;
				width: min-content;

				.subtext {
					font-size: var(--tiny-font-size);
				}
			}

			.ksg-image {
				position: relative;

				img {
					width: 100%;
					vertical-align: inherit;
				}

				&-links_umfliessend {
					float: left;
					margin: 0 15px 10px 0;
				}

				&-rechts_umfliessend {
					float: right;
					margin: 0 0 10px 15px;
				}

				&-mitte_oberhalb {
					display: block;
					margin: 0 auto 10px;
				}

				&-mitte_unterhalb {
					display: block;
					margin: -10px auto 30px;
				}
			}

		}

		p {
			&::after {
				content: "";
				display: block;
				clear: both;
			}
		}
	}
}
</style>

<style  lang="scss">
.paragraph-outer ul {
	padding-left: 2.5rem;
	margin: 15px 0 30px;

	li {
		position: relative;
		list-style: none;

		&::before {
			content: '';
			transform: rotate(45deg);
			position: absolute;
			top: 2px;
			right: 100%;
			width: .5rem;
			height: 1rem;
			border: solid 3px var(--color-blaugruen);
			border-top: none;
			border-left: none;
			margin-right: 1.75rem;
		}
	}

	li+li {
		margin: .875rem 0 0;
	}
}
</style>