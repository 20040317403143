<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import type { ElementTypeDef, JsonArticlePart, JsonArticlePartParagraph, JsonArticlePartSectionView, JsonArticlePartTable, PartTypes } from "@/types";
import { toRefs } from "vue";
import { computed, type ComputedRef, type PropType } from "vue";
import { useStore } from "@/stores/main";
import { makeidentifier, jumpToArticleAnchor, getHighlightedText } from "@/utils/utils";
import ArticlePartParagraph from "@/components/ArticlePartParagraph.vue";
import ArticlePartTable from "@/components/ArticlePartTable.vue";
import ArticlePartSectionView from "@/components/ArticlePartSectionView.vue";

const props = defineProps({
	part: {
		type: Object as PropType<JsonArticlePart>,
		required: true,
	},
	inOverlay: {
		type: Boolean,
		default: false,
	}
});

const emit = defineEmits(["foundAnchor"]);
const store = useStore();
const dynComps: any = {
	ArticlePartParagraph,
	ArticlePartTable,
	ArticlePartSectionView,
};
const { t, n } = useI18n({ useScope: "global" });
const { part, inOverlay } = toRefs(props);
const firstColTypeDefs: ElementTypeDef[] = [
	{ key: "ueberschrift", component: "ArticlePartParagraph" },
	{ key: "text", component: "ArticlePartParagraph" },
	{ key: "bild_fuer_text", component: "ArticlePartParagraph" },
	{ key: "bild_fuer_text_type", component: "ArticlePartParagraph" },
	{ key: "bildausrichtung", component: "ArticlePartParagraph" },
	{ key: "bilduntertext", component: "ArticlePartParagraph" },
	{ key: "platinenschnittbild", component: "ArticlePartSectionView" },
	{ key: "tabelle", component: "ArticlePartTable" },
	{ key: "tabelle_2", component: "ArticlePartTable" },
	{ key: "tabelle_3", component: "ArticlePartTable" },
	{ key: "tabelle_4", component: "ArticlePartTable" },
];

const secondColTypeDefs: ElementTypeDef[] = [
	{ key: "ueberschrift_zweite_spalte", component: "ArticlePartParagraph" },
	{ key: "text_zweite_spalte", component: "ArticlePartParagraph" },
	{ key: "bild_fuer_text_zweite_spalte", component: "ArticlePartParagraph" },
	{ key: "bild_fuer_text_zweite_spalte_type", component: "ArticlePartParagraph" },
	{ key: "bildausrichtung_zweite_spalte", component: "ArticlePartParagraph" },
	{ key: "bilduntertext_zweite_spalte", component: "ArticlePartParagraph" },
	{ key: "platinenschnittbild_zweite_spalte", component: "ArticlePartSectionView" },
	{ key: "tabelle_zweite_spalte", component: "ArticlePartTable" },
	{ key: "tabelle_2_zweite_spalte", component: "ArticlePartTable" },
	{ key: "tabelle_3_zweite_spalte", component: "ArticlePartTable" },
	{ key: "tabelle_4_zweite_spalte", component: "ArticlePartTable" },
];

const thirdColTypeDefs: ElementTypeDef[] = [
	{ key: "ueberschrift_dritte_spalte", component: "ArticlePartParagraph" },
	{ key: "text_dritte_spalte", component: "ArticlePartParagraph" },
	{ key: "bild_fuer_text_dritte_spalte", component: "ArticlePartParagraph" },
	{ key: "bild_fuer_text_dritte_spalte_type", component: "ArticlePartParagraph" },
	{ key: "bildausrichtung_dritte_spalte", component: "ArticlePartParagraph" },
	{ key: "bilduntertext_dritte_spalte", component: "ArticlePartParagraph" },
	{ key: "platinenschnittbild_dritte_spalte", component: "ArticlePartSectionView" },
	{ key: "tabelle_dritte_spalte", component: "ArticlePartTable" },
];

const fourthColTypeDefs: ElementTypeDef[] = [
	{ key: "ueberschrift_vierte_spalte", component: "ArticlePartParagraph" },
	{ key: "text_vierte_spalte", component: "ArticlePartParagraph" },
	{ key: "bild_fuer_text_vierte_spalte", component: "ArticlePartParagraph" },
	{ key: "bild_fuer_text_vierte_spalte_type", component: "ArticlePartParagraph" },
	{ key: "bildausrichtung_vierte_spalte", component: "ArticlePartParagraph" },
	{ key: "bilduntertext_vierte_spalte", component: "ArticlePartParagraph" },
	{ key: "platinenschnittbild_vierte_spalte", component: "ArticlePartSectionView" },
	{ key: "tabelle_vierte_spalte", component: "ArticlePartTable" },
];

const searchPhrases: ComputedRef<string[]> = computed(() => {
	return store.getSearchPhrases;
});

const getDataValue = (key: string): string => {
	const text = part.value[`${key}`] as string || "";
	if (searchPhrases.value) {
		return getHighlightedText(text, searchPhrases.value);
	}
	return text;
};

const collectPartData = (typeDefs: ElementTypeDef[]): PartTypes => {
	const p = part.value;
	const partTypes = {} as PartTypes;
	for (const tdef of typeDefs) {
		const key = tdef.key;
		const comp = tdef.component;
		if (!Object.keys(partTypes).includes(comp)) {
			partTypes[comp] = {} as JsonArticlePartParagraph | JsonArticlePartSectionView | JsonArticlePartTable;
		}
		if (partTypes && partTypes[comp]) {
			let cleanedKey = key.replace(/_(zweite|dritte|vierte)_spalte/, "");
			partTypes[comp][cleanedKey] = p[key];
		}
	}

	return partTypes;
};

const handleAnchor = () => {
	if (part.value.bezeichner_sprungziel) {
		let identifier = makeidentifier(part.value.bezeichner_sprungziel, undefined, `${part.value.artikelzuordnung}_`);
		if (inOverlay.value) {
			identifier = `in_overlay_${identifier}`;
		}
		emit("foundAnchor", part.value.bezeichner_sprungziel, identifier);
		return identifier;
	}
	return "";
};
</script>

<template>
	<div class="part-outer" :key="store.brainCount">
		<div v-if="part.bezeichner_sprungziel" class="part-anchor-outer">
			<h2 :id="handleAnchor()" class="ksg-anchor" v-html="getDataValue('bezeichner_sprungziel')"></h2>
			<a v-if="props.inOverlay" href="#" @click.stop.prevent="jumpToArticleAnchor(`in_overlay_${part.artikelzuordnung}`, '', props.inOverlay)" class="article-top">{{ t("message.button-back-to-top-of-page") }}<span class="article-up-icon icon icon-right_arrow_big"></span></a>
			<a v-else href="#" @click.stop.prevent="jumpToArticleAnchor(`__${part.artikelzuordnung}`)" class="article-top">{{ t("message.button-back-to-top-of-page") }}<span class="article-up-icon icon icon-right_arrow_big"></span></a>
		</div>
		<article v-if="part.mehrspaltigkeit && part.mehrspaltigkeit.length === 6" class="content content-grid" :class="`content-grid${part.mehrspaltigkeit}`">
			<div class="first">
				<component v-for="data, key in collectPartData(firstColTypeDefs)" :is="dynComps[key]" :data="data" :type-defs="firstColTypeDefs" :article-id="part.artikelzuordnung" />
			</div>
			<div class="second">
				<component v-for="data, key in collectPartData(secondColTypeDefs)" :is="dynComps[key]" :data="data" :type-defs="secondColTypeDefs" :article-id="part.artikelzuordnung" />
			</div>
		</article>
		<article v-else-if="part.mehrspaltigkeit && part.mehrspaltigkeit.length === 9" class="content content-grid" :class="`content-grid${part.mehrspaltigkeit}`">
			<div class="first">
				<component v-for="data, key in collectPartData(firstColTypeDefs)" :is="dynComps[key]" :data="data" :type-defs="firstColTypeDefs" :article-id="part.artikelzuordnung" />
			</div>
			<div class="second">
				<component v-for="data, key in collectPartData(secondColTypeDefs)" :is="dynComps[key]" :data="data" :type-defs="secondColTypeDefs" :article-id="part.artikelzuordnung" />
			</div>
			<div class="third">
				<component v-for="data, key in collectPartData(thirdColTypeDefs)" :is="dynComps[key]" :data="data" :type-defs="thirdColTypeDefs" :article-id="part.artikelzuordnung" />
			</div>
		</article>
		<article v-else-if="part.mehrspaltigkeit && part.mehrspaltigkeit.length === 12" class="content content-grid" :class="`content-grid${part.mehrspaltigkeit}`">
			<div class="first">
				<component v-for="data, key in collectPartData(firstColTypeDefs)" :is="dynComps[key]" :data="data" :type-defs="firstColTypeDefs" :article-id="part.artikelzuordnung" />
			</div>
			<div class="second">
				<component v-for="data, key in collectPartData(secondColTypeDefs)" :is="dynComps[key]" :data="data" :type-defs="secondColTypeDefs" :article-id="part.artikelzuordnung" />
			</div>
			<div class="third">
				<component v-for="data, key in collectPartData(thirdColTypeDefs)" :is="dynComps[key]" :data="data" :type-defs="thirdColTypeDefs" :article-id="part.artikelzuordnung" />
			</div>
			<div class="fourth">
				<component v-for="data, key in collectPartData(fourthColTypeDefs)" :is="dynComps[key]" :data="data" :type-defs="fourthColTypeDefs" :article-id="part.artikelzuordnung" />
			</div>
		</article>
		<article v-else class="content">
			<component v-for="data, key in collectPartData(firstColTypeDefs)" :is="dynComps[key]" :data="data" :type-defs="firstColTypeDefs" :article-id="part.artikelzuordnung" />
		</article>
	</div>
</template>

<style scoped lang="scss">
.part-outer {
	.part-anchor-outer {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		margin: var(--header-2-anchor-margin);
		border-bottom: 2px solid var(--color-iron);

		.article-top {
			display: block;

			.article-up-icon {
				display: inline-block;
				rotate: -90deg;
				font-size: 1.4rem;
				margin-left: 8px;
				transform: translateX(-2px);
			}
		}
	}

	.content {
		&.content-grid {
			display: grid;
			align-items: start;
			grid-template-areas: "first second";
			margin: 0 0 0 -30px;

			&_50_50 {
				grid-template-columns: 5fr 5fr;
			}

			&_60_40 {
				grid-template-columns: 6fr 4fr;
			}

			&_66_33 {
				grid-template-columns: 6.66fr 3.33fr;
			}

			&_70_30 {
				grid-template-columns: 7fr 3fr;
			}

			&_75_25 {
				grid-template-columns: 7.5fr 2.5fr;
			}

			&_25_75 {
				grid-template-columns: 2.5fr 7.5fr;
			}

			&_30_70 {
				grid-template-columns: 3fr 7fr;
			}

			&_40_60 {
				grid-template-columns: 4fr 6fr;
			}

			&_33_66 {
				grid-template-columns: 3.33fr 6.66fr;
			}

			&_33_33_33 {
				grid-template-areas: "first second third";
				grid-template-columns: 3.33fr 3.33fr 3.33fr;
				// gap: 20px;
			}

			&_25_25_25_25 {
				grid-template-areas: "first second third fourth";
				grid-template-columns: 2.5fr 2.5fr 2.5fr 2.5fr;
			}
		}

		.first {
			grid-area: first;
			padding: 0 0 0 30px;
		}

		.second {
			grid-area: second;
			padding: 0 0 0 30px;
		}

		.third {
			grid-area: third;
			padding: 0 0 0 30px;
		}

		.fourth {
			grid-area: fourth;
			padding: 0 0 0 30px;
		}
	}
}
</style>
