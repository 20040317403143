<script setup lang="ts">
import { useStore } from "@/stores/main";
import FilterItem from "@/components/FilterItem.vue";
import { computed, provide } from "vue";
import type { ITree } from "@/types";
const store = useStore();

provide("iconAssingmentForFilterGroups", {
	"allgemeines": ["icon-info", 1.6], // Allgemeines
	"anwendungen_loesungen": ["icon-industry", 1.6], // Anwendung/Lösungen
	"leiterplatten_technologien": ["icon-gear", 1.6], // Leiterplatten-Technologien
	"basismaterialien": ["icon-basic_materials", 1.6], // Basismaterialien
	"nutzengestaltung_formate": ["icon-pattern", 1.6], // Nutzengestaltung/Panelformate
	"kennzeichnungen": ["icon-barcode", 1.6], // Kennzeichnungen
	"mechanische_und_laserprozesse": ["icon-mechanical_laser", 1.6], // Mechanische- und Laserprozesse
	"design_rules_loecher_leiterbild": ["icon-sketch", 1.6], // Design Rules Löcher/Leiterbild
	"masken_zusatzdrucke": ["icon-masks", 1.6], // Masken/Zusatzdrucke
	"loet_und_bondoberflaechen": ["icon-solder_bond", 1.6], // Löt- und Bondoberflächen
	"via_verschlusstechnologien": ["icon-locking_tech", 1.6], // Via-Verschlusstechnologien
	"sondertechnologien": ["icon-special", 1.6], // Sondertechnologien
	"impedanz": ["icon-impetanz", 1.6], // Impedanz
	"testverfahren_qualitaetskontrolle": ["icon-gear", 1.6], // Testverfahren/Qualitätskontrolle
	"design_loesungen": ["icon-design_rules", 1.6], // Design-Lösungen
	"serviceleistungen_nuetzliche_tools": ["icon-screwdriver", 1.6], // Serviceleistungen/Nützliche Tools
	"verpackung_versand_lagerung": ["icon-packaging_delivery", 1.6], // Verpackung/Versand/Lagerung
	"ddc_placeholder": ["icon-compass", 1.6], // Platzhalter, wenn ein neuer Filter durch KSG angelegt wird
});

const filterData = computed(() => {
	return store.getFilterData as ITree | null;
});

const getKey = (id: string) => {
	return id;
};
</script>

<template>
	<div class="filters-outer" v-if="filterData" :key="store.brainCount">
		<FilterItem v-for="(node, idx) in filterData.nodes" :node="node" :idx="idx" :key="node.id" />
	</div>
</template>

<style scoped lang="scss">
loet_und_bondoberflaechen .filters-outer {
	display: flex;
	flex-direction: column;
	justify-content: start;
	gap: 8px;
	padding: 0;
	user-select: none;
	margin: 25px 0 20px;
}
</style>
