import { createApp } from 'vue';
import { createPinia, type Pinia } from 'pinia';
import { createI18n } from 'vue-i18n';
import { useStore } from "./stores/main";
import VuePapaParse from 'vue-papa-parse';
import VueCollapsiblePanel from '@swoga/vue-collapsible-panel';
import Popper from "vue3-popper";
import App from './App.vue';
import EntryTabs from "./EntryTabs.vue";
import PortalContent from "@/components/PortalContent.vue";
import SSOLoginShell from "@/components/SSOLoginShell.vue";
import LoginUserMenu from "@/components/LoginUserMenu.vue";
import ResizeObserver from 'resize-observer-vue';

import '@swoga/vue-collapsible-panel/dist/vue-collapsible-panel.css';
import './assets/main.scss';

// Importing required i18n messages.
import de from "@/i18n/de.json";
import en from "@/i18n/en.json";


let piniaStore: Pinia | null = null;

export const init = (tabsTarget: HTMLElement, appTarget: HTMLElement, entryId: string, lang?: string, searchTarget?: HTMLElement, sessionRestore = false) => {
	const i18n = createI18n({
		locale: lang || "de",
		legacy: false,
		messages: {
			de,
			en,
		}
	});
	if (!piniaStore) {
		piniaStore = createPinia();
	}
	const tabs = createApp(EntryTabs);
	tabs.use(i18n).use(piniaStore);
	const store = useStore();
	if (entryId) {
		store.entryId = entryId;
	}
	store.sessionRestore = sessionRestore;
	if (lang) {
		store.lang = lang;
	}
	if (searchTarget) {
		store.searchDomTarget = searchTarget;
	}
	tabs.mount(tabsTarget);

	const app = createApp(App);
	app.use(piniaStore).use(VuePapaParse).use(VueCollapsiblePanel).use(i18n).use(ResizeObserver);
	app.component("Popper", Popper);
	app.mount(appTarget);
};

export const initPortalContent = (target: HTMLElement, lang = "de") => {
	const i18n = createI18n({
		locale: lang || "de",
		legacy: false,
		messages: {
			de,
			en,
		}
	});

	if (!piniaStore) {
		piniaStore = createPinia();
	}
	const portalContent = createApp(PortalContent);
	portalContent.use(piniaStore).use(i18n);
	const store = useStore();
	if (lang) {
		store.lang = lang;
	}
	portalContent.mount(target);
};

export const initSSOLogin = (target: HTMLElement, iFrameSrc = "", lang = "de") => {
	const i18n = createI18n({
		locale: lang || "de",
		legacy: false,
		messages: {
			de,
			en,
		}
	});
	if (!piniaStore) {
		piniaStore = createPinia();
	}
	const login = createApp(SSOLoginShell);
	login.use(piniaStore).use(i18n);
	const store = useStore();
	store.ssoLoginUrl = iFrameSrc;
	if (lang) {
		store.lang = lang;
	}
	login.mount(target);
};

export const initLoginUserMenu = (target: HTMLElement, lang = "de") => {
	const i18n = createI18n({
		locale: lang || "de",
		legacy: false,
		messages: {
			de,
			en,
		}
	});
	if (!piniaStore) {
		piniaStore = createPinia();
	}
	const loginUserMenu = createApp(LoginUserMenu);
	loginUserMenu.use(piniaStore).use(i18n);
	loginUserMenu.component("Popper", Popper);
	const store = useStore();
	if (lang) {
		store.lang = lang;
	}
	loginUserMenu.mount(target);
};

