<script setup lang="ts">
import { useStore } from "@/stores/main";
import type { ITreeNode, JsonArticle } from "@/types";
import { toRefs, nextTick, type PropType } from "vue";
import { jumpToArticleAnchor } from "@/utils/utils";

const props = defineProps({
	node: {
		type: Object as PropType<ITreeNode>,
		required: true,
	},
	checkedStatus: {
		type: Number,
		required: true,
	},
	tooltip: {
		type: String,
		default: "",
	},
	small: {
		type: Boolean,
	}
});

const store = useStore();
const { node, checkedStatus, tooltip, small } = toRefs(props);

const handleClick = async (e: Event) => {
	store.updateUsedFilters(node.value);
	const n = node.value;
	if (n.checkerstate === 1 || n.checkerstate === -1) {
		let article: JsonArticle | null;
		if (n.checkerstate === 1 && n.children.length) {
			for (const child of n.children) {
				if (child.checkerstate === 1) {
					article = store.getArticleDataByFilterIdentifier(child.id);
					break;
				}
			}
		} else {
			article = store.getArticleDataByFilterIdentifier(n.id);
		}
		await nextTick(() => {
			if (article) {
				jumpToArticleAnchor(`__${article.id}`)
			}
		});
	}
	// store.updateUsedFilters(node.value);
}
</script>

<template>
	<div :title="tooltip" @click.stop.prevent="handleClick" :class="['checker', { 'checked-child': node.checkerstate === -1, checked: node.checkerstate === 1, 'small': small }]"></div>
</template>

<style scoped lang="scss">
@import "@/sass/icon_font_style";

.checker {
	flex-shrink: 0;
	width: 16px;
	height: 16px;
	left: 0px;
	top: 0px;
	background: var(--color-iron);
	border: 1px solid var(--color-nevada);
	@extend .icon;

	&.checked,
	&.checked-child {
		@extend .icon-checkbox_hook;

		&-child {
			@extend .icon-checkbox_minus;
		}

		&::before {
			display: inline-block;
			font-size: 12px;
			transform: translate(0px, -2px);
			font-weight: 600;
		}
	}

	&.small {
		width: 12px;
		height: 12px;
	}
}
</style>
