{
	"message": {
		"button-label-back": "Zurück",
		"button-label-print": "Drucken",
		"button-label-manage-bookmarklist": "Meine Merklisten",
		"hint-no-bookmarks": "Keine Einträge vorhanden",
		"remove-entry": "Eintrag entfernen",
		"search-field-placeholder": "Suchbegriff",
		"search-result-title": "Suchergebnis Ihrer Suchbegriffe",
		"search-to-much-results-flash-message-text": "Zu viel des Guten!<br/>Wir haben mehr als 30 Ergebnisse zur Ihrer aktuellen Suchanfrage gefunden.<br/>Bitte grenzen Sie Ihre Anfrage weiter ein, oder stellen Sie auf »Exakte Suche« um.",
		"search-field-exact-label": "Exakte Suche",
		"search-field-exact-label-tooltip": "Das Suchergebnis wird zielgerichteter, da der Suchalgorithmus für mehrere Wörter im Suchfeld von standardmäßig »oder« auf »und« umgestellt wird!",
		"button-label-delete-all": "Alle löschen",
		"filter-info-title": "Gesetzte Filter",
		"found-filter-info-title": "gefundene Filter",
		"found-unfiltered-info-title": "nicht gesetzte Filter",
		"hint-no-articles": "Leider konnten keine Artikel gefunden werden",
		"hint-no-overlay-article": "Leider konnten kein Artikel gefunden werden! Bitte prüfen Sie den Identifizierer",
		"form-select-first-entry-label-anchor-navigation": "Wählen Sie ein Sprungziel",
		"button-label-faqs": "FAQs",
		"button-label-set-bookmark": "Merken",
		"hint-set-bookmark-no-bookmark-list": "Sie haben noch keine Merkliste, bitte legen Sie eine an. Vergeben Sie dazu einen sprechenden Bezeichner",
		"hint-new-bookmarklist": "Vergeben Sie einen sprechenden Bezeichner für Ihre neue Merkliste",
		"form-input-placeholder-bookmark-title": "Bezeichnung",
		"button-label-cancel": "Abbrechen",
		"button-label-create-bookmarklist": "Merkliste anlegen",
		"form-textarea-title-bookmark-notice": "Notizen",
		"button-label-add-bookmark": "Jetzt merken",
		"button-label-article-show-complete-table": "Tabelle komplett anzeigen",
		"button-back-to-top-of-page": "Artikelanfang",
		"overlay-header-title-overlay-article": "Zusatzinfo",
		"design-to-cost-regex": ".*standard.*design\\sto\\scost.*",
		"cash-info-regex": ".*advanced.*",
		"performance-message-header": "Neues Spiel, neues Glück!",
		"performance-message-text": "Aktuell sind keine Filter gesetzt. Wählen Sie links aus unseren 17 informativen Kapiteln den passenden Inhalt für die PCB-Fragestellung, die Ihnen aktuell unter den Nägeln brennt oder nutzen Sie unsere Suche.",
		"performance-message-text2": "Alternativ können Sie auch über <a href=\"/Leiterplattentechnologien.htm\">Leiterplattentechnologien <i class=\"icon icon-right_arrow\"></i></a> oder <a href=\"/Anwendung--amp--Loesungen.htm\">Anwendungen & Lösungen <i class=\"icon icon-right_arrow\"></i></a> mit einem von uns gesetzten Filterset in den Design Compass einsteigen.",
		"sso-login-shell-no-frame-support-text": "Der verwendete Browser, unterstützt leider keine iFrames.",
		"ddc-footer-imprint-text": "Impressum",
		"ddc-footer-imprint-link": "https://www.ksg-pcb.com/impressum",
		"ddc-footer-privacy_policy-text": "Datenschutz",
		"ddc-footer-privacy_policy-link": "https://www.ksg-pcb.com/datenschutz",
		"flash-msg-to-many-search-results": "Zu viel des Guten!<br/>Wir haben mehr als 30 Ergebnisse zur Ihrer aktuellen Suchanfrage gefunden.<br/>Bitte grenzen Sie Ihre Anfrage weiter ein.",
		"portalpage-salutation-headline-snippet": "Hallo",
		"portalpage-welcome-headline": "Herzlich willkommen im Digital Design Compass der KSG Group!",
		"portalpage-welcome-paragraph1": "Unsere smarte Plattform unterstützt Sie bei der schnellen und sicheren PCB-Entwicklung.",
		"portalpage-welcome-paragraph2": "Um den Inhalt unserer Plattform optimal auf Ihre Anforderungen und aktuellen Projekte zuzuschneiden,<br />bieten wir Ihnen verschiedene Einstiegsmöglichkeiten.",
		"portalpage-entry-pcb-technology-label": "Leiterplattentechnologien",
		"portalpage-entry-pcb-technology-link": "/Leiterplattentechnologien.htm",
		"portalpage-entry-solutions-label": "Anwendungen & Lösungen",
		"portalpage-entry-solutions-link": "/Anwendung--amp--Loesungen.htm",
		"portalpage-entry-individual-label": "Individuell und ohne Filter",
		"portalpage-entry-individual-link": "/Design-Compass.htm?entry=6463b665b4398df7e0ab8ef0",
		"portalpage-entry-last-session-label": "Letzte Sitzung wiederherstellen",
		"portalpage-entry-last-session-link": "/Design-Compass.htm?entry={session_entry_id}&session_restore=1",
		"portalpage-news-panel-header": "News und Updates",
		"portalpage-hackl-hacks-header": "Die \"Hans Hackl Hacks\"",
		"portalpage-hackl-para-first": "In dieser Serie eröffnet Johann Hackl, auch bekannt als Hans Hackl, eine Vielzahl von Tipps und Tricks rund um den Digital Design Compass.",
		"portalpage-hackl-para-second": "Hans wird Ihnen in diesem Video alle Funktionen und Möglichkeiten des innovativen Tools vorstellen. Von grundlegenden Anwendungen bis hin zu fortgeschrittenen Techniken zeigt er Ihnen, wie Sie das Beste aus dem Digital Design Compass herausholen.",
		"portalpage-hackl-hacks-video-src": "https://www.youtube-nocookie.com/embed/_HeD9Wdl_k4?si=usawVHKnaX9k-nh3",
		"portalpage-newletter-header": "Hungrig auf Neuigkeiten?",
		"portalpage-newletter-paragraph": "Holen Sie sich die neusten Informationen rund um den Leiterplattenmarkt.",
		"portalpage-newletter-button-label": "Jetzt Newsletter<br />abonnieren",
		"portalpage-newletter-link": "https://www.ksg-pcb.com/newsletter",
		"loginpage-tab-login-label": "Login",
		"loginpage-tab-register-label": "Registrieren",
		"loginpage-register-hint-paragraph-text-1": "Sie haben noch keinen Account für den Digital Design Compass?",
		"loginpage-register-hint-paragraph-text-2": "Hier registrieren",
		"login-user-menu-logout-label": "Abmelden",
		"login-user-menu-label": "Benutzer",
		"login-user-menu-user-data-link-label": "Meine Daten",
		"login-user-menu-testmode-off-link-label": "Testmodus einschalten",
		"login-user-menu-testmode-on-link-label": "Testmodus ausschalten",
		"login-user-menu-user-account-link-label": "Zugangsdaten ändern",
		"register-base-form-header": "Account erstellen",
		"register-base-form-error": "Füllen Sie bitte alle Felder aus und überprüfen Sie die E-Mail.",
		"register-base-form-disclaimer": "Ihre E-Mail-Adresse nutzen wir auch, um Ihnen Informationen zu neuen Angeboten und technischen Hinweisen zukommen zu lassen. Sie können dem jederzeit unter <a href=\"mailto:datenschutz{'@'}ksg-pcb.com\" target=\"_blank\">datenschutz{'@'}ksg-pcb.com</a> kostenfrei widersprechen. Es gilt unsere <a href=\"https://www.ksg-pcb.com/datenschutz\" target=\"_blank\">Datenschutzerklärung</a>.",
		"register-base-form-data-success-msg-header": "Vielen Dank!",
		"register-base-form-data-success-msg-paragraph-1": "Ihre Registrierung für den Digital Design Compass ist fast<br /> abgeschlossen. Sie erhalten nach Prüfung Ihrer Angaben<br /> eine E-Mail mit Ihren Zugangsdaten.",
		"register-base-form-data-success-msg-paragraph-2": "Bitte prüfen Sie auch Ihren Spam-Ordner.",
		"register-base-form-user-exists-msg-header": "Benutzer bereits registriert!",
		"register-base-form-user-exists-msg-paragraph-1": "Der Benutzername <span class=\"email\">{user_already_exists}</span> ist für die Nutzung des Digital Design Compass bereits registriert.",
		"register-base-form-user-exists-msg-paragraph-2": "Sollten Sie Ihr Passwort vergessen haben, wechseln Sie bitte zum Login und klicken auf <i>Passwort vergessen</i>, um ein neues Passwort zu vergeben.",
		"register-base-form-submit-button-label": "Registrieren",
		"register-extended-form-header": "Registrierung",
		"register-extended-form-paragraph": "Geben Sie uns ergänzende Informationen zu Ihrer Registrierung!",
		"register-extended-form-error": "Füllen Sie bitte die benötigten Felder aus.",
		"register-extended-form-server-error": "Beim Übermitteln der Daten ist ein Fehler aufgetreten. Bitte wenden Sie sich an den Support!",
		"register-all-personal-data-form-header": "Persönliche Daten",
		"register-all-extended-data-form-header": "Unternehmensdaten",
		"account-data-form-header": "Zugangsdaten ändern",
		"account-form-error": "Bitte überprüfen Sie Ihre eingegebene Daten.",
		"account-change-process-error": "Die Daten konnten nicht geändert werden, da folgende Fehler aufgetreten sind:",
		"account-change-no-changes-msg": "Es wurden keine Änderungen an Benutzername oder Passwort vorgenommen!",
		"account-change-success-header": "Ihre Zugangsdaten wurden erfolgreich aktualisiert!",
		"account-change-success-msg": "Sie werden in Kürze aus Sicherheitsgründen automatisch abgemeldet! Bitte loggen Sie sich mit den neuen Zugangsdaten wieder ein.",
		"form-prompt-salutation": "Anrede",
		"form-prompt-organisation": "Unternehmen",
		"form-prompt-firstname": "Vorname",
		"form-prompt-surname": "Nachname",
		"form-prompt-role": "Rolle",
		"form-prompt-language": "Sprache",
		"form-prompt-email": "E-Mail",
		"form-prompt-industry": "Branche",
		"form-prompt-department": "Abteilung",
		"form-prompt-position": "Funktion",
		"form-prompt-phone": "Telefonnummer",
		"form-prompt-street": "Straße",
		"form-prompt-zip-code": "Postleitzahl",
		"form-prompt-location": "Ort",
		"form-prompt-country": "Land",
		"form-prompt-user": "Benutzername",
		"form-prompt-user-new": "Neuer Benutzername",
		"form-prompt-user-new-placeholder": "Nur ausfüllen, wenn Sie auch den Benutzernamen ändern wollen",
		"form-prompt-old-password": "Aktuell verwendetes Passwort",
		"form-prompt-new-password": "Neues Passwort",
		"form-prompt-new-password-repeat": "Neues Passwort wiederholen",
		"form-prompt-no-matching-password-repeat": "Passwörter stimmen nicht überein.",
		"form-prompt-account-password-error": "Geben Sie das Passwort für diesen Account ein.",
		"form-prompt-username-email-error": "Bitte gültige E-Mail-Adresse angeben.",
		"form-options-placeholder-salutation": "Anrede wählen",
		"form-options-salutation-mrs": "Frau",
		"form-options-salutation-mr": "Herr",
		"form-options-salutation-not-specified": "Keine Angabe",
		"form-options-placeholder-role": "Rolle auswählen",
		"form-options-role-customer": "KSG-Kunde",
		"form-options-role-interested": "Interessent",
		"form-options-role-others": "Sonstige",
		"form-options-language-german": "Deutsch",
		"form-options-language-english": "Englisch",
		"form-options-placeholder-industry": "Branche auswählen",
		"form-options-industry-electronic": "Industrieelektronik",
		"form-options-industry-medicine": "Medizintechnik",
		"form-options-industry-mobility": "Mobility",
		"form-options-industry-communication": "Kommunikation",
		"form-options-industry-ems": "EMS",
		"form-options-industry-space": "Luft- und Raumfahrt",
		"form-options-industry-military": "Militärtechnik",
		"form-options-industry-consumerelectronics": "Konsumelektronik",
		"form-options-industry-others": "Sonstige",
		"form-options-placeholder-department": "Abteilung auswählen",
		"form-options-department-development": "Entwicklung",
		"form-options-department-layout": "Layout",
		"form-options-department-purchasing": "Einkauf",
		"form-options-department-quality": "Qualität",
		"form-options-department-sales": "Vertrieb",
		"form-options-department-management": "Geschäftsleitung",
		"form-options-department-administration": "Administration/Verwaltung",
		"form-options-placeholder-function": "Funktion auswählen",
		"form-options-function-head-of-department": "Abteilungsleitung",
		"form-options-function-teamleader": "Teamleitung",
		"form-options-function-employee": "Mitarbeiter",
		"form-options-function-student": "Student/Auszubildender",
		"register-extended-form-submit-button-label": "Speichern",
		"account-form-submit-button-label": "Daten aktualisieren",
		"bookmarks-share-button-label": "Teilen",
		"bookmarks-share-popup-title": "Mit wem möchten Sie diese Daten teilen?",
		"bookmarks-share-popup-input-label": "E-Mail-Adresse",
		"bookmarks-share-popup-input-email-error-msg": "Ungültige E-Mail-Adresse!",
		"button-form-sending-label": "Senden",
		"filter-hint-on-active-search": "Artikel, die nicht zur aktiven Suche passen, werden Ihrer individuellen Auswahl hinzugefügt, aber nicht sofort angezeigt. Sie erscheinen nach Änderung oder Löschung der Suchanfrage."
	}
}