<script setup lang="ts">
import { ref, onMounted, toRefs, watch, type PropType, type Ref } from 'vue';
import type { PopupModalOptions } from "@/types";


const props = defineProps({
	options: {
		type: Object as PropType<PopupModalOptions>,
		default: null,
	},
});

const emit = defineEmits(["close-popup"]);

const { options } = toRefs(props);
const modal: Ref<HTMLDivElement | null> = ref(null);
const popupDomel: Ref<HTMLDivElement | null> = ref(null);
defineExpose({ popupDomel });

const setOptions = () => {
	const opts = options.value;
	const popupElm = popupDomel.value as HTMLDivElement;
	const modalElm = modal.value as HTMLDivElement;

	if (opts && popupElm) {
		for (const key of Object.keys(opts)) {
			if (key === "modal" || key === "modalclose") {
				if (key === "modal") {
					modalElm.style.setProperty("display", opts.modal ? "block" : "none");
				} else {
					modalElm.addEventListener("click", emitClosePopup, { once: true });
				}
			} else if (key === "width") {
				(popupElm.querySelector(".popup") as HTMLDivElement).style.setProperty("width", opts.width as string);
			} else if (key === "maxWidth") {
				(popupElm.querySelector(".popup") as HTMLDivElement).style.setProperty("max-width", opts.maxWidth as string);
			} else if (key === "height") {
				(popupElm.querySelector(".flex-inner-content") as HTMLDivElement).style.setProperty("height", opts.height as string);
			} else if (key === "maxHeight") {
				(popupElm.querySelector(".flex-inner-content") as HTMLDivElement).style.setProperty("max-height", opts.maxHeight as string);
			} else if (key === "zindex") {
				popupElm.style.setProperty("z-index", (opts.zindex as number).toString());
			} else if (key === "escclose") {
				document.body.addEventListener("keyup", (e: KeyboardEvent) => {
					if (e.key === "Escape") {
						emitClosePopup(e);
					}
				}, { once: true })
			}
		}
	}
};

const emitClosePopup = (e: Event) => {
	emit("close-popup");
};

watch(popupDomel, (popDomel) => {
	if (popDomel) {
		setOptions();
	}
});

onMounted(() => {
	const accRootElm = document.querySelectorAll(".vcpg") as NodeListOf<HTMLDivElement>;
	if (accRootElm) {
		accRootElm.forEach(elm => {
			elm.removeAttribute("style");
		});
	}
});
</script>

<template>
	<div class="popup-outer" ref="popupDomel">
		<div class="modal" ref="modal"></div>
		<div class="popup">
			<div v-if="options && options.closebutton" @click.stop="$emit('close-popup')" class="close-icon icon icon-cross"></div>
			<div class="flex-outer">
				<div class="flex-inner flex-inner-header">
					<slot name="header"></slot>
				</div>
				<div class="flex-inner flex-inner-content">
					<slot name="content"></slot>
				</div>
				<div class="flex-inner flex-inner-footer">
					<slot name="footer"></slot>
				</div>
			</div>
		</div>
	</div>
</template>

<style scoped lang="scss">
@import "@/sass/media";

.popup-outer {
	position: fixed;
	left: 0;
	top: 0;
	width: 100vw;
	height: 100vh;
	z-index: var(--popover-zindex);

	.modal {
		display: var(--popover-modal-display);
		width: 100vw;
		height: 100vh;
		background: var(--popover-modal-bg-color);
		opacity: var(--popover-modal-bg-opacity);
	}

	.popup {
		position: absolute;
		top: 50%;
		left: 50%;
		width: var(--popover-width);
		max-width: var(--popover-max-width);
		transform: translate(-50%, -50%);
		background-color: #fff;
		box-shadow: var(--popover-shadow);
		border-bottom-right-radius: var(--big-border-bottom-right-radius);
		padding: var(--popover-padding);

		.close-icon {
			position: absolute;
			right: -20px;
			top: -20px;
			background-color: var(--color-blaugruen);
			border-radius: 50%;
			width: 39px;
			height: 39px;
			text-align: center;
			padding: 11px 0 0 1px;
			box-shadow: var(--main-shadow);
			transition: background-color .3s ease-in-out;

			&:hover {
				background-color: var(--color-hover-dark-green);
			}
		}

		.icon {
			color: var(--color-white);
		}

		.flex-outer {
			display: flex;
			flex-direction: column;
			gap: 10px;

			.flex-inner {
				&-content {
					height: var(--popover-height);
					max-height: var(--popover-max-height);
					overflow: var(--popover-content-overflow);
					padding: 5px;
				}
			}
		}
	}

	@media screen and (max-width: $size-overlay-switch) {
		.popup {
			width: var(--popover-small-media-width);
		}
	}

}
</style>