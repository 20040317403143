<script setup lang="ts">
import { useStore } from "@/stores/main";
import { useI18n } from 'vue-i18n';

const { t, n } = useI18n({ useScope: "global" });

const store = useStore();
const handleClick = () => {
	store.flashMessage = null;
	setTimeout(() => {
		if (import.meta.env.PROD) {
			(window as any)._paq.push(["trackEvent", "Top menu icon click", "Druckdialog angefordert"]);
		}
		window.print();
	}, 200);
};

</script>

<template>
	<div class="print-button-outer" @click="handleClick">
		<i class="icon icon-print" :title="t('message.button-label-print')"></i>
	</div>
</template>

<style scoped lang="scss">
.print-button-outer {
	.icon {
		font-size: 1.9rem;
	}
}
</style>