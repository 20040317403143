<script setup lang="ts">
import { onMounted, computed, ref, watch, type Ref, type ComputedRef } from "vue"
import { useStore } from "@/stores/main"
import type { UserData, UserDataStorageData, UserDataStorageError, UpdateLastUserDataRequestFormData } from "@/types"
import PopupModal from "@/components/PopupModal.vue"
import RegisterForm from "@/components/form/RegisterForm.vue"
import AccountForm from "@/components/form/AccountForm.vue"
import { updateLastUserDataRequest } from "@/utils/entrypoints"
import { getISOWithTimezoneString } from "@/utils/utils"
import { useI18n } from 'vue-i18n'


const store = useStore()
const { t, n } = useI18n({ useScope: "global" })

const showExtRegistrationForm = ref(false)
const showAccountForm = ref(false)
const showAccountSuccessMsg = ref(false)
const showAllDataForm = ref(false)
const popupDomel: Ref<{ popupDomel: HTMLDivElement | null } | null> = ref(null)

const ssoLoginUser: ComputedRef<UserDataStorageData | UserDataStorageError | null> = computed(() => {
	return store.ssoLoginUser
})

const userData: ComputedRef<UserData | null> = computed(() => {
	if (ssoLoginUser.value && ssoLoginUser.value.benutzer) {
		return ssoLoginUser.value.benutzer as UserData
	}
	return null
})

const closeAllPopups: ComputedRef<number> = computed(() => {
	return store.closeAllPopups
})


const devMode = !import.meta.env.PROD

const logoutLink = () => {
	return `${window.location.pathname}?logout=standardCug`
}

const getUserData = (): UserData | undefined => {
	if (ssoLoginUser.value && ssoLoginUser.value.benutzer) {
		return ssoLoginUser.value.benutzer as UserData
	}
}

const handleExtendedRegistrationForm = () => {
	if (ssoLoginUser.value && !ssoLoginUser.value.error) {
		const user = ssoLoginUser.value.benutzer as UserData
		if (!user.abteilung) {
			showExtRegistrationForm.value = true
		} else {
			showExtRegistrationForm.value = false
		}
	}
}

const closePopup = (e?: Event) => {
	store.currentPopupContent = []
	showExtRegistrationForm.value = false
	showAllDataForm.value = false
	showAccountForm.value = false
	showAccountSuccessMsg.value = false
}

const getNowDatetimeString = (): string => {
	return getISOWithTimezoneString(new Date)
}

const handleLastUserDataRequest = () => {
	if (ssoLoginUser.value) {
		const data: UpdateLastUserDataRequestFormData = {
			id: ssoLoginUser.value.id,
			sprache: store.getLang,
			letzte_benutzerabfrage: getNowDatetimeString()
		}
		updateLastUserDataRequest(data)
	}
}

// const _isUserDataStorageData = (data: any) => {
// 	if (data && data.benutzer !== undefined) {
// 		return true
// 	}
// 	return false
// }

// const handleLangSwitcherForPrivilegedUsers = () => {
// 	const privilegedUsers = [
// 		"katharina.luchner@ksg-pcb.com",
// 		"hans-herbert.queck@ksg-pcb.com",
// 		"mario.berger@ksg-pcb.com",
// 		"dirk.deiters@ksg-pcb.com",
// 		"johann.hackl@ksg-pcb.com",
// 		"peter+ksg@livinglogic.de",
// 		"laura.kaulfuss@ksg-pcb.com",
// 		"henrivaneetveldt@gmail.com",
// 		"michael.soper@rubiconsa.com",
// 	]
// 	if (_isUserDataStorageData(ssoLoginUser.value)) {
// 		const userEmail = (ssoLoginUser.value as UserDataStorageData).benutzer.email
// 		if (privilegedUsers.includes(userEmail)) {
// 			const langSwitcherElm = document.querySelector("#head-sticky-wrapper .langSwitcher_flags") as HTMLDivElement
// 			langSwitcherElm.style.display = "flex"
// 		}
// 	}
// }

const handleUserDataForm = () => {
	showAllDataForm.value = true
}

const handleUserAccountDataForm = () => {
	showAccountForm.value = true
}

const handleTestmode = () => {
	store.testmode = store.testmode ? false : true
	const wipe = true
	store.updateFilterData(wipe)
}

const handleUserDataAccess = () => {
	// Todo: handle user access data. 
}

const getTestmodeLabel = () => {
	if (store.testmode) {
		return t("message.login-user-menu-testmode-on-link-label")
	} else {
		return t("message.login-user-menu-testmode-off-link-label")
	}
}

const handleAccountChangeSuccess = (e: Event) => {
	showAccountSuccessMsg.value = true
	setTimeout(() => {
		closePopup()
		window.location.href = "/?logout=standardCug"
	}, 4000);
}

watch(closeAllPopups, (clap: number) => {
	if (clap) {
		closePopup()
	}
})

watch(() => store.testmode, (tm) => {
	if (tm) {
		store.updateArticleData(false, true)
	} else {
		store.updateArticleData(true)
	}
})

onMounted(async () => {
	if (import.meta.env.PROD) {
		await store.updateUserDataStorage()
		handleLastUserDataRequest()
		handleExtendedRegistrationForm()
	}
	// handleLangSwitcherForPrivilegedUsers()
})
</script>

<template>
	<div class="login-registration-outer">
		<Popper v-if="devMode || (ssoLoginUser && !ssoLoginUser.error)" placement="bottom" offset-skid="10" arrow-padding="0" arrow>
			<div class="login-user-menu-outer">
				<i class="icon icon-user"></i>
				<i class="icon icon-right_arrow_big"></i>
			</div>
			<template #content>
				<div class="menu-outer">
					<p class="label-username">{{ t("message.login-user-menu-label") }}</p>
					<p v-if="userData" class="username">{{ userData.email }}</p>
					<a class="menu-item" href="#" @click.prevent.stop="handleUserDataForm" :disabled="showAccountForm || showExtRegistrationForm"><i class="icon icon-compass"></i> {{ t("message.login-user-menu-user-data-link-label") }}</a>
					<a class="menu-item" href="#" @click.prevent.stop="handleUserAccountDataForm" :disabled="showAccountForm || showExtRegistrationForm"><i class="icon icon-compass"></i> {{ t("message.login-user-menu-user-account-link-label") }}</a>
					<a v-if="userData && userData.testmodus" class="menu-item" href="#" @click.prevent.stop="handleTestmode" :disabled="showAccountForm || showExtRegistrationForm"><i class="icon icon-screwdriver"></i> {{ getTestmodeLabel() }}</a>
					<!-- <a class="menu-item account" href="#" @click.stop="handleUserDataAccess" :disabled="showExtRegistrationForm"><i class="icon icon-user"></i> {{ t("message.login-user-menu-user-account-link-label") }}</a> -->
					<a :href="logoutLink()" class="menu-item"><i class="icon icon-cross"></i> {{ t("message.login-user-menu-logout-label") }}</a>
				</div>
			</template>
		</Popper>
		<Teleport to="body">
			<PopupModal v-if="showExtRegistrationForm" @close-popup="closePopup" :options="{ width: '50vw', maxWidth: '1000px' }" ref="popupDomel">
				<template #content>
					<RegisterForm view="register_extended" @form-save-success="closePopup" />
				</template>
			</PopupModal>
			<PopupModal v-if="showAllDataForm" @close-popup="closePopup" :options="{ width: '80vw', maxWidth: '1200px', closebutton: true, modalclose: true, escclose: true }" ref="popupDomel">
				<template #content>
					<div class="all-data-form">
						<RegisterForm view="register_all" :edit="true" @form-save-success="closePopup" />
					</div>
				</template>
			</PopupModal>
			<PopupModal v-if="showAccountForm" @close-popup="closePopup" :options="{ width: '60vw', maxWidth: '900px', closebutton: true, modalclose: true, escclose: true }" ref="popupDomel">
				<template #content>
					<div v-if="showAccountSuccessMsg" class="account-success-msg">
						<div class="inner">
							<i class="icon icon-rocket"></i>
							<h1>{{ t("message.account-change-success-header") }}</h1>
							<p class="message">{{ t("message.account-change-success-msg") }}</p>
						</div>
					</div>
					<div v-else class="account-data-form">
						<AccountForm :edit="true" @form-save-success="handleAccountChangeSuccess" />
					</div>
				</template>
			</PopupModal>
			<div v-if="store.testmode" id="testmode-status">Testmode</div>
		</Teleport>
	</div>
</template>

<style scoped lang="scss">
.all-data-form,
.account-data-form {
	padding: 20px 50px;
}

.account-success-msg {
	.inner {
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: 20px;

		.icon {
			font-size: 10rem;
		}

		h1 {
			font-size: 1.9rem;
			font-weight: 600;
			margin: 10px 0 20px;
		}

		p {
			text-align: center;
			font-size: 1.2rem;
		}
	}
}

.login-user-menu-outer {
	position: relative;
	z-index: 10000;

	.icon {
		display: inline-block;
		font-size: 2rem;
		color: var(--color-blaugruen);

		&:hover {
			color: var(--color-hover-dark-green);
		}
	}

	.icon-right_arrow_big {
		position: absolute;
		right: 5px;
		bottom: 2px;
		transform: rotate(90deg);
		font-size: .6rem;
	}

	.icon-user {
		margin: 0 13px 0;
	}
}

.menu-outer {
	display: flex;
	flex-direction: column;
	gap: 10px;

	.label-username {
		font-size: .8rem;
		color: var(--color-blaugruen);
		padding: 0;
		margin: 0 0 -10px;
	}

	.username {
		font-weight: 600;
		margin-bottom: 12px;
		white-space: nowrap;
	}

	.menu-item {
		display: grid;
		grid-template-columns: 27px 1fr;
		gap: 10px;
		align-items: center;
		white-space: nowrap;
		text-decoration: none;

		.icon {
			font-size: 1rem;
			text-align: center;
		}

		&.account {
			.icon {
				font-size: 1.6rem;
			}
		}

		&:hover {
			color: var(--color-hover-dark-green);

			.icon {
				color: var(--color-hover-dark-green);
			}
		}

		.icon-compass {
			font-size: 1.3rem;
		}

	}
}

#testmode-status {
	position: absolute;
	left: 20px;
	top: 5px;
	padding: 5px 20px;
	background-color: var(--color-tulip-tree);
	border-radius: 20px;
	z-index: 1000;
}
</style>