<script setup lang="ts">
import { inject, ref, onMounted, onUnmounted, type Ref } from "vue";
import { useStore } from "@/stores/main";
import { jumpToArticleAnchor } from "@/utils/utils";

const store = useStore();
const contentScrollPane = inject("contentScrollPane") as Ref<HTMLElement | null>;
const showNavigator = ref(false);
let scrollEventThrottleTimeout = "";
let startedWithId = "";

const handleBackToTop = (e?: MouseEvent) => {
	if (contentScrollPane.value) {
		contentScrollPane.value.scrollTo({
			top: 0,
			behavior: "smooth"
		});
	}
};

const handleBackToPreviousArticle = (e: MouseEvent) => {
	const filteredArticleData = store.filteredArticleData;
	const currentIntersectedArticleId = store.currentIntersectedArticleIds.length ? store.currentIntersectedArticleIds.at(-1) : "";
	if (startedWithId && filteredArticleData) {
		for (const [idx, article] of filteredArticleData.entries()) {
			if (article.id === currentIntersectedArticleId) {
				if (idx > 0) {
					jumpToArticleAnchor(`__${filteredArticleData[idx - 1].id}`);
				} else {
					handleBackToTop();
				}
			}
		}
	} else if (currentIntersectedArticleId) {
		startedWithId = currentIntersectedArticleId;
		jumpToArticleAnchor(`__${currentIntersectedArticleId}`);
	}
};

const handleNavigatorVisibility = (e: Event) => {
	const csp = contentScrollPane.value;
	if (scrollEventThrottleTimeout) {
		clearTimeout(scrollEventThrottleTimeout);
		scrollEventThrottleTimeout = "";
	}
	setTimeout(() => {
		if (csp && csp.scrollTop > 500) {
			showNavigator.value = true;
		} else {
			showNavigator.value = false;
		}
	}, 1000);
};

onMounted(() => {
	const csp = contentScrollPane.value;
	if (csp) {
		csp.addEventListener("scroll", handleNavigatorVisibility);
	}
});

onUnmounted(() => {
	const csp = contentScrollPane.value;
	if (csp) {
		csp.removeEventListener("scroll", handleNavigatorVisibility);
	}
});

</script>

<template>
	<Transition name="fade">
		<div v-show="showNavigator" class="article-scroll-navigator">
			<div class="bubble bubble-to-top" @click.stop="handleBackToPreviousArticle($event)">
				<i class="icon icon-down_arrow_short" />
			</div>
			<div class="bubble bubble-article-top" @click.stop="handleBackToTop($event)">
				<i class="icon-first icon icon-down_arrow_short" />
				<i class="icon-second icon icon-down_arrow_short" />
			</div>
		</div>
	</Transition>
</template>

<style scoped lang="scss">
.article-scroll-navigator {
	position: fixed;
	bottom: 50px;
	right: 15px;
	z-index: 1000;

	.bubble {
		position: relative;
		background-color: var(--color-blaugruen);
		width: 40px;
		height: 40px;
		border-radius: 50%;
		transition: background-color .3s ease-in-out;
		cursor: pointer;

		&:hover {
			background-color: var(--color-hover-dark-green);
		}

		&-article-top {
			margin: 5px 0 0;
		}

		.icon {
			display: block;
			position: absolute;
			left: 50%;
			top: 50%;
			transform: rotate(180deg) translate(50%, 50%);
			color: var(--color-white);
			font-size: 1.5rem;

			&-first {
				transform: rotate(180deg) translate(50%, 70%);
			}

			&-second {
				transform: rotate(180deg) translate(50%, 38%);
			}
		}
	}
}
</style>