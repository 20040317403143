import { type Ref, unref, provide, inject } from "vue";

interface IntersectionObserverOptions {
	[key: string]: string | Document | Element | undefined | number;
	root?: Document | Element;
	rootMargin?: string;
	threshold?: number;
}

export function useIntersectionObserver(varName: string, callback: IntersectionObserverCallback, options?: IntersectionObserverOptions): void {
	provide(varName, new IntersectionObserver(callback, unref(options)));
}

export function useIntersectionObserverTarget(target: Ref<Element | null>, observerVarName: string, unobserve = false) {
	const io = inject(observerVarName) as IntersectionObserver;
	const t = unref(target);
	if (t) unobserve ? io.unobserve(t) : io.observe(t);
}