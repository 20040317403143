<script setup lang="ts">
import { useStore } from "@/stores/main";
import type { ITreeNode, JsonArticle, FilterIconAssignmentsObject } from "@/types";
import { onMounted, ref, toRef, inject, nextTick, type PropType, type ComputedRef, computed } from "vue";
import Checker from "@/components/Checker.vue";
import { jumpToArticleAnchor } from "@/utils/utils";
import { useI18n } from "vue-i18n"


const props = defineProps({
	node: {
		type: Object as PropType<ITreeNode>,
		required: true,
	},
	idx: {
		type: Number,
		required: true,
	},
});

const { t } = useI18n({ useScope: "global" })

const iconAssingments = inject("iconAssingmentForFilterGroups") as FilterIconAssignmentsObject;
const store = useStore();
const node = toRef(props, "node");
const nodeCssClassString = ref("");

const getCheckedFilters: ComputedRef<number> = computed(() => {
	if (!node.value.parent && node.value.children) {
		node.value.countCheckedNodes(node.value, true);
		return node.value.checkedNodes;
	}
	return 0;
});

const getIcon = () => {
	if (iconAssingments[node.value.identifier]) {
		return iconAssingments[node.value.identifier][0];
	}
	return iconAssingments["ddc_placeholder"][0];
};

const getIconFontSize = () => {
	if (iconAssingments[node.value.identifier]) {
		return iconAssingments[node.value.identifier][1];
	}
	return iconAssingments["ddc_placeholder"][1];
};


const handleChildToggle = (e: Event | null, init = false) => {
	if (!init) {
		node.value.toggleState = node.value.toggleState ? node.value.toggleState * -1 : 1;
	}
	if (node.value.parent) {
		if (node.value.toggleState === 1) {
			nodeCssClassString.value = "icon icon-right_arrow_short icon-right_arrow_short-90";
		} else {
			nodeCssClassString.value = "icon icon-right_arrow_short";
		}
	} else {
		if (node.value.toggleState === 1) {
			nodeCssClassString.value = "icon icon-right_arrow_short icon-right_arrow_short-90";
		} else {
			nodeCssClassString.value = "icon icon-right_arrow_short";
		}
	}
};

const handleTextClickByLevel = async () => {
	const n = node.value;
	let article: JsonArticle | null;
	if (n.parent && !n.children.length) {
		if (n.checkerstate === 1 || n.checkerstate === -1) {
			article = store.getArticleDataByFilterIdentifier(n.id);
			await nextTick(() => {
				if (article) {
					jumpToArticleAnchor(`__${article.id}`)
				}
			});
		} else {
			store.updateUsedFilters(n);
			article = store.getArticleDataByFilterIdentifier(n.id);
			await nextTick(() => {
				if (article) {
					jumpToArticleAnchor(`__${article.id}`)
				}
			});
		}
	} else {
		handleChildToggle(null);
	}
};

const initialClassStrByLevelAndToggleState = () => {
	const n = node.value;
	if (n.parent) {
		nodeCssClassString.value = "icon icon-right_arrow_short";
	} else {
		nodeCssClassString.value = "icon icon-right_arrow_short";
	}
	handleChildToggle(null, true);
};

onMounted(() => {
	initialClassStrByLevelAndToggleState()
});

</script>

<template>
	<div class="outer" :class="{ 'has-no-parent': !node.parent, open: node.toggleState === 1 }">
		<div v-if="node.parent" class="filter-outer" :class="{ 'checked-state-color': node.checkerstate === 1 }" @click.stop.prevent="handleTextClickByLevel">
			<div class="inner-outer">
				<Popper v-if="store.searchPhrase" placement="right" offset-distance="20" arrow-padding="20" arrow hover>
					<div class="flex-outer">
						<Checker :node="node" :checked-status="node.checkerstate" />
						<div class="flex-text-icon">
							<div class="title">{{ node.name }}</div>
						</div>
					</div>
					<template #content="close">
						<div class="popper-as-tooltip">
							{{ t("message.filter-hint-on-active-search") }}
						</div>
					</template>
				</Popper>
				<div v-else class="flex-outer">
					<Checker :node="node" :checked-status="node.checkerstate" />
					<div class="flex-text-icon">
						<div class="title">{{ node.name }}</div>
					</div>
				</div>
				<div v-if="node.children && node.children.length" @click.stop.prevent="handleChildToggle" :class="['checker-node-children-icon', nodeCssClassString]"></div>
				<div v-else class="icon-dummy"></div>
			</div>
		</div>
		<div v-else class="filter-outer" @click.stop.prevent="handleTextClickByLevel">
			<div :class="`group-icon icon ${getIcon()}`" :style="`font-size: ${getIconFontSize()}rem`">
				<div v-if="getCheckedFilters" class="badge-checked">{{ getCheckedFilters }}</div>
			</div>
			<div class="inner-outer">
				<div class="flex-outer">
					<div class="flex-text-icon">
						<div class="title">{{ node.name }}</div>
					</div>
					<div v-if="node.children && node.children.length" @click.stop.prevent="handleChildToggle" :class="nodeCssClassString"></div>
					<div v-else class="icon-dummy"></div>
				</div>
			</div>
		</div>
		<div v-if="node.children && node.children.length" class="children-outer" :class="{ open: node.toggleState === 1 }">
			<FilterItem v-for="(n, idx) in node.children" :node="n" :idx="idx" :key="n.id" />
		</div>
	</div>
</template>

<style scoped lang="scss">
@import "@/sass/icon_font_style";
@import "@/sass/media";

.outer {
	>.filter-outer {
		margin: 0 0 15px;
	}

	&.open>.filter-outer {
		margin: 0;
	}

	&.has-no-parent {
		margin: 0 0 0 15px;
		// overflow: hidden;

		>.children-outer {
			padding: 5px 0 0 3px;
			margin: 0 0 20px;
		}

		// box-shadow: 2px 2px 5px 2px rgba(0, 0, 0, .2);
		// box-shadow: var(--main-shadow);
		&.open {
			// box-shadow: 2px 2px 5px 2px rgba(0, 0, 0, .2);
			background-color: var(--color-beige);
			border-bottom-right-radius: var(--medium-border-bottom-right-radius);

			>.filter-outer {
				background-color: var(--color-beige);

				&:hover {
					background-color: var(--color-yellow-green);
				}
			}

			.group-icon {
				position: relative;
				min-width: 40px;

				.badge-checked {
					box-shadow: 2px 2px 2px 0 rgba(0, 0, 0, .3);
				}

				&.icon {
					background-color: var(--color-white);
					padding: 7px;
					box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, .3);
					border-bottom-right-radius: var(--xsmall-border-bottom-right-radius);
					transform: translate(-2px, -2px);
				}
			}
		}
	}

	.children-outer {
		display: none;
		padding: 0 0 10px 20px;

		&.open {
			display: block;
		}
	}

	.filter-outer {
		display: flex;
		flex-direction: row;
		align-items: center;
		transition: background-color .2s ease-in-out;
		background-clip: content-box;
		cursor: pointer;

		&:hover {
			background-color: var(--color-yellow-green);
		}

		&.checked-state-color {
			.title {
				cursor: pointer;
				color: var(--color-anthrazit);

				&:hover {
					color: #000;
				}
			}
		}

		.group-icon {
			.badge-checked {
				position: absolute;
				top: -10px;
				right: -9px;
				z-index: 100;
				background-color: var(--color-tulip-tree);
				box-shadow: 1px 1px 1px 0 rgba(0, 0, 0, .3);
				font-size: .8rem;
				border-radius: 10px;
				font-weight: 600;
				text-align: center;
				color: var(--color-anthrazit);
				padding: 4px 0 3px;
				min-width: 20px;
				line-height: 1;
				font-family: 'Nexa Regular', 'Droid Sans', 'Helvetica Neue', sans-serif;
				transition: box-shadow .4s ease-in-out;
			}

			&.icon {
				background-color: var(--color-white);
				padding: 7px;
				box-shadow: 1px 1px 1px 0 rgba(0, 0, 0, .2);
				border-bottom-right-radius: var(--xsmall-border-bottom-right-radius);
				transform: translate(0, 0);
				transition: transform .4s ease-in-out, box-shadow .4s ease-in-out;
			}
		}

		.inner-outer {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: space-between;
			gap: 10px;
			width: 100%;

			.popper-as-tooltip {
				max-width: 20vw;
				font-size: .8rem;
			}

			.flex-outer {
				display: flex;
				flex-direction: row;
				align-items: center;
				gap: 10px;
				width: 100%;
				padding: 8px 5px 8px 10px;
			}


			.flex-text-icon {
				display: flex;
				flex-direction: row;
				justify-content: flex-start;
				align-items: center;
				padding: 0px;
				gap: 15px;
				width: 100%;
				font-weight: 600;
				font-size: 1rem;
				color: var(--color-blaugruen);

				.title {
					cursor: pointer;
					font-size: 1rem;
				}

				@media screen and (max-width: $size-xxxxlarge) {
					.title {
						font-size: .8rem;
					}
				}
			}

			.icon {
				font-size: 1.2rem;
				transition: transform .3s ease-in-out;
				padding: 0 5px;

				&-minus {
					font-size: 1.2rem;
				}

				&-right_arrow_short-90 {
					transform: rotate(90deg);
				}

				&.checker-node-children-icon {
					margin: 0 5px 0 0;
				}
			}

			.icon-dummy {
				width: 22px;
			}
		}
	}

	&:not(.has-no-parent) {
		.filter-outer {
			padding: 0;
			margin: 0 0 5px;

			.inner-outer {
				.flex-outer {
					padding: 8px 5px 8px 10px;
				}
			}
		}
	}
}
</style>
