<script setup lang="ts">
import { useStore } from "@/stores/main"
import { useI18n } from 'vue-i18n'
import type { BookmarkData, JsonArticle, JsonBookmarks, UserData, UserDataStorageData, UserDataStorageError } from "@/types"
import { reactive, ref, toRefs, type PropType, nextTick, type Ref } from "vue"
import Faqs from "@/components/Faqs.vue"
import TextInput from "./form/TextInput.vue"
import FormWrapper from "./form/FormWrapper.vue"
import SelectInput from "./form/SelectInput.vue"
import SharePopper from "@/components/SharePopper.vue"
import { computed, type ComputedRef } from "vue"
import { newBookmarkList, newBookmarkListEntry } from "@/utils/entrypoints"

const props = defineProps({
	data: {
		type: Object as PropType<JsonArticle>,
		required: true,
	}
})

const store = useStore()
const { t } = useI18n({ useScope: "global" })
const { titel, identifizierer, faqs } = toRefs(props.data)

const shareButton = ref()
const shareIdentifier = ref("")
const sharedTargetUser = ref("")

const bookmarks: ComputedRef<JsonBookmarks[]> = computed(() => {
	return store.getBookmarks
})

const bookmarkView: ComputedRef<string> = computed(() => {
	return store.getBookmarkCurrArticle
})

const userData: ComputedRef<UserDataStorageData | UserDataStorageError | null> = computed(() => {
	return store.getSsoLoginUser
})

const userEmail: ComputedRef<string> = computed(() => {
	if (userData.value && userData.value.benutzer) {
		return (userData.value.benutzer as UserData).email
	}
	return ""
})

const getBookmarksOptions = () => {
	const opts: Array<any> = []
	for (const [idx, bm] of bookmarks.value.entries()) {
		const optData = {
			key: bm.id,
			value: bm.titel
		}
		if (!idx && !bookmarkData.bmid) {
			bookmarkData.bmid = bm.id
		}
		opts.push(optData)
	}
	return opts
}

const holdSharedTargetUser = (user: string) => {
	sharedTargetUser.value = user
}

const handleShareDialog = (articleIdentifier: string) => {
	shareIdentifier.value = ""
	nextTick(() => {
		shareIdentifier.value = articleIdentifier
	})
}

const bookmarkData: BookmarkData = reactive({
	newBmListTitle: "",
	user: "",
	bmid: "",
	articleid: identifizierer,
	titel: titel.value || "",
	notizen: "",
})

const newBmList = ref(false);

const checkBmDuplicates = (idStr: string, mode = "entries") => {
	for (const bmGroup of bookmarks.value) {
		if (mode === "entries") {
			if (bmGroup.id === bookmarkData.bmid && bmGroup.eintraege) {
				for (const bm of bmGroup.eintraege) {
					if (bm.artikelid.toLowerCase() === idStr.toLowerCase()) {
						return true
					}
				}
			}
		} else {
			if (bmGroup.titel.toLowerCase() === idStr.toLowerCase()) {
				return true
			}
		}
	}
	return false
}

const handleBookmark = async () => {
	if (userEmail.value) {
		bookmarkData.user = userEmail.value
	}
	if (bookmarkData.newBmListTitle) {
		if (!checkBmDuplicates(bookmarkData.newBmListTitle, "group")) {
			await newBookmarkList(bookmarkData)
		}
	} else {
		if (!checkBmDuplicates(bookmarkData.articleid)) {
			await newBookmarkListEntry(bookmarkData)
		}
	}
	await store.updateBookmarksData()
	newBmList.value = false
	bookmarkData.newBmListTitle = ""
}
</script>

<template>
	<div class="article-bottom-bar">
		<div class="right-buttons">
			<SharePopper type="artikel" :lang="store.getLang" :entry-id="store.entryId" @sharedWithUser="holdSharedTargetUser" :share-identifier="shareIdentifier" :identifier="data.identifizierer" :sourceUser="userEmail" :target-user="sharedTargetUser">
				<button @click.stop="handleShareDialog(identifizierer)" ref="shareButton" class="share-icon button green large"><i class="icon icon-share"></i>{{ t("message.bookmarks-share-button-label") }}</button>
			</SharePopper>
			<Popper placement="top" offset-skid="-380" arrow-padding="20" class="popper-faq-content" arrow>
				<div v-if="faqs.length" class="button large green">
					<div class="badge">{{ faqs.length }}</div>
					{{ `${t("message.button-label-faqs")}` }}
				</div>
				<template #content="{ close }">
					<div class="close-cross" @click="close()">
						<i class="icon icon-cross"></i>
					</div>
					<div class="faq-scrollpane">
						<Faqs :data="faqs" />
					</div>
				</template>
			</Popper>
			<Popper @close:popper="newBmList = false;" placement="top" offset-skid="-150" arrow-padding="20" :disabled="bookmarkView ? true : false" arrow>
				<div :class="['button large green', { disabled: bookmarkView }]">
					<div class="icon icon-plus"></div>
					<div>{{ t("message.button-label-set-bookmark") }}</div>
				</div>
				<template #content="{ close }">
					<div class="close-cross" @click="close()">
						<i class="icon icon-cross"></i>
					</div>
					<h1 class="popper-popover">{{ t("message.button-label-set-bookmark") }}</h1>
					<div v-if="!bookmarks.length || newBmList" class="bookmark">
						<p v-if="!bookmarks.length" class="flex-box-row-gap">{{ t("message.hint-set-bookmark-no-bookmark-list") }}.</p>
						<p v-else class="flex-box-row-gap">{{ t("message.hint-new-bookmarklist") }}.</p>
						<FormWrapper class="bookmark">
							<TextInput type="text" :id="`${identifizierer}_bezeichnung`" :placeholder="t('message.form-input-placeholder-bookmark-title')" v-model="bookmarkData.newBmListTitle" focus />
							<div class="bookmark-button-outer">
								<button v-if="bookmarks.length" @click="newBmList = false;" class="large green outline" @click.prevent>{{ t("message.button-label-cancel") }}</button>
								<button @click.prevent="handleBookmark()" class="large green" :disabled="!bookmarkData.newBmListTitle">{{ t("message.button-label-create-bookmarklist") }}</button>
							</div>
						</FormWrapper>
					</div>
					<FormWrapper v-else class="bookmark">
						<div class="field-icon-combi">
							<SelectInput :id="`${identifizierer}_bmid`" v-model="bookmarkData.bmid" :options="getBookmarksOptions()" class="bookmark-list-select" />
							<div @click="newBmList = true" class="button green icon-only icon icon-plus"></div>
						</div>
						<TextInput type="text" :id="`${identifizierer}_title`" :placeholder="t('message.form-input-placeholder-bookmark-title')" v-model="bookmarkData.titel" />
						<TextInput type="textarea" :id="`${identifizierer}_notice`" :placeholder="t('message.form-textarea-title-bookmark-notice')" v-model="bookmarkData.notizen" />
						<div class="bookmark-button-outer">
							<button @click="handleBookmark(); close()" class="large green" :disabled="!bookmarkData.titel">{{ t("message.button-label-add-bookmark") }}</button>
						</div>
					</FormWrapper>
				</template>
			</Popper>
		</div>
	</div>
</template>

<style scoped lang="scss">
.article-bottom-bar {
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 10px 0;
	gap: 10px;

	.popper-faq-content .faq-scrollpane {
		max-width: 1000px;
	}

	.icon {
		color: var(--color-white);
		font-size: 1.2rem;
	}

	.close-cross {
		position: absolute;
		right: -20px;
		top: -20px;
		width: 39px;
		height: 39px;
		border-radius: 50%;
		background-color: var(--color-blaugruen);
		line-height: 1;
		padding: 11px 0 0;
		text-align: center;
		box-shadow: var(--main-shadow);

		.icon {
			font-size: 1rem !important;
		}
	}

	.right-buttons {
		display: flex;
		flex-direction: row;
		justify-content: flex-end;
		align-items: center;
		padding: 0px;
		gap: 10px;
		flex: none;
		order: 3;
		align-self: stretch;
		flex-grow: 1;
	}

	.button {
		position: relative;

		.badge {
			position: absolute;
			top: -6px;
			right: -6px;
			z-index: 100;
			background-color: var(--color-tulip-tree);
			box-shadow: 1px 1px 1px 0 rgba(0, 0, 0, .3);
			font-size: .8rem;
			border-radius: 10px;
			font-weight: 600;
			text-align: center;
			color: var(--color-anthrazit);
			padding: 4px 0 3px;
			min-width: 20px;
			line-height: 1;
			font-family: 'Nexa Regular', 'Droid Sans', 'Helvetica Neue', sans-serif;
		}
	}

	.faq-scrollpane {
		width: 50vw;
		height: 500px;
		overflow-y: auto;
	}

	.bookmark {
		display: flex;
		flex-direction: column;
		gap: 20px 0;
		width: 400px;
		height: 445px;

		.field-icon-combi {
			display: flex;
			flex-direction: row;
			align-items: center;
			gap: 10px;

			.bookmark-list-select {
				width: 100%;
			}
		}

		textarea {
			height: 300px;
		}

		&-button-outer {
			display: flex;
			justify-content: flex-end;
			gap: 15px;
		}
	}
}
</style>
