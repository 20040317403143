<script setup lang="ts">
import { ref, toRefs, onMounted, type PropType, type ComputedRef } from "vue"
import type { JsonArticlePartSectionView, JsonArticlePartSection, ElementTypeDef } from "@/types"
import { getHighlightedText } from "@/utils/utils"
import { useStore } from "@/stores/main"
import { useI18n } from 'vue-i18n'
import imgUrl from "../assets/PCP_1-x-1_DDC_2022.svg"
import ArticlePartTable from "@/components/ArticlePartTable.vue"
import { computed } from "vue"

const props = defineProps({
	data: {
		type: Object as PropType<JsonArticlePartSectionView>,
		required: true,
	},
	articleId: {
		type: String,
		required: true,
	},
	typeDefs: {
		type: Array<ElementTypeDef>,
		required: false,
	},
});
const { data, articleId } = toRefs(props)
const store = useStore()
const { t, n } = useI18n({ useScope: "global" })
const production = import.meta.env.PROD
const pcbSectionData: JsonArticlePartSection | null = data.value.platinenschnittbild
const table = { tabelle: pcbSectionData?.tabelle_zu_schnittbild }
const restrictedTable = ref(false)
let domTable: HTMLTableElement | null = null

const searchPhrases: ComputedRef<string[]> = computed(() => {
	return store.getSearchPhrases
})

const objectId: ComputedRef<string> = computed(() => {
	if (pcbSectionData) {
		return `${pcbSectionData.identifizierer.toLowerCase()}_${articleId.value}`
	}
	return ""
})

const resetTable = () => {
	if (domTable) {
		const tRows = domTable.querySelectorAll("tbody tr") as NodeListOf<HTMLTableRowElement>
		for (const row of tRows) {
			row.style.display = ""
		}
		restrictedTable.value = false
	}
}

const prepareSVG = () => {
	if (pcbSectionData) {
		const elmId = pcbSectionData.identifizierer.toLowerCase()
		const obj = document.querySelector(`#${elmId}_${articleId.value}`) as HTMLObjectElement
		const evFuncA = (e: Event) => {
			const table = document.querySelector(`#${elmId}_${articleId.value}_table`) as HTMLTableElement
			const svgSrc = obj.contentDocument
			domTable = table
			if (svgSrc && table) {
				const tableFirstCols = table.querySelectorAll("tbody tr td:first-child") as NodeListOf<HTMLTableColElement>
				for (const col of tableFirstCols) {
					const svgSrcId = col.innerText.trim()
					const svgElms = []
					for (let i = 0; i < 5; i++) {
						if (svgSrcId) {
							let svgElm = svgSrc.querySelector(`#${svgSrcId}${i > 0 ? "-" + (i + 1) : ""}`) as Element
							if (svgElm) {
								svgElms.push(svgElm)
							}
						}
					}
					if (svgElms.length) {
						for (const svgElm of svgElms) {
							svgElm.setAttribute("cursor", "pointer")
							svgElm.setAttribute("pointer-events", "bounding-box")
							const evFuncB = (e: Event) => {
								const id = svgElm.id.trim().split(/-/)[0]
								let restricted = false
								if (!store.singleSectionViewShiftOnClick) {
									const objectElm = document.querySelector(`#${objectId.value}`) as HTMLObjectElement
									if (objectElm) {
										const headerForObjectElm = objectElm.previousSibling as HTMLElement
										if (headerForObjectElm && headerForObjectElm.nodeType === 1) {
											headerForObjectElm.scrollIntoView({ behavior: "smooth" })
										} else {
											objectElm.scrollIntoView({ behavior: "smooth" })
										}
										// This prevents, that the scrolling runs only ones in a DDC session. 
										// Uncomment the next line, to switch that on. 
										// store.singleSectionViewShiftOnClick = true
									}
								}

								for (const firstCol of tableFirstCols) {
									if (firstCol.innerText.trim() === id) {
										const row = firstCol.parentNode as HTMLTableRowElement | null
										if (row) {
											row.style.display = ""
											row.classList.add("row-highlighted")
										}
									} else {
										const row = firstCol.parentNode as HTMLTableRowElement | null
										if (row) {
											row.style.display = "none"
											row.classList.remove("row-highlighted")
											restricted = true
										}
									}
								}
								restrictedTable.value = restricted
							}
							svgElm.removeEventListener("click", evFuncB)
							svgElm.addEventListener("click", evFuncB)
						}
					}
				}
			}
		}
		obj?.removeEventListener("load", evFuncA)
		obj?.addEventListener("load", evFuncA)
	}
}

onMounted(() => {
	prepareSVG()
});
</script>

<template>
	<div v-if="pcbSectionData" class="pcb-svg-outer">
		<h2 v-if="pcbSectionData.titel" v-html="getHighlightedText((pcbSectionData.titel), searchPhrases)"></h2>
		<object v-if="production" :id="`${pcbSectionData.identifizierer.toLowerCase()}_${articleId}`" :data="pcbSectionData.schnittbild_als_svg" type="image/svg+xml"></object>
		<object v-else :id="`${pcbSectionData.identifizierer.toLowerCase()}_${articleId}`" :data="imgUrl" type="image/svg+xml"></object>
		<ArticlePartTable v-if="table" :data="table" :sectionData="pcbSectionData" :article-id="articleId" />
		<div v-if="restrictedTable" class="tabel-show-all-button">
			<button @click.stop="resetTable" class="large green outline">{{ t("message.button-label-article-show-complete-table") }}</button>
		</div>
	</div>
</template>

<style scoped lang="scss">
.pcb-svg-outer {
	margin: 0;

	object {
		margin: 0 0 20px;
	}

	.tabel-show-all-button {
		display: flex;
		flex-direction: row;
		justify-content: center;
		margin: -25px 0 20px;
	}
}
</style>