import type { FilterTreeNodeData, ITree, ITreeNode, ITreeNodeFlatJson, ITreeNodeFlatJsonNodeData, MatchingEntries } from "@/types"

class TreeNode implements ITreeNode {
	[key: string]: string | ITree | ITreeNode | ITreeNode[] | string[] | number | null | Function;
	tree: ITree;
	id: string;
	identifier: string;
	name: string;
	lang: string;
	parent: ITreeNode | null;
	children: ITreeNode[] = [];
	toggleState = -1;
	checkerstate = 0;
	checkedNodes = 0;
	constructor(jsonNode: FilterTreeNodeData, parent: ITreeNode | null, tree: ITree) {
		this.tree = tree;
		this.id = jsonNode.id;
		this.name = jsonNode.bezeichnung;
		this.lang = jsonNode.sprache;
		this.parent = parent;
		this.identifier = jsonNode.identifizierer as string;
	}

	countCheckedNodes = (node: ITreeNode, clear = false) => {
		if (clear) {
			this.checkedNodes = 0;
		}
		if (node.children) {
			for (const child of node.children) {
				if (child.checkerstate === 1 && !child.children.length) {
					this.checkedNodes++;
				}
				if (child.children) {
					this.countCheckedNodes(child);
				}
			}
		}
	};
}

class Tree implements ITree {
	nodes: ITreeNode[] = [];
	flatJsonTree: ITreeNodeFlatJson | null = null;
	jsonToTree(nodes: FilterTreeNodeData[], parent: ITreeNode | null) {
		for (const node of nodes) {
			const tn = new TreeNode(node, parent, this) as ITreeNode;
			if (parent) {
				parent.children.push(tn);
			} else {
				this.nodes.push(tn);
			}
			if (node.children && node.children.length) {
				this.jsonToTree(node.children, tn);
			}
		}
	}

	findNodeById = (nodes: ITreeNode[], id: string): ITreeNode | null => {
		for (const node of nodes) {
			if (node.id === id) {
				return node;
			}
			if (node.children && node.children.length) {
				const foundnode = this.findNodeById(node.children, id);
				if (foundnode !== null)
					return foundnode;
			}
		}
		return null;
	};

	findNodeByIdentifier = (nodes: ITreeNode[], id: string): ITreeNode | null => {
		for (const node of nodes) {
			if (node.id === id) {
				return node;
			}
			if (node.children && node.children.length) {
				const foundnode = this.findNodeByIdentifier(node.children, id);
				if (foundnode !== null)
					return foundnode;
			}
		}
		return null;
	};

	findNodesListIncludesNodeById = (nodes: ITreeNode[], id: string, result: ITreeNode[][]): ITreeNode[][] => {
		for (const node of nodes) {
			if (node.id === id) {
				result.push(nodes);
			}
			if (node.children && node.children.length) {
				this.findNodesListIncludesNodeById(node.children, id, result);
			}
		}
		return result;
	};

	getNodeParentsPathNodes = (node: ITreeNode, path: ITreeNode[]): ITreeNode[] => {
		path.unshift(node);
		if (node.parent) {
			this.getNodeParentsPathNodes(node.parent, path);
		}
		return path;
	};

	uncheckAll = (nodes?: ITreeNode[]) => {
		let nodesList = this.nodes;
		if (nodes) {
			nodesList = nodes;
		}
		for (const n of nodesList) {
			n.checkerstate = 0;
			if (n.children.length) {
				this.uncheckAll(n.children);
			}
		}
	};

	treeToJson = (nodes: ITreeNode[], flat = true) => {
		if (flat) {
			if (!this.flatJsonTree) {
				this.flatJsonTree = {} as ITreeNodeFlatJson;
			}
			for (const node of nodes) {
				const nodeData: ITreeNodeFlatJsonNodeData = {
					id: node.id,
					identifier: node.identifier,
					name: node.name,
					checkerstate: node.checkerstate,
					toggleState: node.toggleState,
					lang: node.lang
				}
				this.flatJsonTree["_" + node.id] = nodeData;
				if (node.children && node.children.length) {
					this.treeToJson(node.children);
				}
			}
		}
	}
}

const markdownRegExp = /\[(.*)\]\((?<type>#|https\:\/\/)([a-zA-Z0-9ß\?\&\=\-_/\.\:\;]+)\)/;

const getHighlightedText = (text: string | null, searchPhrases: string[]): string => {
	if (text && searchPhrases.length) {
		const mdRegExpMatches = text.match(new RegExp(markdownRegExp, "ig"));
		if (mdRegExpMatches) {
			text = text.replace(new RegExp(markdownRegExp, "ig"), "$_&&_$");
		}
		if (searchPhrases.length) {
			for (const phrase of searchPhrases) {
				text = text.replace(new RegExp(`(${phrase.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')})`, "ig"), `<span class="accent">$1</span>`);
			}
		}
		let idx = 0;
		if (mdRegExpMatches) {
			text = text.replace(/\$_\&\&_\$/g, () => mdRegExpMatches[idx++]);
		}
	}
	return text || "";
};

const makeidentifier = (name: string, names?: string[], prefix?: string, suffix?: string) => {
	const replacements: any = {
		"Ä": "AE",
		"Ö": "OE",
		"Ü": "UE",
		"ä": "ae",
		"ö": "oe",
		"ü": "ue",
		"ß": "ss",
		"ÁĄȦÂẦẮẶÃḀĂǺÅẠẨẰÅĀȂǠẢẪẲǍẬÀǞȀẤẴ": "A",
		"ḆḂḄ": "B",
		"ČĊĈÇĆḈ": "C",
		"ḎḊḒĎḌḐ": "D",
		"ÉȆĔỆÊẾËĒḘĚȨỀḖẸĘḚỂẺȄĖḜỄÈḔẼ": "E",
		"Ḟ": "F",
		"ĜǴḠĢĠĞǦ": "G",
		"ĤḨȞḦḢḪḤ": "H",
		"ĬḬȈĪÌỈÍǏȊĨİḮÎỊÏĮ": "I",
		"Ĵ": "J",
		"ḰǨḲḴĶK": "K",
		"ḶĻḸḺḼĹĽ": "L",
		"ḾṀṂ": "M",
		"ṆÑŃṈṊŇǸṄŅ": "N",
		"ŌṎȎȮỎỖÒỞǬǑṐÓÔỐỘǪỠÕŐṒȪỒỚỢƠṌŎȌȰȬỌỔỜ": "O",
		"ṖṔ": "P",
		"ŔṞṘȐȒŘṚŖṜ": "R",
		"ŜṦȘŚṠṨŠṢŞṤ": "S",
		"ŤṮŢṰṪȚṬ": "T",
		"ǓÙǛŬṶȖÚỦỮǙÛŪŲṸỨỰǗŨŰṲṺƯỪǕŮṴȔỤỬ": "U",
		"ṾṼ": "V",
		"ŴẆẀẈẂẄ": "W",
		"ẊẌ": "X",
		"ỶẎỸÝŸȲỲŶỴ": "Y",
		"ŻŹẐẒŽẔ": "Z",
		"áǻấậẵâăǡãầắặāȧǟåạẩằḁȁȃảẫẳǎàą": "a",
		"ḅḇḃ": "b",
		"ċĉćḉçč": "c",
		"ḑḏďḍḋḓ": "d",
		"ễéẽȅêēḝěḕệḙëếḗęềẹȇėȩểẻèĕḛ": "e",
		"ḟ": "f",
		"ģġǧğḡǵĝ": "g",
		"ẖḥḩȟḧĥḣḫ": "h",
		"ȉīḭìḯĩỉíǐîȋįịïĭ": "i",
		"ǰĵ": "j",
		"ḵǩḱķḳ": "k",
		"ļḽĺḹḷľḻ": "l",
		"ḿṁṃ": "m",
		"ńñṅǹṇňṉņṋ": "n",
		"ọổờǫṍòȍȭỏỗởóơṏőǒôȱốộỡõŏṑȏồớợǭȯōȫṓ": "o",
		"ṕṗ": "p",
		"ṝṟřŗṙȑȓŕṛ": "r",
		"śṥṧšşṡṩșŝṣ": "s",
		"ţṭẗṯṱțťṫ": "t",
		"ụửùǔǜūṵųúȕủữûǚũṷűứựǘůṹȗưừǖŭṳṻ": "u",
		"ṽṿ": "v",
		"ẅẇẘẁẉẃŵ": "w",
		"ẍẋ": "x",
		"ỵỷẏýỹẙŷỳÿȳ": "y",
		"żẕźẑžẓ": "z"
	};
	const newnameParts = [];
	const keywords = ["function", "if", "while", "for", "else", "elif", "elsif", "def", "yield", "from", "global", "True", "False", "None", "true", "false", "null"];
	let testname, testsuffix = 2, chars, i, c;
	for (chars in replacements) {
		name = name.replace(new RegExp("[" + chars + "]", "g"), replacements[chars]);
	}
	for (i = 0; i < name.length; ++i) {
		c = name[i];
		if ("abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ_".indexOf(c) >= 0) {
			newnameParts.push(c);
		} else if ("0123456789".indexOf(c) >= 0) {
			if (!newnameParts.length) {
				newnameParts.push("_");
			}
			newnameParts.push(c);
		} else if (newnameParts.length && newnameParts[newnameParts.length - 1] != "_") {
			newnameParts.push("_");
		}
	}
	while (newnameParts.length && newnameParts[newnameParts.length - 1] == "_") {
		newnameParts.pop();
	}
	let newname = newnameParts.join("") || "_";
	newname = (prefix || "") + newname + (suffix || "");
	testname = newname.toLowerCase();
	names = names || [];
	while (names.indexOf(testname) >= 0 || keywords.indexOf(testname) >= 0) {
		testname = newname.toLowerCase() + testsuffix;
		testsuffix += 1;
	}
	return testname;
};

const jumpToArticleAnchor = (anchor: string, searchPhrase = "", inOverlay = false) => {
	let anchorElm = document.getElementById(anchor);
	if (!inOverlay && searchPhrase) {
		if (anchorElm) {
			anchorElm = anchorElm.querySelector(".accent")
		}
	}
	if (anchorElm) {
		anchorElm.scrollIntoView();
		if (!inOverlay) {
			const cc: HTMLDivElement | null = document.querySelector(".content-column");
			if (cc) {
				cc.scrollBy(0, -22);
			}
		}
	}
};

const getMarkdownParsedTextData = (text: string, mode = "standard"): string => {
	// const mdLinkRex = /\[([\w\s\d]+)\]\((?<type>#|https\:\/\/)([\w\d./?=#]+)\)/;
	const mdLinkRex = markdownRegExp;
	let dlDataAttributeStr = ""
	let dlDataTargetAttributeStr = ""
	let classname = ""
	let classname2 = ""
	let result = text.match(mdLinkRex);
	while (result) {
		if (result.groups && result.groups.type === "#") {
			if (/^download_.*/.test(result[3])) {
				dlDataAttributeStr = 'data-download="true"'
				dlDataTargetAttributeStr = 'data-download-target'
				classname = "download"
				classname2 = "down_arrow"
			} else {
				dlDataAttributeStr = " "
				dlDataTargetAttributeStr = 'data-overlay-target'
				classname = "overlay"
				classname2 = "right_arrow"
			}
			if (mode === "table") {
				text = text.replace(mdLinkRex, `<a href="#" ${dlDataAttributeStr} ${dlDataTargetAttributeStr}="$3" class="markdown-link ${classname}"><i ${dlDataAttributeStr} ${dlDataTargetAttributeStr}="$3" class="icon icon-${classname2}"></i></a>`);
			} else {
				text = text.replace(mdLinkRex, `<a href="#" ${dlDataAttributeStr} ${dlDataTargetAttributeStr}="$3" class="markdown-link ${classname}"><i ${dlDataAttributeStr} ${dlDataTargetAttributeStr}="$3" class="icon icon-${classname2}">&nbsp;</i>$1</a>`);
			}
		} else {
			text = text.replace(mdLinkRex, '<a href="$2$3" target="_blank" class="markdown-link external"><i class="icon icon-right_arrow">&nbsp;</i>$1</a>');
		}
		result = text.match(mdLinkRex);
	}
	return text;
};

const getISOWithTimezoneString = (date?: Date): string => {
	const d: Date = date || new Date();
	const dateStr = new Date(d.getTime() - d.getTimezoneOffset() * 60000).toISOString().split(".")[0];
	return dateStr;
}

let debounceTimeout: any = 0;
const debounceFunction = (func: () => void, time = 500) => {
	clearTimeout(debounceTimeout);
	debounceTimeout = setTimeout(() => {
		func();
	}, time);
};

const bindCMSLangSwitcherForDDC = (matchingEntriesForCurrLang: MatchingEntries) => {
	const entryId = new URLSearchParams(window.location.search).get("entry")
	const langSwitcherFlags = document.querySelectorAll(".lSwCont .flagItem")
	langSwitcherFlags.forEach((flagItem) => {
		const linkItem = flagItem.querySelector("a")
		if (linkItem) {
			const langSwitcherLang = linkItem.innerText.toLowerCase()
			const matchingEntries = matchingEntriesForCurrLang[langSwitcherLang]
			for (const key in matchingEntries) {
				if (Object.prototype.hasOwnProperty.call(matchingEntries, key)) {
					if (entryId === key.replace("source_", "")) {
						const targetId = (matchingEntries[key] as string).replace("target_", "")
						linkItem.href = `${linkItem.href}?entry=${targetId}`
					}
				}
			}
		}
	})
}


export {
	Tree,
	TreeNode,
	markdownRegExp,
	getHighlightedText,
	makeidentifier,
	jumpToArticleAnchor,
	getMarkdownParsedTextData,
	getISOWithTimezoneString,
	debounceFunction,
	bindCMSLangSwitcherForDDC
};
