<script setup lang="ts">
import { onMounted, computed, type ComputedRef } from "vue";
import type { JsonBlogEntry } from "@/types";
import { useStore } from "@/stores/main";
import { HOSTNAME } from "@/utils/entrypoints";

const store = useStore();

const entries: ComputedRef<JsonBlogEntry[] | null> = computed(() => {
	return store.getBlogEntries;
});

onMounted(async () => {
	await store.updateBlogData();
})
</script>

<template>
	<div class="scrollpane">
		<ul v-if="entries" class="blog-entries-list">
			<li v-for="[id, entry] in entries.entries()" :class="['item', { 'item-first': !id, 'item-last': id + 1 === entries.length }]">
				<div v-if="entry.bild" class="entry-image">
					<div class="image" :style="'background-image: url(' + HOSTNAME + entry.bild.url + ');'"></div>
				</div>
				<div class="entry-text">
					<div class="date">{{ `${entry.kategorie} | ${new Date(entry.datum).toLocaleDateString("de-DE", { dateStyle: "medium" })}` }}</div>
					<a v-if="entry.link_zum_blog_artikel" class="title" target="_blank" :href="entry.link_zum_blog_artikel">{{ entry.titel }}</a>
					<span v-else class="title">{{ entry.titel }}</span>
					<p v-if="entry.beschreibung" class="desc">{{ entry.beschreibung }}</p>
				</div>
			</li>
		</ul>
	</div>
</template>

<style scoped lang="scss">
.scrollpane {
	max-height: 600px;
	overflow: auto;
	margin: 0 -20px 15px;

	.blog-entries-list {
		display: grid;
		grid-template-columns: repeat(4, 25%);
		align-items: start;
		list-style: none;
		margin: 0;
		padding: 0;
		background-color: var(--color-white);

		.item {
			display: flex;
			flex-direction: column;
			align-content: flex-start;
			padding: 0 20px;

			.entry-image {
				flex: 1 1;
				margin: 0 0 10px;

				.image {
					min-width: 120px;
					max-width: 100%;
					background-repeat: no-repeat;
					background-size: cover;
					background-position: center;
					aspect-ratio: 16/7;
				}
			}

			.entry-text {
				flex: 1 1;
				min-width: 200px;
			}
		}

		.date,
		.desc {
			font-size: 0.8rem;
		}

		.title {
			font-weight: 700;
		}

		.desc {
			margin: 0 0 1rem;
			line-height: 1.3rem;
		}
	}
}
</style>