<script setup lang="ts">
import { toRefs, reactive, ref, nextTick, type ComputedRef, computed } from "vue"
import { useStore } from "@/stores/main"
import FormWrapper from "@/components/form/FormWrapper.vue"
import TextInput from "@/components/form/TextInput.vue"
import type { UserData, AccountData } from "@/types"
import { changeAccount } from "@/utils/entrypoints"
import { useI18n } from 'vue-i18n'


const props = defineProps({
	// Possible values: "register_base", "register_extended, register_all"
	edit: {
		type: Boolean,
		default: false,
	}
})

const emits = defineEmits(["form-save-success", "form-save-error"])

const { edit } = toRefs(props)
const { t, n } = useI18n({ useScope: "global" })
const store = useStore()
const validate = ref(0)
const errors = ref(new Set())
const serverError = ref(false)
const dataSaved = ref(false)
const submitButton = ref(null)
const newPasswordError = ref()
const repeatPasswordError = ref()
const noChanges = ref(false)
const changeProcessErrors = ref([])

const userData: ComputedRef<UserData | null> = computed(() => {
	const ssoLoginUser = store.ssoLoginUser
	if (ssoLoginUser && ssoLoginUser.benutzer) {
		return ssoLoginUser.benutzer as UserData
	}
	return null
})

const accountData: AccountData = reactive({
	benutzer_email: userData.value?.email || "",
	benutzer_email_neu: "",
	altes_passwort: "",
	passwort: "",
	passwort_repeat: "",
	sprache: store.lang
})

const submitForm = async () => {
	validate.value++
	dataSaved.value = false
	serverError.value = false
	changeProcessErrors.value = []
	const sbDom = submitButton.value ? submitButton.value as HTMLButtonElement : null
	nextTick(async () => {
		if (accountData.passwort !== accountData.passwort_repeat) {
			newPasswordError.value = {
				id: "new-password",
				errorMsg: t("message.form-prompt-no-matching-password-repeat")
			}
			repeatPasswordError.value = {
				id: "new-password-repeat",
				errorMsg: t("message.form-prompt-no-matching-password-repeat")
			}
			return
		}
		if (accountData.benutzer_email_neu === "" && accountData.passwort === "" && accountData.passwort_repeat === "") {
			noChanges.value = true
			return
		} else {
			noChanges.value = false
		}
		if (!errors.value.size) {
			if (sbDom) {
				sbDom.disabled = true
			}
			try {
				const prefetchResp = await changeAccount()
				if (prefetchResp && prefetchResp.ok) {
					const resp = await changeAccount(accountData)
					if (resp && resp.ok) {
						const errors = await resp.json()
						if (!errors.length) {
							dataSaved.value = true
							emits("form-save-success")
						} else {
							changeProcessErrors.value = errors
						}
					} else {
						throw new Error("Change account server error!");
					}
				} else {
					throw new Error("Change account prefetch server error!")
				}
			} catch (error) {
				dataSaved.value = false
				serverError.value = true
				emits("form-save-error", error)
			}
			if (sbDom) {
				sbDom.disabled = false
			}
		}
	})
}

const updateErrors = (fieldName: string, error: boolean) => {
	if ((fieldName === "new-password" || fieldName === "new-password-repeat") && accountData.passwort !== "" && accountData.passwort_repeat !== "" && accountData.passwort === accountData.passwort_repeat) {
		errors.value.delete("new-password")
		errors.value.delete("new-password-repeat")
		newPasswordError.value = null
		repeatPasswordError.value = null
	} else {
		if (error) {
			errors.value.add(fieldName)
		} else {
			errors.value.delete(fieldName)
		}
	}
}

</script>

<template>
	<div class="account-form-outer">
		<h2 class="all-header">{{ t("message.account-data-form-header") }}</h2>
		<p v-if="errors.size" class="error-msg">{{ t("message.account-form-error") }}</p>
		<p v-if="serverError" class="error-msg">{{ t("message.register-extended-form-server-error") }}</p>
		<div v-if="changeProcessErrors.length" class="error-msg change-process-errors">
			{{ t("message.account-change-process-error") }}
			<ul>
				<li v-for="error in changeProcessErrors">{{ error }}</li>
			</ul>
		</div>
		<FormWrapper>
			<div class="fields fields-short">
				<section id="username-data">
					<p>{{ t('message.form-prompt-user') }}: <strong>{{ accountData.benutzer_email }}</strong></p>
					<TextInput autocomplete="one-time-code" type="password" :label="t('message.form-prompt-old-password')" id="old-password" v-model="accountData.altes_passwort" :validate="validate" validation-reg-exp=".+" :error-msg="t('message.form-prompt-account-password-error')" @field-error="updateErrors" />
				</section>
				<p v-if="noChanges" class="error-msg">{{ t("message.account-change-no-changes-msg") }}</p>
				<section id="password-data">
					<TextInput autocomplete="one-time-code" type="text" :label="t('message.form-prompt-user-new')" :placeholder="t('message.form-prompt-user-new-placeholder')" id="new-username" v-model="accountData.benutzer_email_neu" :validate="validate" validation-reg-exp="(^$|^\w+([+\.-]?\w+)*@\w+([+\.-]?\w+)*(\.\w{2,5})+$)" :error-msg="t('message.form-prompt-username-email-error')" @field-error="updateErrors" />
					<TextInput autocomplete="one-time-code" type="password" :label="t('message.form-prompt-new-password')" id="new-password" v-model="accountData.passwort" :validate="validate" @field-error="updateErrors" :external-error="newPasswordError" />
					<TextInput autocomplete="one-time-code" type="password" :label="t('message.form-prompt-new-password-repeat')" id="new-password-repeat" v-model="accountData.passwort_repeat" :validate="validate" @field-error="updateErrors" :external-error="repeatPasswordError" />
				</section>
			</div>
			<div v-if="!dataSaved" class="buttons-outer">
				<button @click.stop.prevent="submitForm" class="register green large" ref="submitButton">
					{{ t("message.account-form-submit-button-label") }}
				</button>
			</div>
		</FormWrapper>
	</div>
</template>

<style scoped lang="scss">
@import "@/sass/media";

.account-form-outer {
	margin: 0 0 20px;

	.disclaimer {
		margin: 0 0 25px;
		font-size: 0.8rem;
		text-align: left;
	}

	h2 {
		text-align: left;
		color: var(--color-blaugruen);
		font-weight: 600;
		font-size: 2rem;
		margin: 18px 0 10px;
		text-align: center;
	}

	p {
		margin: 0 0 15px;

	}

	.error-msg {
		color: #c93b3b;
	}

	section {
		text-align: left;
		margin: 0 0 35px;
	}


	.all-header {
		text-align: left;
	}

	.all-extended-header {
		margin: 60px 0 0;
	}

	.fields {
		margin: 20px 0 0;
	}

	.input-outer {
		margin: 0 0 15px;
		width: 100%;
	}

	.buttons-outer {
		text-align: center;

		button {
			margin: 5px auto 0 auto;

			&.register {
				padding: 0.3125rem 2.5rem;
			}
		}
	}
}
</style>