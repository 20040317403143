<script setup lang="ts">
import { } from "vue";
import type { } from "@/types";
import { useI18n } from "vue-i18n";

const { t } = useI18n({ useScope: "global" });
</script>

<template>
	<div class="performance-message-outer">
		<div class="icon-outer">
			<i class="icon icon-rocket"></i>
		</div>
		<div class="text-outer">
			<h1>{{ t("message.performance-message-header") }}</h1>
			<p class="message">{{ t("message.performance-message-text") }}</p>
			<p class="message" v-html="t('message.performance-message-text2')"></p>
		</div>
	</div>
</template>

<style scoped lang="scss">
.performance-message-outer {
	display: flex;
	flex-direction: row;
	gap: 20px;
	padding: 20px;
	background-color: var(--color-white);
	box-shadow: var(--main-shadow);
	border-bottom-right-radius: var(--big-border-bottom-right-radius);
	margin: var(--content-default-margin);
	margin-top: 100px;

	.buttons-outer {
		display: flex;
		flex-direction: row;
		justify-content: center;
		gap: 35px;
		margin: 0 0 30px;
	}

	.icon-outer {
		.icon {
			font-size: 12rem;
			color: var(--color-blaugruen);
		}
	}
}
</style>