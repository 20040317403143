<script setup lang="ts">
import { useStore } from "@/stores/main";
import { useI18n } from 'vue-i18n';
import { computed, onMounted } from "vue";
import { type ComputedRef, type Ref, ref, toRefs, watch } from "vue";
import Pill from "./Pill.vue";
import type { ITreeNode, UserDataStorageJsonData } from "@/types";
import { debounceFunction } from "@/utils/utils";

const props = defineProps({
	hideOnBookmark: {
		type: String,
		default: "",
	},
});

const { hideOnBookmark } = toRefs(props);
const store = useStore();
const { t, n } = useI18n({ useScope: "global" });
const overflowArea: Ref<HTMLDivElement | null> = ref(null);
const showInfoPanel = ref(false);
const filtersCount = ref(0);
const searchUnfilteredCount = ref(0);


const getSearchPhrases: ComputedRef<string[]> = computed(() => {
	return store.getSearchPhrases;
})

const getFilter = computed(() => {
	const sph = getSearchPhrases.value;
	if (sph.length && store.filteredArticleData) {
		const filtersForFoundArticles: ITreeNode[] = [];
		for (const article of store.filteredArticleData) {
			if (store.unfilteredArticleData && !store.unfilteredArticleData.includes(article)) {
				if (store.filterData && article.zutreffender_filter) {
					const filterNode = store.filterData.findNodeById(store.filterData.nodes, article.zutreffender_filter.id)
					if (filterNode && !filtersForFoundArticles.includes(filterNode)) {
						filtersForFoundArticles.push(filterNode);
					}
				}
			}
		}
		return filtersForFoundArticles;
	}
	return store.getCurrentUsedFilters;
})

const getSearchUncheckedFilter = computed(() => {
	const sph = getSearchPhrases.value;
	if (sph.length && store.unfilteredArticleData) {
		const filtersForFoundArticles: ITreeNode[] = [];
		for (const article of store.unfilteredArticleData) {
			if (store.filterData && article.zutreffender_filter) {
				const filterNode = store.filterData.findNodeById(store.filterData.nodes, article.zutreffender_filter.id);
				if (filterNode && !filtersForFoundArticles.includes(filterNode)) {
					filtersForFoundArticles.push(filterNode);
				}
			}
		}
		return filtersForFoundArticles;
	}
	return store.getCurrentUsedFilters;
})

const getArticleCount = computed(() => {
	return store.filteredArticleData ? store.filteredArticleData.length : 0;
})

const getSearchUnfilteredArticleCount = computed(() => {
	return store.unfilteredArticleData ? store.unfilteredArticleData.length : 0;
})

const clearAllFilters = (e: Event) => {
	store.updateUsedFilters("*");
}

const clearAllPhrases = (e: Event) => {
	store.searchPhrase = "";
	store.updateArticleData();
	calcSearchFilterInfoHeight(store.searchFilterInfoExpanded, true);
}

const handleFilterRowToggleState = () => {
	store.searchFilterInfoExpanded = store.searchFilterInfoExpanded ? false : true;
	const writeToApp = true;
	debounceFunction(() => store.updateUserDataStorage(writeToApp), 2000);
};

const calcSearchFilterInfoHeight = (expanded: boolean, update = false) => {
	const ova = overflowArea.value;
	if (update && ova) {
		ova.classList.add("no-transition");
		ova.style.maxHeight = "1px";
		ova.style.minHeight = "1px";
		setTimeout(() => {
			ova.classList.remove("no-transition");
			if (ova && expanded) {
				ova.style.maxHeight = "30vh";
				ova.style.minHeight = `${ova.scrollHeight}px`;
			} else if (ova) {
				ova.style.maxHeight = "1px";
				ova.style.minHeight = "1px";
			}
		}, 300);
	} else {
		if (ova && expanded) {
			ova.style.maxHeight = "30vh";
			ova.style.minHeight = `${ova.scrollHeight}px`;
		} else if (ova) {
			ova.style.maxHeight = "1px";
			ova.style.minHeight = "1px";
		}
	}
};

watch(() => store.searchFilterInfoExpanded, (expanded) => {
	calcSearchFilterInfoHeight(expanded);
});

watch(getFilter, (currFilters) => {
	var ova = overflowArea.value;
	filtersCount.value = currFilters.length;
	if (store.initializing) {
		handleFilterRowToggleState();
	}
	if (!currFilters.length && ova) {
		ova.style.maxHeight = "";
		ova.style.minHeight = "";
	}
});

watch(getSearchUncheckedFilter, (currFilters) => {
	searchUnfilteredCount.value = currFilters.length;
});

watch(() => store.currentUsedFilters, (currFilters) => {
	if (currFilters.length) {
		showInfoPanel.value = true;
	} else {
		showInfoPanel.value = false;
	}
});

watch(() => store.ssoLoginUser, (currUser) => {
	// Temporarily we ignore the user session data storage.
	// if (currUser && currUser.json_daten) {
	// 	store.searchFilterInfoExpanded = (JSON.parse(currUser.json_daten) as UserDataStorageJsonData).pills_toggle_state === "1" ? true : false;
	// }
});

</script>

<template>
	<div v-show="!hideOnBookmark" class="top-info-rows">
		<div class="search-filter-info-outer">
			<div class="filter-panel">
				<div class="overflow-area" ref="overflowArea">
					<div class="filter-info-outer">
						<div v-if="store.searchPhrase" class="pills">
							<div class="info-title">{{ t("message.search-result-title") }}</div>
							<!-- <div class="plist"> -->
							<Pill v-for="p in getSearchPhrases" :title="p" searchphrase />
							<Pill :title="t('message.button-label-delete-all')" @click.stop="clearAllPhrases" woicon highlight hasevent />
							<!-- </div> -->
						</div>
						<div v-if="store.searchPhrase" class="pills">
							<div class="info-title">{{ `${filtersCount} ${t("message.found-filter-info-title")}` }}</div>
							<!-- <div class="plist"> -->
							<Pill v-for="n in getFilter" :title="n.name" :node="n" woicon />
							<!-- </div> -->
						</div>
						<div v-if="store.searchPhrase && store.unfilteredArticleData && store.unfilteredArticleData.length" class="pills">
							<div class="info-title">{{ `${getSearchUnfilteredArticleCount} ${t("message.found-unfiltered-info-title")}` }}</div>
							<!-- <div class="plist"> -->
							<Pill v-for="n in getSearchUncheckedFilter" :title="n.name" :node="n" checker-tooltip="Aktivieren zur Übernahme in den aktuellen Filtersatz." :wochecker="false" woicon />
							<!-- </div> -->
						</div>
						<div v-if="!store.searchPhrase" class="pills">
							<div class="info-title">{{ t('message.filter-info-title') }}</div>
							<!-- <div class="plist"> -->
							<Pill v-for="n in getFilter" :title="n.name" :node="n" />
							<Pill :title="t('message.button-label-delete-all')" @click.stop="clearAllFilters" woicon highlight hasevent />
							<!-- </div> -->
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="toggle-icon" @click="handleFilterRowToggleState" :class="{ expanded: store.searchFilterInfoExpanded }">
			<i class="icon icon-down_arrow_short"></i>
		</div>
	</div>
</template>

<style scoped lang="scss">
.top-info-rows {
	display: flex;
	flex-direction: row;
	align-items: end;
	justify-content: space-between;
	gap: 20px;
	width: 100%;

	.toggle-icon {
		margin: 0 25px 15px 0;
		width: 35px;
		height: 25px;
		transition: transform .3s ease-in-out;

		&.expanded {
			transform: rotate(180deg);
		}

		.icon {
			font-size: 2rem;
			color: var(--color-white);
		}
	}

	.search-filter-info-outer {
		margin: 0 0 10px;

		.filter-panel {
			display: flex;
			flex-direction: column;
			margin: 0 13px 0 0;

			.overflow-area {
				margin: 8px 20px;
				overflow: hidden;
				max-height: 30vh;
				min-height: 1px;
				transition: min-height .4s ease-in-out, max-height .6s ease-in-out;

				&.no-transition {
					transition: none;
				}
			}

			.filter-info-outer {
				display: flex;
				flex-direction: column;
				gap: 10px;
				user-select: none;
				padding: 5px 5px 7px 2px;

				.info-title {
					white-space: nowrap;
					margin: 0;
					color: var(--color-white);
					line-height: 1;
				}

				.pills {
					display: flex;
					flex-direction: row;
					flex-wrap: wrap;
					align-items: center;
					gap: 10px;

					// .plist {
					// 	display: flex;
					// 	flex-direction: row;
					// 	align-items: center;
					// 	flex-wrap: wrap;
					// 	gap: 10px;
					// }
				}
			}
		}
	}
}
</style>
