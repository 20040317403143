<script setup lang="ts">
import { onMounted, toRefs, type PropType } from "vue";
import type { JsonEntryTab } from "@/types";
import { useStore } from "@/stores/main";

const props = defineProps({
	data: {
		type: Object as PropType<JsonEntryTab>,
		required: true,
	},
});

const store = useStore();
const { data } = toRefs(props);

const handleClick = (e: Event) => {
	store.updateDataByTab(data.value);
};

onMounted(() => {
	if (data.value.ausgewaehlt) {
		store.updateDataByTab(data.value);
	}
});
</script>

<template>
	<div v-if="data.in_tab_leiste_anzeigen" class="entry-tab" :class="{ 'entry-tab-here': data.ausgewaehlt }">
		<a href="#" @click.stop.prevent="handleClick($event)">{{ data.tab_bezeichnung }}</a>
	</div>
</template>

<style scoped lang="scss">
.entry-tab {
	background-color: var(--color-white);
	border-width: 1px 1px 0 1px;
	border-style: solid;
	border-color: var(--color-blaugruen);
	transition: background-color .3s ease-in-out;

	a {
		display: block;
		width: 100%;
		height: 100%;
		padding: 2px 15px;
		font-size: 1.125rem;
		color: var(--color-anthrazit);
		text-decoration: none;
	}

	&:hover {
		background-color: var(--color-beige);
	}

	&-here {
		background-color: var(--color-blaugruen);
		padding-bottom: 4px;

		&:hover {
			background-color: var(--color-blaugruen);
		}

		a {
			color: var(--color-white);
		}
	}

}
</style>