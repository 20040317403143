<script setup lang="ts">
import { toRefs, reactive, ref, nextTick, type ComputedRef, computed, onMounted } from "vue"
import { useStore } from "@/stores/main"
import FormWrapper from "@/components/form/FormWrapper.vue"
import TextInput from "@/components/form/TextInput.vue"
import SelectInput from "@/components/form/SelectInput.vue"
import type { UserData, BaseRegisterUserData, ExtendedRegisterUserData, AppddLookupType } from "@/types"
import { getExistingUserData, registerUser, registerUserExtended, registeredUserAll } from "@/utils/entrypoints"
import { useI18n } from 'vue-i18n'


const props = defineProps({
	// Possible values: "register_base", "register_extended, register_all"
	view: {
		type: String,
		default: "register_base",
	},
	edit: {
		type: Boolean,
		default: false,
	}
})

const emits = defineEmits(["form-save-success", "form-save-error"])

const { view, edit } = toRefs(props)
const { t, n } = useI18n({ useScope: "global" })
const store = useStore()
const validate = ref(0)
const errors = ref(new Set())
const serverError = ref(false)
const dataSaved = ref(false)
const userAlreadyExists = ref("")
const submitButton = ref(null)

const userData: ComputedRef<UserData | null> = computed(() => {
	const ssoLoginUser = store.ssoLoginUser
	if (ssoLoginUser && ssoLoginUser.benutzer) {
		return ssoLoginUser.benutzer as UserData
	}
	return null
})

const lang: ComputedRef<string> = computed(() => {
	return store.getLang
})

let salutationOpts: AppddLookupType[],
	customerOpts: AppddLookupType[],
	languageOpts: AppddLookupType[],
	brancheOpts: AppddLookupType[],
	departmentOpts: AppddLookupType[],
	functionOpts: AppddLookupType[]

const baseRegistrationData: BaseRegisterUserData = reactive({
	id: "",
	anrede2: "",
	vorname: "",
	nachname: "",
	organisation: "",
	e_mail: "",
	account_aktiv2: "",
	ksg_kunde_oder_interessent: "",
	sprache: lang.value
})

const extendedRegistrationData: ExtendedRegisterUserData = reactive({
	id: userData.value ? userData.value.id : "",
	branche: "",
	abteilung: "",
	funktion: "",
	telefonnummer: "",
	strasse2: "",
	postleitzahl: "",
	ort: "",
	land: ""
})

const getRegisterUserDataValueForKey = (regData: BaseRegisterUserData | ExtendedRegisterUserData, key: string, lookups?: AppddLookupType[] | null): string => {
	if (userData.value && Object.prototype.hasOwnProperty.call(userData.value, key)) {
		if (Object.prototype.hasOwnProperty.call(regData, key)) {
			if (lookups) {
				for (const lookup of lookups) {
					if (lookup.value === userData.value[key]) {
						return lookup.key
					}
				}
			}
			return userData.value[key]
		}
	}
	return ""
}

const initFormFieldsModel = () => {
	const baseFormCtrlTypes = store.registerBaseFormControlTypes
	const extFormCtrlTypes = store.registerExtendedFormControlTypes
	for (const baseKey in baseFormCtrlTypes) {
		if (Object.prototype.hasOwnProperty.call(baseFormCtrlTypes, baseKey)) {
			let lup = false;
			const types = baseFormCtrlTypes[baseKey]
			const lookups = types.lookups as AppddLookupType[]
			if (lookups) {
				for (const lookup of lookups) {
					const lupVal = lookup.value as string
					if (/^message\./.test(lupVal)) {
						lookup.value = t(lupVal)
					}
				}
			}
			if (["anrede2", "ksg_kunde_oder_interessent", "sprache"].includes(baseKey)) {
				if (baseKey === "anrede2") {
					salutationOpts = lookups
					lup = true
				} else if (baseKey === "ksg_kunde_oder_interessent") {
					customerOpts = lookups
					lup = true
				} else if (baseKey === "sprache") {
					languageOpts = lookups
					lup = true
				}
			}
			if (edit.value) {
				baseRegistrationData[baseKey] = getRegisterUserDataValueForKey(baseRegistrationData, baseKey, lup ? lookups : null)
			}
		}
	}
	for (const extKey in extFormCtrlTypes) {
		if (Object.prototype.hasOwnProperty.call(extFormCtrlTypes, extKey)) {
			let lup = false;
			const types = extFormCtrlTypes[extKey]
			const lookups = types.lookups as AppddLookupType[]
			if (lookups) {
				for (const lookup of lookups) {
					const lupVal = lookup.value as string
					if (/^message\./.test(lupVal)) {
						lookup.value = t(lupVal)
					}
				}
			}
			if (["branche", "abteilung", "funktion"].includes(extKey)) {
				if (extKey === "branche") {
					brancheOpts = lookups
					lup = true
				} else if (extKey === "abteilung") {
					departmentOpts = lookups
					lup = true
				} else if (extKey === "funktion") {
					functionOpts = lookups
					lup = true
				}
			}
			if (edit.value) {
				extendedRegistrationData[extKey] = getRegisterUserDataValueForKey(extendedRegistrationData, extKey, lup ? lookups : null)
			}
		}
	}
}

const submitForm = async () => {
	validate.value++
	dataSaved.value = false
	serverError.value = false
	userAlreadyExists.value = ""
	const sbDom = submitButton.value ? submitButton.value as HTMLButtonElement : null
	if (view.value === "register_base") {
		nextTick(async () => {
			if (!errors.value.size) {
				if (sbDom) {
					sbDom.disabled = true
				}
				const resp = await fetch(getExistingUserData(baseRegistrationData.e_mail.toLowerCase())).catch((status) => {
					const message = `Not able to request existing user data: ${status}`
					throw new Error(message)
				})
				if (resp && resp.ok) {
					const existingUsers = await resp.json()
					if (existingUsers.length) {
						const euser = existingUsers[0] as UserData
						if (
							euser.email.toLowerCase() === baseRegistrationData.e_mail.toLowerCase() && (!euser.vertrauenswuerdig || euser.account_aktiv2)
						) {
							userAlreadyExists.value = baseRegistrationData.e_mail.toLowerCase()
							return
						} else if (!euser.account_aktiv2 && euser.vertrauenswuerdig && !euser.nachname) {
							baseRegistrationData.id = euser.id
							baseRegistrationData.account_aktiv2 = "1"
						}
					}
					try {
						await registerUser(baseRegistrationData)
						dataSaved.value = true
						emits("form-save-success")
					} catch (error) {
						dataSaved.value = false
						serverError.value = true
						emits("form-save-error", error)
					}
					if (sbDom) {
						sbDom.disabled = false
					}
				}
			}
		})
	} else if (view.value === "register_extended") {
		nextTick(async () => {
			if (!errors.value.size) {
				try {
					await registerUserExtended(extendedRegistrationData)
					emits("form-save-success")
				} catch (error) {
					serverError.value = true
					emits("form-save-error", error)
				}
			}
		})
	} else if (view.value === "register_all") {
		nextTick(async () => {
			if (!errors.value.size) {
				try {
					registeredUserAll(baseRegistrationData, extendedRegistrationData)
					emits("form-save-success")
				} catch (error) {
					serverError.value = true
					emits("form-save-error", error)
				}
			}
		})
	}
}

const updateErrors = (fieldName: string, error: boolean) => {
	if (error) {
		errors.value.add(fieldName)
	} else {
		errors.value.delete(fieldName)
	}
}

const handleVisibilityByDepartment = (): boolean => {
	if (extendedRegistrationData.abteilung === 'geschaeftsleitung') {
		errors.value.delete("funktion")
		return false
	} else {
		return true
	}
}

initFormFieldsModel()

</script>

<template>
	<div class="register-form-outer">
		<template v-if="view === 'register_all'">
			<h2 class="all-header">{{ t("message.register-all-personal-data-form-header") }}</h2>
		</template>
		<template v-if="view === 'register_base'">
			<h2 v-if="!userAlreadyExists">{{ t("message.register-base-form-header") }}</h2>
			<p v-if="errors.size" class="error-msg">{{ t("message.register-base-form-error") }}</p>
		</template>
		<template v-if="view === 'register_extended'">
			<h2>{{ t("message.register-extended-form-header") }}</h2>
			<p>{{ t("message.register-extended-form-paragraph") }}</p>
			<p v-if="errors.size" class="error-msg">{{ t("message.register-extended-form-error") }}</p>
		</template>
		<p v-if="serverError" class="error-msg">{{ t("message.register-extended-form-server-error") }}</p>
		<FormWrapper>
			<div v-if="view === 'register_base' || view === 'register_all'" class="registration-step-1">
				<div v-if="dataSaved || userAlreadyExists">
					<div v-if="dataSaved" class="data-success-msg">
						<h1>{{ t("message.register-base-form-data-success-msg-header") }}</h1>
						<p v-html="t('message.register-base-form-data-success-msg-paragraph-1')"></p>
						<p v-html="t('message.register-base-form-data-success-msg-paragraph-2')"></p>
					</div>
					<div v-if="userAlreadyExists" class="user-already-exists-msg">
						<h2>{{ t("message.register-base-form-user-exists-msg-header") }}</h2>
						<p v-html="t('message.register-base-form-user-exists-msg-paragraph-1', { user_already_exists: userAlreadyExists })"></p>
						<p v-html="t('message.register-base-form-user-exists-msg-paragraph-2')"></p>
					</div>
				</div>
				<div v-else class="fields fields-short">
					<div class="flex-container flex-1-3">
						<SelectInput :label="t('message.form-prompt-salutation')" id="salutation" :options="salutationOpts" v-model="baseRegistrationData.anrede2" :validate="validate" validation-reg-exp=".+" @field-error="updateErrors" />
						<TextInput type="text" :label="t('message.form-prompt-organisation')" id="organisation" v-model="baseRegistrationData.organisation" :validate="validate" validation-reg-exp=".+" @field-error="updateErrors" />
					</div>
					<div class="flex-container flex-1-1">
						<TextInput type="text" :label="t('message.form-prompt-firstname')" id="firstname" v-model="baseRegistrationData.vorname" :validate="validate" validation-reg-exp=".+" @field-error="updateErrors" />
						<TextInput type="text" :label="t('message.form-prompt-surname')" id="surname" v-model="baseRegistrationData.nachname" :validate="validate" validation-reg-exp=".+" @field-error="updateErrors" />
					</div>
					<div class="flex-container flex-1-3">
						<SelectInput :label="t('message.form-prompt-language')" id="sprache" :options="languageOpts" v-model="baseRegistrationData.sprache" :validate="validate" validation-reg-exp=".+" @field-error="updateErrors" />
						<SelectInput :label="t('message.form-prompt-role')" id="ksg_kunde_oder_interessent" :options="customerOpts" v-model="baseRegistrationData.ksg_kunde_oder_interessent" :validate="validate" validation-reg-exp=".+" @field-error="updateErrors" />
					</div>
					<TextInput v-if="view === 'register_base'" type="text" :label="t('message.form-prompt-email')" id="email" v-model="baseRegistrationData.e_mail" :validate="validate" validation-reg-exp="^\w+([+\.-]?\w+)*@\w+([+\.-]?\w+)*(\.\w{2,5})+$" @field-error="updateErrors" />
				</div>
			</div>
			<template v-if="view === 'register_all'">
				<h2 class="all-header all-extended-header">{{ t("message.register-all-extended-data-form-header") }}</h2>
			</template>
			<div v-if="view === 'register_extended' || view === 'register_all'" class="fields fields-extended registration-step-2">
				<div class="flex-container flex-1-1-1">
					<SelectInput :label="t('message.form-prompt-industry')" id="branche" :options="brancheOpts" v-model="extendedRegistrationData.branche" :validate="validate" validation-reg-exp=".+" @field-error="updateErrors" />
					<SelectInput :label="t('message.form-prompt-department')" id="abteilung" :options="departmentOpts" v-model="extendedRegistrationData.abteilung" :validate="validate" validation-reg-exp=".+" @field-error="updateErrors" />
					<Transition name="fade" mode="in-out">
						<SelectInput v-if="handleVisibilityByDepartment()" :label="t('message.form-prompt-position')" id="funktion" :options="functionOpts" v-model="extendedRegistrationData.funktion" :validate="validate" validation-reg-exp=".+" @field-error="updateErrors" />
					</Transition>
				</div>
				<div class="flex-container flex-1-1">
					<TextInput type="text" :label="t('message.form-prompt-phone')" id="telefonnummer" v-model="extendedRegistrationData.telefonnummer" />
					<TextInput type="text" :label="t('message.form-prompt-street')" id="strasse2" v-model="extendedRegistrationData.strasse2" />
				</div>
				<div class="flex-container flex-1-3">
					<TextInput type="text" :label="t('message.form-prompt-zip-code')" id="postleitzahl" v-model="extendedRegistrationData.postleitzahl" />
					<TextInput type="text" :label="t('message.form-prompt-location')" id="ort" v-model="extendedRegistrationData.ort" />
				</div>
				<TextInput type="text" :label="t('message.form-prompt-country')" id="land" v-model="extendedRegistrationData.land" />
			</div>
			<div v-if="!dataSaved && !userAlreadyExists" class="buttons-outer">
				<p v-if="view === 'register_base'" class="disclaimer" v-html="t('message.register-base-form-disclaimer')"></p>
				<button @click.stop.prevent="submitForm" class="register green large" ref="submitButton">
					<template v-if="view === 'register_base'">{{ t("message.register-base-form-submit-button-label") }}</template>
					<template v-if="view === 'register_extended' || view === 'register_all'">{{ t("message.register-extended-form-submit-button-label") }}</template>
				</button>
			</div>
		</FormWrapper>
	</div>
</template>

<style scoped lang="scss">
@import "@/sass/media";

.register-form-outer {
	margin: 0 0 20px;

	.disclaimer {
		margin: 0 0 25px;
		font-size: 0.8rem;
		text-align: left;
	}

	h2 {
		text-align: left;
		color: var(--color-blaugruen);
		font-weight: 600;
		font-size: 2rem;
		margin: 18px 0 10px;
		text-align: center;
	}

	p {
		text-align: center;

		&.error-msg {
			color: #c93b3b;
			text-align: center;
		}
	}


	.all-header {
		text-align: left;
	}

	.all-extended-header {
		margin: 60px 0 0;
	}

	.registration-step-1 {

		.data-success-msg,
		.user-already-exists-msg {

			h1,
			h2 {
				font-size: 2.5rem;
				color: var(--color-blaugruen);
				text-align: center;
				margin: 15px 0 0.4em 0;
			}

			p {
				font-size: 1rem;
				text-align: center;
			}

			.email {
				color: var(--color-nevada);
			}
		}
	}

	.fields {
		margin: 20px 0 0;
	}

	.flex-container {
		display: flex;
		flex-direction: row;
		gap: 20px;

		&.flex-1-1 {
			.input-outer {
				width: 50%;
			}

			&-1 {
				.input-outer {
					width: 33%;
				}
			}
		}

		&.flex-1-3 {

			.input-outer {
				&:nth-child(1) {
					width: 28%;
				}

				&:nth-child(2) {
					width: 72%;
				}
			}
		}
	}

	.input-outer {
		margin: 0 0 15px;
		width: 100%;
	}

	.buttons-outer {
		text-align: center;

		button {
			margin: 5px auto 0 auto;

			&.register {
				padding: 0.3125rem 2.5rem;
			}
		}
	}
}

@media screen and (max-width: $size-xxlarge) {
	.register-form-outer {
		.flex-container {
			&.flex-1-3 {
				display: block;

				.input-outer {
					width: 100%;

					&:nth-child(1) {
						width: 100%;
					}

					&:nth-child(2) {
						width: 100%;
					}
				}
			}
		}

		.registration-step-1 {

			.data-success-msg,
			.user-already-exists-msg {
				max-width: 600px;
				margin: 0 auto;
			}

			br {
				display: none;
			}

		}
	}
}
</style>