<script setup lang="ts">

</script>

<template>
	<form @submit.stop.prevent="" action="#" class="standard-form" @="$attrs">
		<slot></slot>
	</form>
</template>

<style lang="scss">
.standard-form {
	margin: 0;

	.input-outer {
		display: flex;
		gap: 0 10px;

		&-label {

			&-left,
			&-right {
				align-items: center;
				flex-direction: row;
			}

			&-right {
				flex-direction: row-reverse;
			}

			&-top,
			&-bottom {
				flex-direction: column;
			}

			&-bottom {
				flex-direction: column-reverse;
			}
		}
	}
}
</style>