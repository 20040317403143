<script setup lang="ts">
import { ref, reactive } from "vue";
import { useStore } from "@/stores/main";
import { useI18n } from 'vue-i18n';
import RegisterForm from "@/components/form/RegisterForm.vue";


const store = useStore();
const { t, n } = useI18n({ useScope: "global" });
const showRegisterForm = ref(false);

const switchForm = (register: boolean) => {
	store.brainCount++;
	showRegisterForm.value = register;
};
</script>

<template>
	<div class="sso-login-content">
		<div :class="['switcher-area', { 'switcher-area-en': store.lang !== 'de' }]">
			<div class="buttons-outer">
				<div :class="['button-bg', { 'move-to-registration': showRegisterForm }]"></div>
				<a href="#" @click.stop.prevent="switchForm(false)" :class="['form-switch', { 'here': !showRegisterForm }]">{{ t("message.loginpage-tab-login-label") }}</a>
				<a href="#" @click.stop.prevent="switchForm(true)" :class="['form-switch', { 'here': showRegisterForm }]">{{ t("message.loginpage-tab-register-label") }}</a>
			</div>
		</div>
		<div class="sso-login-shell">
			<Transition name="fade" mode="in-out">
				<section v-show="showRegisterForm" class="ksg-login registration">
					<RegisterForm :key="store.brainCount" view="register_base" />
					<!-- <p class="register-text"><a @click.stop.prevent="showRegisterForm = false" href="#" class="register-link"><i class="icon icon-right_arrow"></i> Zurück zum Login</a></p> -->
				</section>
			</Transition>
			<Transition name="fade" mode="in-out">
				<section v-show="!showRegisterForm" class="ksg-login">
					<iframe :src="store.ssoLoginUrl" frameborder="0" border="0" scrolling="no">
						{{ t("message.sso-login-shell-no-frame-support-text") }}
					</iframe>
					<p class="register-text">{{ t("message.loginpage-register-hint-paragraph-text-1") }} <a @click.stop.prevent="showRegisterForm = true" href="#" class="register-link"><i class="icon icon-right_arrow"></i> {{ t("message.loginpage-register-hint-paragraph-text-2") }}</a></p>
				</section>
			</Transition>
		</div>
	</div>
</template>

<style scoped lang="scss">
@import "@/sass/media";

.sso-login-content {
	display: grid;
	grid-template-columns: .35fr .65fr;

	.switcher-area {
		background-image: url("@/assets/Titelbild_Design-Compass_2024_final.png");
		background-position: right top;
		background-size: cover;
		background-repeat: no-repeat;
		max-width: 1024px;

		&-en {
			background-image: url("@/assets/Titelbild_Design-Compass_2024_final_en.png");
		}

		.buttons-outer {
			position: relative;
			width: 140px;
			text-align: center;
			margin: 10vh 0 0 auto;

			.button-bg {
				position: absolute;
				right: 0;
				top: -12px;
				z-index: 10;
				width: 100%;
				height: 50px;
				background-color: var(--color-white);
				transition: top .5s ease-in-out;

				&.move-to-registration {
					top: 50px;
				}
			}

			.form-switch {
				display: block;
				position: relative;
				z-index: 20;
				color: var(--color-white);
				text-decoration: none;
				margin: 0 0 40px;
				font-weight: 600;

				&.here {
					color: var(--color-blaugruen);
				}
			}
		}
	}

	.sso-login-shell {

		position: relative;

		.ksg-login {
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
			width: 700px;

			iframe {
				height: 460px;
				width: 100%;
			}

			.register-text {
				text-align: center;

				.register-link {
					font-weight: 600;
					text-decoration: none;

					&:hover {
						text-decoration: underline;
					}

					.icon {
						color: var(--color-hover-dark-green);
						font-weight: 600;
					}
				}
			}
		}
	}
}

@media screen and (max-width: $size-xxlarge) {
	.sso-login-content {
		display: block;

		.switcher-area {
			position: relative;
			display: flex;
			flex-direction: column;
			background-position: left center;
			min-height: auto;

			.buttons-outer {
				margin: 40px 0 0 auto;
			}
		}

		.sso-login-shell {
			.ksg-login {
				position: static;
				margin: 0;
				width: auto;
				margin: 20px 20px 0;
				transform: none;

				&.registration {
					margin: 20px 20px 0;
				}
			}
		}
	}
}
</style>