<script setup lang="ts">
import { useStore } from "@/stores/main";

const props = defineProps({
	type: {
		type: String,
		default: "info", // info, warn, error.
	},
});
const store = useStore();

const close = () => {
	store.flashMessage = null;
};
</script>

<template>
	<div :class="['flash-message-outer', props.type]">
		<div class="text">
			<slot></slot>
		</div>
		<div class="icon-outer">
			<i class="icon icon-cross" @click.stop.prevent="close"></i>
		</div>
	</div>
</template>

<style scoped lang="scss">
.flash-message-outer {
	position: fixed;
	left: 50%;
	top: 20px;
	z-index: 10000;
	transform: translateX(-50%);
	width: 50vw;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	gap: 20px;
	padding: 10px 20px;
	margin: 15px 20px 10px 35px;
	box-shadow: var(--main-shadow);

	&.info,
	&.warn,
	&.error {
		background-color: var(--color-gelbgruen);
		color: var(--color-anthrazit);
	}

	&.warn {
		background-color: var(--color-tulip-tree);
	}

	&.error {
		background-color: var(--color-scary);
	}

	.text {
		font-size: 1.2rem;
	}

	.icon {
		font-size: 1rem;
		color: var(--color-anthrazit);
	}
}
</style>