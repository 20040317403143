<script setup lang="ts">
import { onMounted, type ComputedRef } from "vue";
import { useStore } from "@/stores/main";
import type { JsonEntryTab } from "@/types";
import EntryTab from "./components/EntryTab.vue";
import { computed } from "vue";

const store = useStore();

const tabs: ComputedRef<JsonEntryTab[]> = computed(() => {
	return store.getEntryTabs;
});


onMounted(async () => {
	await store.updateTabsData();
});

</script>

<template>
	<div class="entry-tabs-outer">
		<EntryTab v-for="tab in tabs" :data="tab" :key="tab.id" />
	</div>
</template>

<style scoped lang="scss">
.entry-tabs-outer {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: flex-start;
	gap: 5px;
}
</style>