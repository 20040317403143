<script setup lang="ts">
import { useStore } from "@/stores/main";
import { useI18n } from 'vue-i18n';
import type { JsonArticle } from "@/types";
import type { ComputedRef } from "vue";
import { computed } from "vue";
import { toRefs } from "vue";
import Article from "@/components/Article.vue";
import StandardMessage from "@/components/StandardMessage.vue";
import PerformanceMessage from "@/components/PerformanceMessage.vue";

const props = defineProps({
	articleId: {
		type: String,
		default: "",
	},
});

const store = useStore();
const { t, n } = useI18n({ useScope: "global" });
const { articleId } = toRefs(props);

const bookmarkCurrArticle: ComputedRef<string> = computed(() => {
	return store.bookmarkCurrArticle
})

const article: ComputedRef<JsonArticle[] | null> = computed(() => {
	if (bookmarkCurrArticle.value) {
		const article = store.getArticleDataByIdentifier(bookmarkCurrArticle.value)
		if (article) {
			return [article]
		}
	}
	return store.getFilteredArticleData
});

const overlayArticle: ComputedRef<JsonArticle | null> = computed(() => {
	return store.getArticleDataByIdentifier(articleId.value);
});
</script>

<template>
	<div>
		<div v-if="store.getCurrentUsedFilters.length || store.bookmarkCurrArticle || store.searchPhrase || overlayArticle">
			<section class="articles-outer" v-if="articleId">
				<Article v-if="overlayArticle" :data="overlayArticle" in-overlay />
				<StandardMessage v-if="!overlayArticle && !store.initializing" :msg="`${t('message.hint-no-overlay-article')}!`" />
			</section>
			<section class="articles-outer" v-else>
				<Article v-for="a in article" :key="a.id" :data="a" />
				<StandardMessage v-if="(!article || !article.length) && !store.initializing" :msg="`${t('message.hint-no-articles')}!`" />
			</section>
		</div>
		<Transition name="fade" v-else-if="!overlayArticle">
			<PerformanceMessage v-show="!store.searchPhrase && !store.getCurrentUsedFilters.length && !store.initializing" />
		</Transition>
	</div>
</template>


<style scoped lang="scss">
.articles-outer {
	margin: var(--content-default-margin);
}
</style>

<style lang="scss">
.popup .articles-outer {
	margin: 0 !important;
}
</style>