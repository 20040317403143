<script setup lang="ts">
import { useStore } from "@/stores/main";
import { useI18n } from 'vue-i18n';
import BlogEntries from "@/components/BlogEntries.vue";
import MobileLockModal from "@/components/MobileLockModal.vue";
import { type ComputedRef, computed, ref, watch } from "vue";
import type { UserDataStorageData, UpdateUserDataStorageParsedJsonData } from "@/types";
import expertsImage from "@/assets/xperts-ddc_1920x1080px.png";


const store = useStore();
const { t, n } = useI18n({ useScope: "global" });

const userData: ComputedRef<UserDataStorageData | null> = computed(() => {
	return store.ssoLoginUser ? store.ssoLoginUser as UserDataStorageData : null;
});

const getUserSalutationName = (): string => {
	if (userData.value) {
		const loginUser = userData.value.benutzer;
		const surname = loginUser.nachname
		const firstname = loginUser.vorname
		if (surname && firstname) {
			return `${firstname} ${surname}`;
		}
	}
	return "";
};

const userDataParsedJson = (): UpdateUserDataStorageParsedJsonData | null => {
	if (userData.value && userData.value.json_daten) {
		const jsonData = JSON.parse(userData.value.json_daten);
		if (jsonData.filterdata) {
			return jsonData.filterdata as UpdateUserDataStorageParsedJsonData;
		}
		return jsonData as UpdateUserDataStorageParsedJsonData;
	}
	return null;
};

const getUserSessionJsonEntryId = (): string => {
	const data = userDataParsedJson() as UpdateUserDataStorageParsedJsonData;
	if (data) {
		return data.entryId;
	}
	return "";
};
</script>

<template>
	<div class="fragments-outer">
		<Teleport to="body">
			<MobileLockModal />
		</Teleport>
		<div class="portal-content-outer green-bg">
			<section class="login-greeting">
				<Transition name="fade">
					<h2 v-if="getUserSalutationName()" class="salutation">{{ t('message.portalpage-salutation-headline-snippet') + ' ' + getUserSalutationName() }}</h2>
					<h2 v-else class="salutation">&nbsp;</h2>
				</Transition>
				<h1 class="welcome">{{ t("message.portalpage-welcome-headline") }}</h1>
				<p>{{ t("message.portalpage-welcome-paragraph1") }}</p>
				<p v-html="t('message.portalpage-welcome-paragraph2')"></p>
			</section>
			<div class="main-columns">
				<ul class="entries">
					<li class="column column-first">
						<a class="entry-link" :href="t('message.portalpage-entry-pcb-technology-link')">
							<span class="text-image-outer">
								<i class="icon-big icon icon-gear"></i>
								<span class="stripe">
									<h3>{{ t("message.portalpage-entry-pcb-technology-label") }}</h3>
									<!-- <p>Wählen Sie eine KSG-Leiterplattentechnologie aus, um Ihre Inhalte spezifisch nach Technologie vorzufiltern.</p> -->
								</span>
								<i class="arrow-icon icon icon-right_arrow"></i>
							</span>
						</a>
					</li>
					<li class="column">
						<a class="entry-link" :href="t('message.portalpage-entry-solutions-link')">
							<span class="text-image-outer">
								<i class="icon-big icon icon-industry"></i>
								<span class="stripe">
									<h3>{{ t("message.portalpage-entry-solutions-label") }}</h3>
									<!-- <p>Wählen Sie eine KSG-Leiterplattentechnologie aus, um Ihre Inhalte spezifisch nach Technologie vorzufiltern.</p> -->
								</span>
								<i class="arrow-icon icon icon-right_arrow"></i>
							</span>
						</a>
					</li>
					<li class="column">
						<a class="entry-link" :href="t('message.portalpage-entry-individual-link')">
							<span class="text-image-outer">
								<i class="icon-big icon icon-compass"></i>
								<span class="stripe">
									<h3>{{ t("message.portalpage-entry-individual-label") }}</h3>
								</span>
								<i class="arrow-icon icon icon-right_arrow"></i>
							</span>
						</a>
					</li>
					<li :class="['column column-last', { 'column-no-user-data': !userData || !userData.json_daten }]">
						<a v-if="userData && userData.json_daten" class="entry-link" :href="t('message.portalpage-entry-last-session-link', { session_entry_id: getUserSessionJsonEntryId() })">
							<span class="text-image-outer">
								<i class="icon-big icon icon-home"></i>
								<span class="stripe">
									<h3>{{ t("message.portalpage-entry-last-session-label") }}</h3>
								</span>
								<i class="arrow-icon icon icon-right_arrow"></i>
							</span>
						</a>
						<p v-else class="no-user-data">
							<span class="text-image-outer">
								<i class="icon-big icon icon-home"></i>
								<span class="stripe">
									<h3>{{ t("message.portalpage-entry-last-session-label") }}</h3>
								</span>
								<i class="arrow-icon icon icon-right_arrow"></i>
							</span>
						</p>
					</li>
				</ul>
			</div>
			<div class="news-outer bright-bg">
				<div class="ksg-panel ksg-blog-panel">
					<h2>{{ t("message.portalpage-news-panel-header") }}</h2>
					<BlogEntries />
				</div>
			</div>
			<div class="hackl-hacks bright-bg">
				<ul class="flexel">
					<li class="content">
						<h2>{{ t("message.portalpage-hackl-hacks-header") }}</h2>
						<p>{{ t("message.portalpage-hackl-para-first") }}</p>
						<p>{{ t("message.portalpage-hackl-para-second") }}</p>
					</li>
					<li class="video">
						<iframe width="560" height="315" :src="t('message.portalpage-hackl-hacks-video-src')" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
					</li>
				</ul>
			</div>
			<div class="newsletter-outer">
				<h2>{{ t("message.portalpage-newletter-header") }}</h2>
				<p>{{ t("message.portalpage-newletter-paragraph") }}</p>
				<a :href="t('message.portalpage-newletter-link')" class="button yellow xlarge" target="_blank">
					<i class="icon icon-mail"></i>
					<span class="label" v-html="t('message.portalpage-newletter-button-label')"></span>
				</a>
			</div>
		</div>
	</div>
</template>

<style scoped lang="scss">
#portal-content {
	margin: 0 auto;

	.bright-bg {
		background-color: var(--color-white);
	}

	.green-bg {
		background-color: var(--color-blaugruen);
	}

	.fragments-outer {
		.portal-content-outer {
			padding: 50px 0 0;

			h1,
			h2,
			p {
				color: var(--color-white);
			}

			h2.salutation {
				font-size: 1.5rem;
				font-weight: normal;
			}

			.login-greeting {
				margin: 0 150px 50px;

				.welcome {
					font-size: var(--header-1);
				}
			}

			.main-columns {
				margin: 0 0 -105px;

				.entries {
					display: grid;
					grid-template-columns: repeat(4, 25%);
					margin: 0 130px;
					padding: 0;
					list-style: none;

					.column {
						display: block;
						width: auto;
						float: none;
						padding: 0 20px 0;

						&-no-user-data {
							opacity: .5;

							.no-user-data {
								background-color: var(--color-gelbgruen);

								.icon {
									cursor: default;
								}
							}
						}

						a.entry-link {
							display: block;
							text-decoration: none;
							background-color: var(--color-gelbgruen);

							&:hover {
								.icon {
									&-big {
										font-size: 4.5rem;
									}
								}

								.stripe {
									padding: 7px 15px 15px;

									h3 {
										font-size: 1.2rem;
									}
								}
							}
						}

						.text-image-outer {
							position: relative;
							display: flex;
							flex-direction: column;
							gap: 15px;
							text-align: center;
							height: 200px;
							padding: 30px 0 0;

							.icon {
								display: inline-block;
								color: var(--color-blaugruen);

								&-big {
									font-size: 4rem;
									transition: font-size .3s ease-in-out;
								}

								&:hover {
									color: var(--color-blaugruen);
								}

								&.arrow-icon {
									display: inline-block;
									position: absolute;
									bottom: 10px;
									right: 10px;
									font-size: 1.4rem;
								}
							}

							.stripe {
								padding: 15px;
								transition: padding .3s ease-in-out;

								h3 {
									font-size: 1rem;
									font-weight: 600;
									color: var(--color-blaugruen);
									transition: font-size .3s ease-in-out;

									a {
										text-decoration: none;

										&:hover {
											text-decoration: underline;
										}
									}
								}
							}
						}
					}
				}
			}

			.news-outer {
				background-color: var(--color-white);
				padding: 160px 150px 20px;

				h2 {
					color: var(--color-anthrazit);
				}
			}

			.hackl-hacks,
			.experts-demo {
				padding: 10px 0 50px;

				.flexel {
					display: flex;
					justify-content: space-between;
					flex-direction: row;
					gap: 25px;
					margin: 0 150px;
					padding: 0;
					list-style: none;

					.content {
						h2 {
							color: var(--color-blaugruen);
							margin: 0;
							padding: 0 0 20px;
							font-size: 2.2rem;
						}

						p {
							color: var(--color-anthrazit);

						}
					}

					.video,
					.experts-image {

						iframe,
						img {
							--video-width-calc: 40;
							min-width: 200px;
							width: calc(var(--video-width-calc)*1vw);
							height: calc(var(--video-width-calc) / 16 * 9vw);
						}
					}

					.experts-image {
						text-align: right;
					}
				}
			}

			.experts-demo {

				a {
					font-weight: 600;
				}
			}

			.newsletter-outer {
				background-color: var(--color-blaugruen);
				color: var(--color-white);
				text-align: center;
				padding: 30px 25px 40px;

				h2 {
					font-weight: 700;
					color: var(--color-white);
					font-size: 1.6rem;
				}

				a.button {
					width: 240px;
					margin: 0 auto;
					padding: 8px 20px;
					gap: 15px;
					color: var(--color-blaugruen);

					.icon,
					.label {
						font-size: 2.6rem;
						color: var(--color-blaugruen);
					}

					.label {
						font-size: 1rem;
						text-align: left;
						font-weight: 600;
						line-height: 1.2;
					}
				}
			}

		}
	}
}
</style>