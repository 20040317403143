<script setup lang="ts">
import { ref, type Ref, toRefs, watch, nextTick } from "vue"
import type { JsonBookmarks, SharedData } from "@/types"
import { useI18n } from "vue-i18n"
import FormWrapper from "./form/FormWrapper.vue"
import TextInput from "./form/TextInput.vue"
import { newShareProtocol } from "@/utils/entrypoints";

const props = defineProps({
	identifier: {
		type: String,
		required: true,
	},
	placement: {
		type: String,
		default: "top",
	},
	shareIdentifier: {
		type: String,
		required: true,
	},
	sourceUser: {
		type: String,
		required: true,
	},
	targetUser: {
		type: String,
		default: ""
	},
	type: {
		type: String,
		default: "merkliste"
	},
	lang: {
		type: String,
		default: "deutsch"
	},
	entryId: {
		type: String,
		required: true
	}
})

const emit = defineEmits(["sharedWithUser"])

const { t } = useI18n({ useScope: "global" })
const { shareIdentifier, identifier, placement, sourceUser, targetUser, type, lang, entryId } = toRefs(props)

const textInputEmail = ref()
const validate = ref(0)
const errors = ref(new Set())
const serverError = ref(false)
const sending = ref(false)
const currSharedIdentifier = ref("")


const sharedData: Ref<SharedData> = ref({
	sourceUser: sourceUser.value,
	targetUser: targetUser.value,
	identifier: identifier.value,
	type: type.value,
	lang: lang.value,
	entryId: entryId.value,
})

const updateErrors = (fieldName: string, error: boolean) => {
	if (error) {
		errors.value.add(fieldName)
	} else {
		errors.value.delete(fieldName)
	}
}

const submitShareForm = async () => {
	validate.value++
	nextTick(async () => {
		if (!errors.value.size) {
			emit("sharedWithUser", sharedData.value.targetUser)
			if (sharedData.value.identifier && sharedData.value.sourceUser && sharedData.value.targetUser) {
				sending.value = true
				const res = await newShareProtocol(sharedData.value)
				if (res && res.ok) {
					(window as any)._paq.push(["trackEvent", "Share button click", `Daten wurden geteilt (${sharedData.value.type})`]);
					resetSharing()
				} else {
					serverError.value = true
				}
			}
		} else {
			serverError.value = false
		}
		sending.value = false
	})
}

const resetSharing = () => {
	textInputEmail.value.clearError()
	currSharedIdentifier.value = ""
	serverError.value = false
	if (errors.value.size) {
		sharedData.value.targetUser = ""
		emit("sharedWithUser", "")
		errors.value.clear()
	}
}

watch(shareIdentifier, (srid) => {
	if (srid) {
		currSharedIdentifier.value = srid
	}
	else {
		currSharedIdentifier.value = ""
	}
})

watch(targetUser, (tuser) => {
	sharedData.value.targetUser = tuser
})

watch(sourceUser, (suser) => {
	sharedData.value.sourceUser = suser
})
</script>

<template>
	<div class="share-popper-outer">
		<Popper @click.stop="void" :show="sharedData.identifier === currSharedIdentifier" :placement="placement" offset-distance="5" offset-skid="-200" arrow-padding="10" class="popper-share" arrow>
			<slot />
			<template #content="{ close }">
				<div class="popper-content-inner">
					<div class="popper-share-title">
						<h3>{{ t("message.bookmarks-share-popup-title") }}</h3>
					</div>
					<FormWrapper>
						<div class="bm-share-form-inner">
							<p v-if="serverError" class="error-msg">{{ t("message.register-form-server-error") }}</p>
							<TextInput ref="textInputEmail" @field-error="updateErrors" :validate="validate" validation-reg-exp="^\w+([+\.-]?\w+)*@\w+([+\.-]?\w+)*(\.\w{2,4})+$" :placeholder="t('message.bookmarks-share-popup-input-label')" :id="`${identifier}_share-email`" v-model="sharedData.targetUser" :errorMsg="t('message.bookmarks-share-popup-input-email-error-msg')" focus></TextInput>
							<div class="buttons">
								<button @click="resetSharing" class="large green outline">{{ t("message.button-label-cancel") }}</button>
								<button @click="submitShareForm" class="large green" :disabled="!!errors.size || !sharedData.targetUser || sending">{{ t("message.button-form-sending-label") }}</button>
							</div>
						</div>
					</FormWrapper>
				</div>
			</template>
		</Popper>
	</div>
</template>

<style scoped lang="scss">
.share-popper-outer {
	cursor: default;

	.popper-content-inner {
		width: 450px;

		.bm-share-form-inner {
			display: flex;
			flex-direction: column;
			gap: 10px;
			width: 100%;

			.buttons {
				display: flex;
				gap: 10px;
				width: 100%;
				justify-content: flex-end;
			}

			p {
				&.error-msg {
					font-weight: 600;
					color: #c93b3b;
				}
			}

		}
	}
}
</style>